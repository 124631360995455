var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import ApolloClient, { gql } from 'apollo-boost';
import { appConfig } from '../appConfig';
import Region from '../types/Region';
var CONNECTION_QUERY = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\nquery{\n    allOrgConnections( condition: {\n      provider: \"ms-teams\"\n    }){\n      nodes {\n        connection\n      }\n      totalCount\n    }\n  }\n"], ["\nquery{\n    allOrgConnections( condition: {\n      provider: \"ms-teams\"\n    }){\n      nodes {\n        connection\n      }\n      totalCount\n    }\n  }\n"])));
var CONNECTION_MUTATION = gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\nmutation CreateOrgConnection($connection: String!){\n  createOrgConnection(input: {\n    provider: \"ms-teams\",\n    connection: $connection,\n    automaticEnroll: true\n  }){\n    clientMutationId\n    orgConnection {\n      orgId\n    }\n  }\n}\n"], ["\nmutation CreateOrgConnection($connection: String!){\n  createOrgConnection(input: {\n    provider: \"ms-teams\",\n    connection: $connection,\n    automaticEnroll: true\n  }){\n    clientMutationId\n    orgConnection {\n      orgId\n    }\n  }\n}\n"])));
export var createClient = function (accessToken) {
    var uri = (accessToken.region === Region.EU) ? appConfig.graphqlEuUrl + "/graphql" : appConfig.graphqlUsUrl + "/graphql";
    var client = new ApolloClient({
        uri: uri,
        headers: {
            authorization: "Bearer " + accessToken.token
        }
    });
    return client;
};
export var hasConnection = function (accessToken) { return __awaiter(void 0, void 0, void 0, function () {
    var client, result;
    return __generator(this, function (_a) {
        client = createClient(accessToken);
        result = new Promise(function (resolve) {
            client.query({ query: CONNECTION_QUERY })
                .then(function (query) {
                if (query.errors) {
                    console.error(query.errors);
                    resolve({ success: false, errors: query.errors });
                }
                if (query.data.allOrgConnections) {
                    if (query.data.allOrgConnections.totalCount < 1) {
                        resolve({ success: true, hasConnection: false });
                    }
                    resolve({ success: true, hasConnection: true });
                }
            });
        });
        return [2 /*return*/, result];
    });
}); };
export var createConnection = function (accessToken, tid) { return __awaiter(void 0, void 0, void 0, function () {
    var client, result;
    return __generator(this, function (_a) {
        client = createClient(accessToken);
        result = new Promise(function (resolve) { return client.mutate({ mutation: CONNECTION_MUTATION, variables: { connection: tid } })
            .then(function (mutation) {
            if (mutation.errors) {
                resolve({ success: false, errors: mutation.errors });
            }
            resolve({ success: true, data: mutation.data });
        }); });
        return [2 /*return*/, result];
    });
}); };
var templateObject_1, templateObject_2;
