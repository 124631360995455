import React from 'react';
import { getActiveAccessToken } from '../lib/TokenService';
import { createClient } from '../lib/GraphQL';
import { Dropdown } from '@fluentui/react-northstar';
import { sortDropDownListItems } from '../lib/TeamsHelper';
var Organisation = function (props) {
    function organisationChangeHandler(option) {
        var activeToken = getActiveAccessToken(props.accessTokens, option.variables.id.toString());
        if (activeToken) {
            var client = createClient(activeToken);
            props.setGqlClient(client);
        }
        props.setActiveAccessToken(activeToken);
        props.setChannel('');
        props.setSpace('');
    }
    var content;
    if (props.accessTokens.length === 1) {
        var activeToken = props.accessTokens[0];
        if (!!!props.gqlClient) {
            var client = createClient(activeToken);
            props.setGqlClient(client);
        }
        props.setActiveAccessToken(activeToken);
        content = (React.createElement("h1", null, activeToken.tokenPayload.org_name));
    }
    else if (props.accessTokens.length > 1) {
        var organisations = props.accessTokens.map(function (accessToken) {
            var _a;
            return { header: accessToken.tokenPayload.org_name, selected: accessToken.tokenPayload.org_id === ((_a = props.activeAccessToken) === null || _a === void 0 ? void 0 : _a.tokenPayload.org_id), variables: { id: accessToken.tokenPayload.org_id } };
        });
        organisations = sortDropDownListItems(organisations);
        content = (React.createElement(Dropdown, { items: organisations, onChange: function (event, option) { return organisationChangeHandler(option.value); }, placeholder: "select an organisation" }));
    }
    else {
        // TODO: Handler this situation better
        content = (React.createElement("div", null, "Error"));
    }
    return (content);
};
export default Organisation;
