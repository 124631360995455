import React from 'react';
import { Image } from '@fluentui/react-northstar';
var ScreenCloudLogoAndText = function (props) {
    var image = "img/logo_screencloud_2019_horizontal_1.svg";
    if (props.theme !== "default") {
        image = "img/logo_screencloud_2019_horizontal_2.svg";
    }
    return (React.createElement(Image, { src: image, style: { height: props.height } }));
};
export default ScreenCloudLogoAndText;
