import { Loader } from '@fluentui/react-northstar';
import * as React from 'react';
import { useAllSpacesQuery } from "../../generated/graphql";
import SpaceList from './SpaceList';
var SpaceListContainer = function (props) {
    var _a = useAllSpacesQuery(), data = _a.data, error = _a.error, loading = _a.loading;
    if (loading) {
        return React.createElement(Loader, { label: "loading...", labelPosition: "end", size: "medium" });
    }
    if (error || !data) {
        return React.createElement("div", null,
            "ERROR ", error === null || error === void 0 ? void 0 :
            error.message);
    }
    return (React.createElement(SpaceList, { data: data, setChannelHandler: props.setChannelHandler, setSpaceHandler: props.setSpaceHandler, space: props.space }));
};
export default SpaceListContainer;
