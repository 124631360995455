var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { useState } from 'react';
import './App.css';
import * as microsoftTeams from "@microsoft/teams-js";
import { BrowserRouter as Router, Route } from "react-router-dom";
import GroupTabConfig from './GroupTabConfig';
import Home from './Home';
import AuthLoginEnd from './Authentication/AuthLoginEnd';
import AuthLogin from './Authentication/AuthLogin';
import EmbeddedChannel from './EmbeddedChannel';
import { initializeIcons } from '@uifabric/icons';
import { Provider } from '@fluentui/react-northstar';
import { resolveTeamsTheme } from '../lib/TeamsHelper';
function App() {
    var _a = useState(), themeState = _a[0], setThemeState = _a[1];
    initializeIcons();
    if (microsoftTeams) {
        if (window.parent === window.self) {
            return (React.createElement(Router, null,
                React.createElement(Route, { exact: true, path: "/azurelogin", component: function () { return AuthLogin({ strategy: 'azuread' }); } }),
                React.createElement(Route, { exact: true, path: "/authloginend", component: AuthLoginEnd }),
                React.createElement(Route, { exact: true, path: "/config", component: TeamsHostError }),
                React.createElement(Route, { exact: true, path: "/googlelogin", component: function () { return AuthLogin({ strategy: 'auth0/google-oauth2' }); } }),
                React.createElement(Route, { exact: true, path: "/group", component: TeamsHostError }),
                React.createElement(Route, { exact: true, path: "/home", component: Home })));
        }
        var updateTheme = function () {
            microsoftTeams.getContext(function (context) {
                if (context.theme !== themeState) {
                    setThemeState(context.theme);
                }
            });
            return resolveTeamsTheme(themeState);
        };
        microsoftTeams.initialize();
        microsoftTeams.registerOnThemeChangeHandler(updateTheme);
        return (React.createElement(Provider, { theme: updateTheme() },
            React.createElement(Router, null,
                React.createElement(Route, { exact: true, path: "/embedded", component: function () { return EmbeddedChannel({ theme: themeState }); } }),
                React.createElement(Route, { exact: true, path: "/config", component: function () { return GroupTabConfig({ theme: themeState }); } }))));
    }
    return (React.createElement("h3", null, "Microsoft Teams SDK not found."));
}
var TeamsHostError = /** @class */ (function (_super) {
    __extends(TeamsHostError, _super);
    function TeamsHostError() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    TeamsHostError.prototype.render = function () {
        return (React.createElement("div", null,
            React.createElement("h3", { className: "Error" }, "Teams client host not found.")));
    };
    return TeamsHostError;
}(React.Component));
export default App;
