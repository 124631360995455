import App from './components/App';
import React from 'react';
import ReactDOM from 'react-dom';
import { appConfig } from './appConfig';
// announce software
console.log("%cscreencloud/microsoft-teams-integration-ui%c version: %c" + appConfig.version, "color: black; background: #fecf00; font-weight: bold;", "font-weight: bold;", "");
// render app
// eslint-disable-next-line react/jsx-no-undef
ReactDOM.render(React.createElement(App, null), document.getElementById('root'));
// unregister all service workers
// DO NOT REMOVE THIS BEFORE THE YEAR 2021
if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations()
        .then(function (registrations) {
        for (var _i = 0, registrations_1 = registrations; _i < registrations_1.length; _i++) {
            var registration = registrations_1[_i];
            registration.unregister().catch(function () { return undefined; });
        }
    });
}
