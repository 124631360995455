import React from "react";
import { MessageBar, MessageBarType, Link } from '@fluentui/react';
import { Dropdown } from "@fluentui/react-northstar";
import { sortDropDownListItems } from "../../lib/TeamsHelper";
var ChannelList = function (props) {
    var _a, _b;
    function generateChannelOptions() {
        var _a;
        var channels = [];
        if ((_a = props.data) === null || _a === void 0 ? void 0 : _a.allChannels) {
            channels = props.data.allChannels.nodes.map(function (channel) {
                return { header: channel.name, selected: channel.id === props.channel.Id, variables: { id: channel.id, isPublic: channel.isEmbedPublic, orgId: channel.orgId, name: channel.name, width: channel.width, height: channel.height } };
            });
        }
        channels = sortDropDownListItems(channels);
        return channels;
    }
    function setChannel(option) {
        var conf = {
            Id: option.id,
            Name: option.name,
            IsPrivate: !option.isPublic,
            OrgId: option.orgId,
            Height: option.height,
            Width: option.width,
            Region: props.activeAccessToken.region
        };
        props.setChannelHandler(conf);
    }
    if (!props.space || (((_b = (_a = props.data) === null || _a === void 0 ? void 0 : _a.allChannels) === null || _b === void 0 ? void 0 : _b.nodes) && props.data.allChannels.nodes.length > 0)) {
        return (React.createElement(Dropdown, { disabled: props.space === '', placeholder: "select a channel", items: generateChannelOptions(), onChange: function (event, option) { return setChannel(option.value.variables); } }));
    }
    else {
        return (React.createElement(MessageBar, { messageBarType: MessageBarType.warning, isMultiline: true },
            "Currently you have no shared channels available in this space. To share channels you need to ",
            React.createElement(Link, { href: "https://get.screen.cloud/embeddable-channels", target: "_blank" }, "request activation"),
            " of the embeddable channel feature for your ScreenCloud Studio account. If the feature has been activated but you need help sharing your channel ",
            React.createElement(Link, { href: "https://support.screencloud.com/hc/en-gb/articles/360007453157-Using-Embeddable-Channels-With-ScreenCloud", target: "_blank" }, "follow our guide"),
            "."));
    }
};
export default ChannelList;
