import * as React from 'react';
import { useAllChannelsQuery } from '../../generated/graphql';
import ChannelList from './ChannelList';
import { Loader } from '@fluentui/react-northstar';
var ChannelListContainer = function (props) {
    if (props.space) {
        var _a = useAllChannelsQuery({ variables: { spaceId: props.space } }), data = _a.data, error = _a.error, loading = _a.loading;
        if (loading) {
            return React.createElement(Loader, { label: "loading...", labelPosition: "end", size: "medium" });
        }
        if (props.space && (error)) {
            return React.createElement("div", null,
                "ERROR ", error === null || error === void 0 ? void 0 :
                error.message);
        }
        return (React.createElement(ChannelList, { activeAccessToken: props.activeAccessToken, channel: props.channel, data: data, space: props.space, setChannelHandler: props.setChannelHandler }));
    }
    else {
        return null;
    }
};
export default ChannelListContainer;
