import React, { useEffect } from 'react';
import * as microsoftTeams from "@microsoft/teams-js";
import { appConfig } from '../../appConfig';
import AuthLoading from './AuthLoading';
var AuthLogin = function (props) {
    useEffect(function () {
        microsoftTeams.initialize();
        microsoftTeams.getContext(function (context) {
            window.location.assign(appConfig.authUrl + "/auth/" + props.strategy + "?redirectUrl=" + appConfig.contentUrl + "/authloginend");
        });
    }, [props.strategy]);
    return (React.createElement(AuthLoading, { theme: undefined, message: "Logging you in..." }));
};
export default AuthLogin;
