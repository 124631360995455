var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import gql from 'graphql-tag';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHoc from '@apollo/react-hoc';
import * as ApolloReactHooks from '@apollo/react-hooks';
/** Methods to use when ordering `App`. */
export var AppsOrderBy;
(function (AppsOrderBy) {
    AppsOrderBy["Natural"] = "NATURAL";
    AppsOrderBy["IdAsc"] = "ID_ASC";
    AppsOrderBy["IdDesc"] = "ID_DESC";
    AppsOrderBy["VisibilityAsc"] = "VISIBILITY_ASC";
    AppsOrderBy["VisibilityDesc"] = "VISIBILITY_DESC";
    AppsOrderBy["CategoriesAsc"] = "CATEGORIES_ASC";
    AppsOrderBy["CategoriesDesc"] = "CATEGORIES_DESC";
    AppsOrderBy["RequirementsAsc"] = "REQUIREMENTS_ASC";
    AppsOrderBy["RequirementsDesc"] = "REQUIREMENTS_DESC";
    AppsOrderBy["NameAsc"] = "NAME_ASC";
    AppsOrderBy["NameDesc"] = "NAME_DESC";
    AppsOrderBy["ScrnAsc"] = "SCRN_ASC";
    AppsOrderBy["ScrnDesc"] = "SCRN_DESC";
    AppsOrderBy["DescriptionAsc"] = "DESCRIPTION_ASC";
    AppsOrderBy["DescriptionDesc"] = "DESCRIPTION_DESC";
    AppsOrderBy["IconUrlAsc"] = "ICON_URL_ASC";
    AppsOrderBy["IconUrlDesc"] = "ICON_URL_DESC";
    AppsOrderBy["DeveloperAsc"] = "DEVELOPER_ASC";
    AppsOrderBy["DeveloperDesc"] = "DEVELOPER_DESC";
    AppsOrderBy["TagsAsc"] = "TAGS_ASC";
    AppsOrderBy["TagsDesc"] = "TAGS_DESC";
    AppsOrderBy["CreatedAtAsc"] = "CREATED_AT_ASC";
    AppsOrderBy["CreatedAtDesc"] = "CREATED_AT_DESC";
    AppsOrderBy["UpdatedAtAsc"] = "UPDATED_AT_ASC";
    AppsOrderBy["UpdatedAtDesc"] = "UPDATED_AT_DESC";
    AppsOrderBy["DefaultInstallAsc"] = "DEFAULT_INSTALL_ASC";
    AppsOrderBy["DefaultInstallDesc"] = "DEFAULT_INSTALL_DESC";
    AppsOrderBy["SlugAsc"] = "SLUG_ASC";
    AppsOrderBy["SlugDesc"] = "SLUG_DESC";
    AppsOrderBy["PrimaryKeyAsc"] = "PRIMARY_KEY_ASC";
    AppsOrderBy["PrimaryKeyDesc"] = "PRIMARY_KEY_DESC";
})(AppsOrderBy || (AppsOrderBy = {}));
export var AppVisibility;
(function (AppVisibility) {
    AppVisibility["Public"] = "PUBLIC";
    AppVisibility["Private"] = "PRIVATE";
})(AppVisibility || (AppVisibility = {}));
export var AppDefaultInstallType;
(function (AppDefaultInstallType) {
    AppDefaultInstallType["Once"] = "ONCE";
    AppDefaultInstallType["Never"] = "NEVER";
    AppDefaultInstallType["Always"] = "ALWAYS";
})(AppDefaultInstallType || (AppDefaultInstallType = {}));
/** Methods to use when ordering `AppVersion`. */
export var AppVersionsOrderBy;
(function (AppVersionsOrderBy) {
    AppVersionsOrderBy["Natural"] = "NATURAL";
    AppVersionsOrderBy["IdAsc"] = "ID_ASC";
    AppVersionsOrderBy["IdDesc"] = "ID_DESC";
    AppVersionsOrderBy["AppIdAsc"] = "APP_ID_ASC";
    AppVersionsOrderBy["AppIdDesc"] = "APP_ID_DESC";
    AppVersionsOrderBy["VersionAsc"] = "VERSION_ASC";
    AppVersionsOrderBy["VersionDesc"] = "VERSION_DESC";
    AppVersionsOrderBy["BetaFlagAsc"] = "BETA_FLAG_ASC";
    AppVersionsOrderBy["BetaFlagDesc"] = "BETA_FLAG_DESC";
    AppVersionsOrderBy["StableFlagAsc"] = "STABLE_FLAG_ASC";
    AppVersionsOrderBy["StableFlagDesc"] = "STABLE_FLAG_DESC";
    AppVersionsOrderBy["ManifestJsonAsc"] = "MANIFEST_JSON_ASC";
    AppVersionsOrderBy["ManifestJsonDesc"] = "MANIFEST_JSON_DESC";
    AppVersionsOrderBy["ViewerUrlAsc"] = "VIEWER_URL_ASC";
    AppVersionsOrderBy["ViewerUrlDesc"] = "VIEWER_URL_DESC";
    AppVersionsOrderBy["EditorUrlAsc"] = "EDITOR_URL_ASC";
    AppVersionsOrderBy["EditorUrlDesc"] = "EDITOR_URL_DESC";
    AppVersionsOrderBy["MediaSandboxAccessAsc"] = "MEDIA_SANDBOX_ACCESS_ASC";
    AppVersionsOrderBy["MediaSandboxAccessDesc"] = "MEDIA_SANDBOX_ACCESS_DESC";
    AppVersionsOrderBy["ConfigurationTypeAsc"] = "CONFIGURATION_TYPE_ASC";
    AppVersionsOrderBy["ConfigurationTypeDesc"] = "CONFIGURATION_TYPE_DESC";
    AppVersionsOrderBy["StoreDataAsc"] = "STORE_DATA_ASC";
    AppVersionsOrderBy["StoreDataDesc"] = "STORE_DATA_DESC";
    AppVersionsOrderBy["CreatedAtAsc"] = "CREATED_AT_ASC";
    AppVersionsOrderBy["CreatedAtDesc"] = "CREATED_AT_DESC";
    AppVersionsOrderBy["UpdatedAtAsc"] = "UPDATED_AT_ASC";
    AppVersionsOrderBy["UpdatedAtDesc"] = "UPDATED_AT_DESC";
    AppVersionsOrderBy["IsLatestAsc"] = "IS_LATEST_ASC";
    AppVersionsOrderBy["IsLatestDesc"] = "IS_LATEST_DESC";
    AppVersionsOrderBy["IsLatestStableAsc"] = "IS_LATEST_STABLE_ASC";
    AppVersionsOrderBy["IsLatestStableDesc"] = "IS_LATEST_STABLE_DESC";
    AppVersionsOrderBy["PrimaryKeyAsc"] = "PRIMARY_KEY_ASC";
    AppVersionsOrderBy["PrimaryKeyDesc"] = "PRIMARY_KEY_DESC";
})(AppVersionsOrderBy || (AppVersionsOrderBy = {}));
export var AppConfiguration;
(function (AppConfiguration) {
    AppConfiguration["Form"] = "FORM";
    AppConfiguration["Editor"] = "EDITOR";
})(AppConfiguration || (AppConfiguration = {}));
/** Methods to use when ordering `AppInstall`. */
export var AppInstallsOrderBy;
(function (AppInstallsOrderBy) {
    AppInstallsOrderBy["Natural"] = "NATURAL";
    AppInstallsOrderBy["IdAsc"] = "ID_ASC";
    AppInstallsOrderBy["IdDesc"] = "ID_DESC";
    AppInstallsOrderBy["AppIdAsc"] = "APP_ID_ASC";
    AppInstallsOrderBy["AppIdDesc"] = "APP_ID_DESC";
    AppInstallsOrderBy["OrgIdAsc"] = "ORG_ID_ASC";
    AppInstallsOrderBy["OrgIdDesc"] = "ORG_ID_DESC";
    AppInstallsOrderBy["SpaceIdAsc"] = "SPACE_ID_ASC";
    AppInstallsOrderBy["SpaceIdDesc"] = "SPACE_ID_DESC";
    AppInstallsOrderBy["CreatedAtAsc"] = "CREATED_AT_ASC";
    AppInstallsOrderBy["CreatedAtDesc"] = "CREATED_AT_DESC";
    AppInstallsOrderBy["UpdatedAtAsc"] = "UPDATED_AT_ASC";
    AppInstallsOrderBy["UpdatedAtDesc"] = "UPDATED_AT_DESC";
    AppInstallsOrderBy["CreatedByAsc"] = "CREATED_BY_ASC";
    AppInstallsOrderBy["CreatedByDesc"] = "CREATED_BY_DESC";
    AppInstallsOrderBy["UpdatedByAsc"] = "UPDATED_BY_ASC";
    AppInstallsOrderBy["UpdatedByDesc"] = "UPDATED_BY_DESC";
    AppInstallsOrderBy["PrimaryKeyAsc"] = "PRIMARY_KEY_ASC";
    AppInstallsOrderBy["PrimaryKeyDesc"] = "PRIMARY_KEY_DESC";
})(AppInstallsOrderBy || (AppInstallsOrderBy = {}));
export var OrgStatus;
(function (OrgStatus) {
    OrgStatus["Active"] = "ACTIVE";
    OrgStatus["Pending"] = "PENDING";
    OrgStatus["Disabled"] = "DISABLED";
    OrgStatus["Archived"] = "ARCHIVED";
})(OrgStatus || (OrgStatus = {}));
export var UserType;
(function (UserType) {
    UserType["User"] = "USER";
    UserType["System"] = "SYSTEM";
    UserType["Bot"] = "BOT";
})(UserType || (UserType = {}));
export var UserStatus;
(function (UserStatus) {
    UserStatus["Active"] = "ACTIVE";
    UserStatus["Pending"] = "PENDING";
    UserStatus["Disabled"] = "DISABLED";
    UserStatus["Archived"] = "ARCHIVED";
})(UserStatus || (UserStatus = {}));
export var ReceiveUpdates;
(function (ReceiveUpdates) {
    ReceiveUpdates["No"] = "NO";
    ReceiveUpdates["Requested"] = "REQUESTED";
    ReceiveUpdates["Confirmed"] = "CONFIRMED";
})(ReceiveUpdates || (ReceiveUpdates = {}));
/** Methods to use when ordering `Org`. */
export var OrgsOrderBy;
(function (OrgsOrderBy) {
    OrgsOrderBy["Natural"] = "NATURAL";
    OrgsOrderBy["IdAsc"] = "ID_ASC";
    OrgsOrderBy["IdDesc"] = "ID_DESC";
    OrgsOrderBy["NameAsc"] = "NAME_ASC";
    OrgsOrderBy["NameDesc"] = "NAME_DESC";
    OrgsOrderBy["SlugAsc"] = "SLUG_ASC";
    OrgsOrderBy["SlugDesc"] = "SLUG_DESC";
    OrgsOrderBy["StatusAsc"] = "STATUS_ASC";
    OrgsOrderBy["StatusDesc"] = "STATUS_DESC";
    OrgsOrderBy["BrandForegroundColorAsc"] = "BRAND_FOREGROUND_COLOR_ASC";
    OrgsOrderBy["BrandForegroundColorDesc"] = "BRAND_FOREGROUND_COLOR_DESC";
    OrgsOrderBy["BrandBackgroundColorAsc"] = "BRAND_BACKGROUND_COLOR_ASC";
    OrgsOrderBy["BrandBackgroundColorDesc"] = "BRAND_BACKGROUND_COLOR_DESC";
    OrgsOrderBy["DefaultUserPermissionsAsc"] = "DEFAULT_USER_PERMISSIONS_ASC";
    OrgsOrderBy["DefaultUserPermissionsDesc"] = "DEFAULT_USER_PERMISSIONS_DESC";
    OrgsOrderBy["FeatureFlagsCacheAsc"] = "FEATURE_FLAGS_CACHE_ASC";
    OrgsOrderBy["FeatureFlagsCacheDesc"] = "FEATURE_FLAGS_CACHE_DESC";
    OrgsOrderBy["PreferencesAsc"] = "PREFERENCES_ASC";
    OrgsOrderBy["PreferencesDesc"] = "PREFERENCES_DESC";
    OrgsOrderBy["EnvAsc"] = "ENV_ASC";
    OrgsOrderBy["EnvDesc"] = "ENV_DESC";
    OrgsOrderBy["CreatedAtAsc"] = "CREATED_AT_ASC";
    OrgsOrderBy["CreatedAtDesc"] = "CREATED_AT_DESC";
    OrgsOrderBy["UpdatedAtAsc"] = "UPDATED_AT_ASC";
    OrgsOrderBy["UpdatedAtDesc"] = "UPDATED_AT_DESC";
    OrgsOrderBy["DeletedAtAsc"] = "DELETED_AT_ASC";
    OrgsOrderBy["DeletedAtDesc"] = "DELETED_AT_DESC";
    OrgsOrderBy["CreatedByAsc"] = "CREATED_BY_ASC";
    OrgsOrderBy["CreatedByDesc"] = "CREATED_BY_DESC";
    OrgsOrderBy["UpdatedByAsc"] = "UPDATED_BY_ASC";
    OrgsOrderBy["UpdatedByDesc"] = "UPDATED_BY_DESC";
    OrgsOrderBy["DeletedByAsc"] = "DELETED_BY_ASC";
    OrgsOrderBy["DeletedByDesc"] = "DELETED_BY_DESC";
    OrgsOrderBy["BlankChannelIdAsc"] = "BLANK_CHANNEL_ID_ASC";
    OrgsOrderBy["BlankChannelIdDesc"] = "BLANK_CHANNEL_ID_DESC";
    OrgsOrderBy["BrandChannelIdAsc"] = "BRAND_CHANNEL_ID_ASC";
    OrgsOrderBy["BrandChannelIdDesc"] = "BRAND_CHANNEL_ID_DESC";
    OrgsOrderBy["StartChannelIdAsc"] = "START_CHANNEL_ID_ASC";
    OrgsOrderBy["StartChannelIdDesc"] = "START_CHANNEL_ID_DESC";
    OrgsOrderBy["BrandLogoIdAsc"] = "BRAND_LOGO_ID_ASC";
    OrgsOrderBy["BrandLogoIdDesc"] = "BRAND_LOGO_ID_DESC";
    OrgsOrderBy["BrandBackgroundIdAsc"] = "BRAND_BACKGROUND_ID_ASC";
    OrgsOrderBy["BrandBackgroundIdDesc"] = "BRAND_BACKGROUND_ID_DESC";
    OrgsOrderBy["DefaultChannelThemeIdAsc"] = "DEFAULT_CHANNEL_THEME_ID_ASC";
    OrgsOrderBy["DefaultChannelThemeIdDesc"] = "DEFAULT_CHANNEL_THEME_ID_DESC";
    OrgsOrderBy["SplashChannelThemeIdAsc"] = "SPLASH_CHANNEL_THEME_ID_ASC";
    OrgsOrderBy["SplashChannelThemeIdDesc"] = "SPLASH_CHANNEL_THEME_ID_DESC";
    OrgsOrderBy["BillingCustomerIdAsc"] = "BILLING_CUSTOMER_ID_ASC";
    OrgsOrderBy["BillingCustomerIdDesc"] = "BILLING_CUSTOMER_ID_DESC";
    OrgsOrderBy["IsDraftAsc"] = "IS_DRAFT_ASC";
    OrgsOrderBy["IsDraftDesc"] = "IS_DRAFT_DESC";
    OrgsOrderBy["BillingDistributorIdAsc"] = "BILLING_DISTRIBUTOR_ID_ASC";
    OrgsOrderBy["BillingDistributorIdDesc"] = "BILLING_DISTRIBUTOR_ID_DESC";
    OrgsOrderBy["BillingResellerIdAsc"] = "BILLING_RESELLER_ID_ASC";
    OrgsOrderBy["BillingResellerIdDesc"] = "BILLING_RESELLER_ID_DESC";
    OrgsOrderBy["BillingExpiresAtAsc"] = "BILLING_EXPIRES_AT_ASC";
    OrgsOrderBy["BillingExpiresAtDesc"] = "BILLING_EXPIRES_AT_DESC";
    OrgsOrderBy["PrimaryKeyAsc"] = "PRIMARY_KEY_ASC";
    OrgsOrderBy["PrimaryKeyDesc"] = "PRIMARY_KEY_DESC";
})(OrgsOrderBy || (OrgsOrderBy = {}));
/** Methods to use when ordering `User`. */
export var UsersOrderBy;
(function (UsersOrderBy) {
    UsersOrderBy["Natural"] = "NATURAL";
    UsersOrderBy["IdAsc"] = "ID_ASC";
    UsersOrderBy["IdDesc"] = "ID_DESC";
    UsersOrderBy["OrgIdAsc"] = "ORG_ID_ASC";
    UsersOrderBy["OrgIdDesc"] = "ORG_ID_DESC";
    UsersOrderBy["UserTypeAsc"] = "USER_TYPE_ASC";
    UsersOrderBy["UserTypeDesc"] = "USER_TYPE_DESC";
    UsersOrderBy["EmailAsc"] = "EMAIL_ASC";
    UsersOrderBy["EmailDesc"] = "EMAIL_DESC";
    UsersOrderBy["StatusAsc"] = "STATUS_ASC";
    UsersOrderBy["StatusDesc"] = "STATUS_DESC";
    UsersOrderBy["GivenNameAsc"] = "GIVEN_NAME_ASC";
    UsersOrderBy["GivenNameDesc"] = "GIVEN_NAME_DESC";
    UsersOrderBy["FamilyNameAsc"] = "FAMILY_NAME_ASC";
    UsersOrderBy["FamilyNameDesc"] = "FAMILY_NAME_DESC";
    UsersOrderBy["IsOwnerAsc"] = "IS_OWNER_ASC";
    UsersOrderBy["IsOwnerDesc"] = "IS_OWNER_DESC";
    UsersOrderBy["ProviderAsc"] = "PROVIDER_ASC";
    UsersOrderBy["ProviderDesc"] = "PROVIDER_DESC";
    UsersOrderBy["ConnectionAsc"] = "CONNECTION_ASC";
    UsersOrderBy["ConnectionDesc"] = "CONNECTION_DESC";
    UsersOrderBy["MetadataAsc"] = "METADATA_ASC";
    UsersOrderBy["MetadataDesc"] = "METADATA_DESC";
    UsersOrderBy["PreferencesAsc"] = "PREFERENCES_ASC";
    UsersOrderBy["PreferencesDesc"] = "PREFERENCES_DESC";
    UsersOrderBy["ReceiveUpdatesAsc"] = "RECEIVE_UPDATES_ASC";
    UsersOrderBy["ReceiveUpdatesDesc"] = "RECEIVE_UPDATES_DESC";
    UsersOrderBy["CreatedAtAsc"] = "CREATED_AT_ASC";
    UsersOrderBy["CreatedAtDesc"] = "CREATED_AT_DESC";
    UsersOrderBy["UpdatedAtAsc"] = "UPDATED_AT_ASC";
    UsersOrderBy["UpdatedAtDesc"] = "UPDATED_AT_DESC";
    UsersOrderBy["DeletedAtAsc"] = "DELETED_AT_ASC";
    UsersOrderBy["DeletedAtDesc"] = "DELETED_AT_DESC";
    UsersOrderBy["CreatedByAsc"] = "CREATED_BY_ASC";
    UsersOrderBy["CreatedByDesc"] = "CREATED_BY_DESC";
    UsersOrderBy["UpdatedByAsc"] = "UPDATED_BY_ASC";
    UsersOrderBy["UpdatedByDesc"] = "UPDATED_BY_DESC";
    UsersOrderBy["DeletedByAsc"] = "DELETED_BY_ASC";
    UsersOrderBy["DeletedByDesc"] = "DELETED_BY_DESC";
    UsersOrderBy["LastAuthenticatedAtAsc"] = "LAST_AUTHENTICATED_AT_ASC";
    UsersOrderBy["LastAuthenticatedAtDesc"] = "LAST_AUTHENTICATED_AT_DESC";
    UsersOrderBy["PrimaryKeyAsc"] = "PRIMARY_KEY_ASC";
    UsersOrderBy["PrimaryKeyDesc"] = "PRIMARY_KEY_DESC";
})(UsersOrderBy || (UsersOrderBy = {}));
/** Methods to use when ordering `Group`. */
export var GroupsOrderBy;
(function (GroupsOrderBy) {
    GroupsOrderBy["Natural"] = "NATURAL";
    GroupsOrderBy["IdAsc"] = "ID_ASC";
    GroupsOrderBy["IdDesc"] = "ID_DESC";
    GroupsOrderBy["OrgIdAsc"] = "ORG_ID_ASC";
    GroupsOrderBy["OrgIdDesc"] = "ORG_ID_DESC";
    GroupsOrderBy["NameAsc"] = "NAME_ASC";
    GroupsOrderBy["NameDesc"] = "NAME_DESC";
    GroupsOrderBy["DescriptionAsc"] = "DESCRIPTION_ASC";
    GroupsOrderBy["DescriptionDesc"] = "DESCRIPTION_DESC";
    GroupsOrderBy["CreatedAtAsc"] = "CREATED_AT_ASC";
    GroupsOrderBy["CreatedAtDesc"] = "CREATED_AT_DESC";
    GroupsOrderBy["UpdatedAtAsc"] = "UPDATED_AT_ASC";
    GroupsOrderBy["UpdatedAtDesc"] = "UPDATED_AT_DESC";
    GroupsOrderBy["CreatedByAsc"] = "CREATED_BY_ASC";
    GroupsOrderBy["CreatedByDesc"] = "CREATED_BY_DESC";
    GroupsOrderBy["UpdatedByAsc"] = "UPDATED_BY_ASC";
    GroupsOrderBy["UpdatedByDesc"] = "UPDATED_BY_DESC";
    GroupsOrderBy["PermissionsAsc"] = "PERMISSIONS_ASC";
    GroupsOrderBy["PermissionsDesc"] = "PERMISSIONS_DESC";
    GroupsOrderBy["PermissionSetsAsc"] = "PERMISSION_SETS_ASC";
    GroupsOrderBy["PermissionSetsDesc"] = "PERMISSION_SETS_DESC";
    GroupsOrderBy["IsDefaultAsc"] = "IS_DEFAULT_ASC";
    GroupsOrderBy["IsDefaultDesc"] = "IS_DEFAULT_DESC";
    GroupsOrderBy["PrimaryKeyAsc"] = "PRIMARY_KEY_ASC";
    GroupsOrderBy["PrimaryKeyDesc"] = "PRIMARY_KEY_DESC";
})(GroupsOrderBy || (GroupsOrderBy = {}));
/** Methods to use when ordering `UserGroup`. */
export var UserGroupsOrderBy;
(function (UserGroupsOrderBy) {
    UserGroupsOrderBy["Natural"] = "NATURAL";
    UserGroupsOrderBy["OrgIdAsc"] = "ORG_ID_ASC";
    UserGroupsOrderBy["OrgIdDesc"] = "ORG_ID_DESC";
    UserGroupsOrderBy["UserIdAsc"] = "USER_ID_ASC";
    UserGroupsOrderBy["UserIdDesc"] = "USER_ID_DESC";
    UserGroupsOrderBy["GroupIdAsc"] = "GROUP_ID_ASC";
    UserGroupsOrderBy["GroupIdDesc"] = "GROUP_ID_DESC";
    UserGroupsOrderBy["CreatedAtAsc"] = "CREATED_AT_ASC";
    UserGroupsOrderBy["CreatedAtDesc"] = "CREATED_AT_DESC";
    UserGroupsOrderBy["CreatedByAsc"] = "CREATED_BY_ASC";
    UserGroupsOrderBy["CreatedByDesc"] = "CREATED_BY_DESC";
    UserGroupsOrderBy["PermissionsAsc"] = "PERMISSIONS_ASC";
    UserGroupsOrderBy["PermissionsDesc"] = "PERMISSIONS_DESC";
    UserGroupsOrderBy["PermissionSetsAsc"] = "PERMISSION_SETS_ASC";
    UserGroupsOrderBy["PermissionSetsDesc"] = "PERMISSION_SETS_DESC";
    UserGroupsOrderBy["PrimaryKeyAsc"] = "PRIMARY_KEY_ASC";
    UserGroupsOrderBy["PrimaryKeyDesc"] = "PRIMARY_KEY_DESC";
})(UserGroupsOrderBy || (UserGroupsOrderBy = {}));
/** Methods to use when ordering `GroupSpace`. */
export var GroupSpacesOrderBy;
(function (GroupSpacesOrderBy) {
    GroupSpacesOrderBy["Natural"] = "NATURAL";
    GroupSpacesOrderBy["OrgIdAsc"] = "ORG_ID_ASC";
    GroupSpacesOrderBy["OrgIdDesc"] = "ORG_ID_DESC";
    GroupSpacesOrderBy["GroupIdAsc"] = "GROUP_ID_ASC";
    GroupSpacesOrderBy["GroupIdDesc"] = "GROUP_ID_DESC";
    GroupSpacesOrderBy["SpaceIdAsc"] = "SPACE_ID_ASC";
    GroupSpacesOrderBy["SpaceIdDesc"] = "SPACE_ID_DESC";
    GroupSpacesOrderBy["PermissionsAsc"] = "PERMISSIONS_ASC";
    GroupSpacesOrderBy["PermissionsDesc"] = "PERMISSIONS_DESC";
    GroupSpacesOrderBy["PermissionSetsAsc"] = "PERMISSION_SETS_ASC";
    GroupSpacesOrderBy["PermissionSetsDesc"] = "PERMISSION_SETS_DESC";
    GroupSpacesOrderBy["PrimaryKeyAsc"] = "PRIMARY_KEY_ASC";
    GroupSpacesOrderBy["PrimaryKeyDesc"] = "PRIMARY_KEY_DESC";
})(GroupSpacesOrderBy || (GroupSpacesOrderBy = {}));
export var CurrencyCode;
(function (CurrencyCode) {
    CurrencyCode["Usd"] = "USD";
    CurrencyCode["Gbp"] = "GBP";
    CurrencyCode["Eur"] = "EUR";
})(CurrencyCode || (CurrencyCode = {}));
export var StateCode;
(function (StateCode) {
    StateCode["Al"] = "AL";
    StateCode["Ak"] = "AK";
    StateCode["Az"] = "AZ";
    StateCode["Ar"] = "AR";
    StateCode["Ca"] = "CA";
    StateCode["Co"] = "CO";
    StateCode["Ct"] = "CT";
    StateCode["De"] = "DE";
    StateCode["Fl"] = "FL";
    StateCode["Ga"] = "GA";
    StateCode["Hi"] = "HI";
    StateCode["Id"] = "ID";
    StateCode["Il"] = "IL";
    StateCode["In"] = "IN";
    StateCode["Ia"] = "IA";
    StateCode["Ks"] = "KS";
    StateCode["Ky"] = "KY";
    StateCode["La"] = "LA";
    StateCode["Me"] = "ME";
    StateCode["Md"] = "MD";
    StateCode["Ma"] = "MA";
    StateCode["Mi"] = "MI";
    StateCode["Mn"] = "MN";
    StateCode["Ms"] = "MS";
    StateCode["Mo"] = "MO";
    StateCode["Mt"] = "MT";
    StateCode["Ne"] = "NE";
    StateCode["Nv"] = "NV";
    StateCode["Nh"] = "NH";
    StateCode["Nj"] = "NJ";
    StateCode["Nm"] = "NM";
    StateCode["Ny"] = "NY";
    StateCode["Nc"] = "NC";
    StateCode["Nd"] = "ND";
    StateCode["Oh"] = "OH";
    StateCode["Ok"] = "OK";
    StateCode["Or"] = "OR";
    StateCode["Pa"] = "PA";
    StateCode["Ri"] = "RI";
    StateCode["Sc"] = "SC";
    StateCode["Sd"] = "SD";
    StateCode["Tn"] = "TN";
    StateCode["Tx"] = "TX";
    StateCode["Ut"] = "UT";
    StateCode["Vt"] = "VT";
    StateCode["Va"] = "VA";
    StateCode["Wa"] = "WA";
    StateCode["Wv"] = "WV";
    StateCode["Wi"] = "WI";
    StateCode["Wy"] = "WY";
    StateCode["Dc"] = "DC";
    StateCode["As"] = "AS";
    StateCode["Gu"] = "GU";
    StateCode["Mp"] = "MP";
    StateCode["Pr"] = "PR";
    StateCode["Um"] = "UM";
    StateCode["Vi"] = "VI";
    StateCode["Ab"] = "AB";
    StateCode["Bc"] = "BC";
    StateCode["Mb"] = "MB";
    StateCode["Nb"] = "NB";
    StateCode["Nl"] = "NL";
    StateCode["Ns"] = "NS";
    StateCode["On"] = "ON";
    StateCode["Pe"] = "PE";
    StateCode["Qc"] = "QC";
    StateCode["Sk"] = "SK";
    StateCode["Nt"] = "NT";
    StateCode["Nu"] = "NU";
    StateCode["Yt"] = "YT";
})(StateCode || (StateCode = {}));
export var CountryCode;
(function (CountryCode) {
    CountryCode["Ad"] = "AD";
    CountryCode["Ae"] = "AE";
    CountryCode["Af"] = "AF";
    CountryCode["Ag"] = "AG";
    CountryCode["Ai"] = "AI";
    CountryCode["Al"] = "AL";
    CountryCode["Am"] = "AM";
    CountryCode["Ao"] = "AO";
    CountryCode["Aq"] = "AQ";
    CountryCode["Ar"] = "AR";
    CountryCode["As"] = "AS";
    CountryCode["At"] = "AT";
    CountryCode["Au"] = "AU";
    CountryCode["Aw"] = "AW";
    CountryCode["Ax"] = "AX";
    CountryCode["Az"] = "AZ";
    CountryCode["Ba"] = "BA";
    CountryCode["Bb"] = "BB";
    CountryCode["Bd"] = "BD";
    CountryCode["Be"] = "BE";
    CountryCode["Bf"] = "BF";
    CountryCode["Bg"] = "BG";
    CountryCode["Bh"] = "BH";
    CountryCode["Bi"] = "BI";
    CountryCode["Bj"] = "BJ";
    CountryCode["Bl"] = "BL";
    CountryCode["Bm"] = "BM";
    CountryCode["Bn"] = "BN";
    CountryCode["Bo"] = "BO";
    CountryCode["Bq"] = "BQ";
    CountryCode["Br"] = "BR";
    CountryCode["Bs"] = "BS";
    CountryCode["Bt"] = "BT";
    CountryCode["Bv"] = "BV";
    CountryCode["Bw"] = "BW";
    CountryCode["By"] = "BY";
    CountryCode["Bz"] = "BZ";
    CountryCode["Ca"] = "CA";
    CountryCode["Cc"] = "CC";
    CountryCode["Cd"] = "CD";
    CountryCode["Cf"] = "CF";
    CountryCode["Cg"] = "CG";
    CountryCode["Ch"] = "CH";
    CountryCode["Ci"] = "CI";
    CountryCode["Ck"] = "CK";
    CountryCode["Cl"] = "CL";
    CountryCode["Cm"] = "CM";
    CountryCode["Cn"] = "CN";
    CountryCode["Co"] = "CO";
    CountryCode["Cr"] = "CR";
    CountryCode["Cu"] = "CU";
    CountryCode["Cv"] = "CV";
    CountryCode["Cw"] = "CW";
    CountryCode["Cx"] = "CX";
    CountryCode["Cy"] = "CY";
    CountryCode["Cz"] = "CZ";
    CountryCode["De"] = "DE";
    CountryCode["Dj"] = "DJ";
    CountryCode["Dk"] = "DK";
    CountryCode["Dm"] = "DM";
    CountryCode["Do"] = "DO";
    CountryCode["Dz"] = "DZ";
    CountryCode["Ec"] = "EC";
    CountryCode["Ee"] = "EE";
    CountryCode["Eg"] = "EG";
    CountryCode["Eh"] = "EH";
    CountryCode["Er"] = "ER";
    CountryCode["Es"] = "ES";
    CountryCode["Et"] = "ET";
    CountryCode["Fi"] = "FI";
    CountryCode["Fj"] = "FJ";
    CountryCode["Fk"] = "FK";
    CountryCode["Fm"] = "FM";
    CountryCode["Fo"] = "FO";
    CountryCode["Fr"] = "FR";
    CountryCode["Ga"] = "GA";
    CountryCode["Gb"] = "GB";
    CountryCode["Gd"] = "GD";
    CountryCode["Ge"] = "GE";
    CountryCode["Gf"] = "GF";
    CountryCode["Gg"] = "GG";
    CountryCode["Gh"] = "GH";
    CountryCode["Gi"] = "GI";
    CountryCode["Gl"] = "GL";
    CountryCode["Gm"] = "GM";
    CountryCode["Gn"] = "GN";
    CountryCode["Gp"] = "GP";
    CountryCode["Gq"] = "GQ";
    CountryCode["Gr"] = "GR";
    CountryCode["Gs"] = "GS";
    CountryCode["Gt"] = "GT";
    CountryCode["Gu"] = "GU";
    CountryCode["Gw"] = "GW";
    CountryCode["Gy"] = "GY";
    CountryCode["Hk"] = "HK";
    CountryCode["Hm"] = "HM";
    CountryCode["Hn"] = "HN";
    CountryCode["Hr"] = "HR";
    CountryCode["Ht"] = "HT";
    CountryCode["Hu"] = "HU";
    CountryCode["Id"] = "ID";
    CountryCode["Ie"] = "IE";
    CountryCode["Il"] = "IL";
    CountryCode["Im"] = "IM";
    CountryCode["In"] = "IN";
    CountryCode["Io"] = "IO";
    CountryCode["Iq"] = "IQ";
    CountryCode["Ir"] = "IR";
    CountryCode["Is"] = "IS";
    CountryCode["It"] = "IT";
    CountryCode["Je"] = "JE";
    CountryCode["Jm"] = "JM";
    CountryCode["Jo"] = "JO";
    CountryCode["Jp"] = "JP";
    CountryCode["Ke"] = "KE";
    CountryCode["Kg"] = "KG";
    CountryCode["Kh"] = "KH";
    CountryCode["Ki"] = "KI";
    CountryCode["Km"] = "KM";
    CountryCode["Kn"] = "KN";
    CountryCode["Kp"] = "KP";
    CountryCode["Kr"] = "KR";
    CountryCode["Kw"] = "KW";
    CountryCode["Ky"] = "KY";
    CountryCode["Kz"] = "KZ";
    CountryCode["La"] = "LA";
    CountryCode["Lb"] = "LB";
    CountryCode["Lc"] = "LC";
    CountryCode["Li"] = "LI";
    CountryCode["Lk"] = "LK";
    CountryCode["Lr"] = "LR";
    CountryCode["Ls"] = "LS";
    CountryCode["Lt"] = "LT";
    CountryCode["Lu"] = "LU";
    CountryCode["Lv"] = "LV";
    CountryCode["Ly"] = "LY";
    CountryCode["Ma"] = "MA";
    CountryCode["Mc"] = "MC";
    CountryCode["Md"] = "MD";
    CountryCode["Me"] = "ME";
    CountryCode["Mf"] = "MF";
    CountryCode["Mg"] = "MG";
    CountryCode["Mh"] = "MH";
    CountryCode["Mk"] = "MK";
    CountryCode["Ml"] = "ML";
    CountryCode["Mm"] = "MM";
    CountryCode["Mn"] = "MN";
    CountryCode["Mo"] = "MO";
    CountryCode["Mp"] = "MP";
    CountryCode["Mq"] = "MQ";
    CountryCode["Mr"] = "MR";
    CountryCode["Ms"] = "MS";
    CountryCode["Mt"] = "MT";
    CountryCode["Mu"] = "MU";
    CountryCode["Mv"] = "MV";
    CountryCode["Mw"] = "MW";
    CountryCode["Mx"] = "MX";
    CountryCode["My"] = "MY";
    CountryCode["Mz"] = "MZ";
    CountryCode["Na"] = "NA";
    CountryCode["Nc"] = "NC";
    CountryCode["Ne"] = "NE";
    CountryCode["Nf"] = "NF";
    CountryCode["Ng"] = "NG";
    CountryCode["Ni"] = "NI";
    CountryCode["Nl"] = "NL";
    CountryCode["No"] = "NO";
    CountryCode["Np"] = "NP";
    CountryCode["Nr"] = "NR";
    CountryCode["Nu"] = "NU";
    CountryCode["Nz"] = "NZ";
    CountryCode["Om"] = "OM";
    CountryCode["Pa"] = "PA";
    CountryCode["Pe"] = "PE";
    CountryCode["Pf"] = "PF";
    CountryCode["Pg"] = "PG";
    CountryCode["Ph"] = "PH";
    CountryCode["Pk"] = "PK";
    CountryCode["Pl"] = "PL";
    CountryCode["Pm"] = "PM";
    CountryCode["Pn"] = "PN";
    CountryCode["Pr"] = "PR";
    CountryCode["Ps"] = "PS";
    CountryCode["Pt"] = "PT";
    CountryCode["Pw"] = "PW";
    CountryCode["Py"] = "PY";
    CountryCode["Qa"] = "QA";
    CountryCode["Re"] = "RE";
    CountryCode["Ro"] = "RO";
    CountryCode["Rs"] = "RS";
    CountryCode["Ru"] = "RU";
    CountryCode["Rw"] = "RW";
    CountryCode["Sa"] = "SA";
    CountryCode["Sb"] = "SB";
    CountryCode["Sc"] = "SC";
    CountryCode["Sd"] = "SD";
    CountryCode["Se"] = "SE";
    CountryCode["Sg"] = "SG";
    CountryCode["Sh"] = "SH";
    CountryCode["Si"] = "SI";
    CountryCode["Sj"] = "SJ";
    CountryCode["Sk"] = "SK";
    CountryCode["Sl"] = "SL";
    CountryCode["Sm"] = "SM";
    CountryCode["Sn"] = "SN";
    CountryCode["So"] = "SO";
    CountryCode["Sr"] = "SR";
    CountryCode["Ss"] = "SS";
    CountryCode["St"] = "ST";
    CountryCode["Sv"] = "SV";
    CountryCode["Sx"] = "SX";
    CountryCode["Sy"] = "SY";
    CountryCode["Sz"] = "SZ";
    CountryCode["Tc"] = "TC";
    CountryCode["Td"] = "TD";
    CountryCode["Tf"] = "TF";
    CountryCode["Tg"] = "TG";
    CountryCode["Th"] = "TH";
    CountryCode["Tj"] = "TJ";
    CountryCode["Tk"] = "TK";
    CountryCode["Tl"] = "TL";
    CountryCode["Tm"] = "TM";
    CountryCode["Tn"] = "TN";
    CountryCode["To"] = "TO";
    CountryCode["Tr"] = "TR";
    CountryCode["Tt"] = "TT";
    CountryCode["Tv"] = "TV";
    CountryCode["Tw"] = "TW";
    CountryCode["Tz"] = "TZ";
    CountryCode["Ua"] = "UA";
    CountryCode["Ug"] = "UG";
    CountryCode["Um"] = "UM";
    CountryCode["Us"] = "US";
    CountryCode["Uy"] = "UY";
    CountryCode["Uz"] = "UZ";
    CountryCode["Va"] = "VA";
    CountryCode["Vc"] = "VC";
    CountryCode["Ve"] = "VE";
    CountryCode["Vg"] = "VG";
    CountryCode["Vi"] = "VI";
    CountryCode["Vn"] = "VN";
    CountryCode["Vu"] = "VU";
    CountryCode["Wf"] = "WF";
    CountryCode["Ws"] = "WS";
    CountryCode["Ye"] = "YE";
    CountryCode["Yt"] = "YT";
    CountryCode["Za"] = "ZA";
    CountryCode["Zm"] = "ZM";
    CountryCode["Zw"] = "ZW";
})(CountryCode || (CountryCode = {}));
/** Methods to use when ordering `BillingReseller`. */
export var BillingResellersOrderBy;
(function (BillingResellersOrderBy) {
    BillingResellersOrderBy["Natural"] = "NATURAL";
    BillingResellersOrderBy["IdAsc"] = "ID_ASC";
    BillingResellersOrderBy["IdDesc"] = "ID_DESC";
    BillingResellersOrderBy["BillingDistributorIdAsc"] = "BILLING_DISTRIBUTOR_ID_ASC";
    BillingResellersOrderBy["BillingDistributorIdDesc"] = "BILLING_DISTRIBUTOR_ID_DESC";
    BillingResellersOrderBy["PaymentOwnerIdAsc"] = "PAYMENT_OWNER_ID_ASC";
    BillingResellersOrderBy["PaymentOwnerIdDesc"] = "PAYMENT_OWNER_ID_DESC";
    BillingResellersOrderBy["InvoiceOwnerIdAsc"] = "INVOICE_OWNER_ID_ASC";
    BillingResellersOrderBy["InvoiceOwnerIdDesc"] = "INVOICE_OWNER_ID_DESC";
    BillingResellersOrderBy["CompanyAsc"] = "COMPANY_ASC";
    BillingResellersOrderBy["CompanyDesc"] = "COMPANY_DESC";
    BillingResellersOrderBy["FirstNameAsc"] = "FIRST_NAME_ASC";
    BillingResellersOrderBy["FirstNameDesc"] = "FIRST_NAME_DESC";
    BillingResellersOrderBy["LastNameAsc"] = "LAST_NAME_ASC";
    BillingResellersOrderBy["LastNameDesc"] = "LAST_NAME_DESC";
    BillingResellersOrderBy["EmailAsc"] = "EMAIL_ASC";
    BillingResellersOrderBy["EmailDesc"] = "EMAIL_DESC";
    BillingResellersOrderBy["PhoneAsc"] = "PHONE_ASC";
    BillingResellersOrderBy["PhoneDesc"] = "PHONE_DESC";
    BillingResellersOrderBy["Line1Asc"] = "LINE1_ASC";
    BillingResellersOrderBy["Line1Desc"] = "LINE1_DESC";
    BillingResellersOrderBy["Line2Asc"] = "LINE2_ASC";
    BillingResellersOrderBy["Line2Desc"] = "LINE2_DESC";
    BillingResellersOrderBy["Line3Asc"] = "LINE3_ASC";
    BillingResellersOrderBy["Line3Desc"] = "LINE3_DESC";
    BillingResellersOrderBy["CityAsc"] = "CITY_ASC";
    BillingResellersOrderBy["CityDesc"] = "CITY_DESC";
    BillingResellersOrderBy["StateCodeAsc"] = "STATE_CODE_ASC";
    BillingResellersOrderBy["StateCodeDesc"] = "STATE_CODE_DESC";
    BillingResellersOrderBy["StateAsc"] = "STATE_ASC";
    BillingResellersOrderBy["StateDesc"] = "STATE_DESC";
    BillingResellersOrderBy["CountryAsc"] = "COUNTRY_ASC";
    BillingResellersOrderBy["CountryDesc"] = "COUNTRY_DESC";
    BillingResellersOrderBy["ZipAsc"] = "ZIP_ASC";
    BillingResellersOrderBy["ZipDesc"] = "ZIP_DESC";
    BillingResellersOrderBy["DeletedAsc"] = "DELETED_ASC";
    BillingResellersOrderBy["DeletedDesc"] = "DELETED_DESC";
    BillingResellersOrderBy["CfCloseIoIdAsc"] = "CF_CLOSE_IO_ID_ASC";
    BillingResellersOrderBy["CfCloseIoIdDesc"] = "CF_CLOSE_IO_ID_DESC";
    BillingResellersOrderBy["CfCustomerTypeAsc"] = "CF_CUSTOMER_TYPE_ASC";
    BillingResellersOrderBy["CfCustomerTypeDesc"] = "CF_CUSTOMER_TYPE_DESC";
    BillingResellersOrderBy["CfSalesAgentAsc"] = "CF_SALES_AGENT_ASC";
    BillingResellersOrderBy["CfSalesAgentDesc"] = "CF_SALES_AGENT_DESC";
    BillingResellersOrderBy["CfAllowLicenceAsc"] = "CF_ALLOW_LICENCE_ASC";
    BillingResellersOrderBy["CfAllowLicenceDesc"] = "CF_ALLOW_LICENCE_DESC";
    BillingResellersOrderBy["CfPrimaryAsc"] = "CF_PRIMARY_ASC";
    BillingResellersOrderBy["CfPrimaryDesc"] = "CF_PRIMARY_DESC";
    BillingResellersOrderBy["CfPartnerAsc"] = "CF_PARTNER_ASC";
    BillingResellersOrderBy["CfPartnerDesc"] = "CF_PARTNER_DESC";
    BillingResellersOrderBy["CreatedAtAsc"] = "CREATED_AT_ASC";
    BillingResellersOrderBy["CreatedAtDesc"] = "CREATED_AT_DESC";
    BillingResellersOrderBy["UpdatedAtAsc"] = "UPDATED_AT_ASC";
    BillingResellersOrderBy["UpdatedAtDesc"] = "UPDATED_AT_DESC";
    BillingResellersOrderBy["PrimaryKeyAsc"] = "PRIMARY_KEY_ASC";
    BillingResellersOrderBy["PrimaryKeyDesc"] = "PRIMARY_KEY_DESC";
})(BillingResellersOrderBy || (BillingResellersOrderBy = {}));
/** Methods to use when ordering `BillingCustomer`. */
export var BillingCustomersOrderBy;
(function (BillingCustomersOrderBy) {
    BillingCustomersOrderBy["Natural"] = "NATURAL";
    BillingCustomersOrderBy["IdAsc"] = "ID_ASC";
    BillingCustomersOrderBy["IdDesc"] = "ID_DESC";
    BillingCustomersOrderBy["OrgIdAsc"] = "ORG_ID_ASC";
    BillingCustomersOrderBy["OrgIdDesc"] = "ORG_ID_DESC";
    BillingCustomersOrderBy["ProviderAsc"] = "PROVIDER_ASC";
    BillingCustomersOrderBy["ProviderDesc"] = "PROVIDER_DESC";
    BillingCustomersOrderBy["ParentIdAsc"] = "PARENT_ID_ASC";
    BillingCustomersOrderBy["ParentIdDesc"] = "PARENT_ID_DESC";
    BillingCustomersOrderBy["PaymentOwnerIdAsc"] = "PAYMENT_OWNER_ID_ASC";
    BillingCustomersOrderBy["PaymentOwnerIdDesc"] = "PAYMENT_OWNER_ID_DESC";
    BillingCustomersOrderBy["InvoiceOwnerIdAsc"] = "INVOICE_OWNER_ID_ASC";
    BillingCustomersOrderBy["InvoiceOwnerIdDesc"] = "INVOICE_OWNER_ID_DESC";
    BillingCustomersOrderBy["CompanyAsc"] = "COMPANY_ASC";
    BillingCustomersOrderBy["CompanyDesc"] = "COMPANY_DESC";
    BillingCustomersOrderBy["FirstNameAsc"] = "FIRST_NAME_ASC";
    BillingCustomersOrderBy["FirstNameDesc"] = "FIRST_NAME_DESC";
    BillingCustomersOrderBy["LastNameAsc"] = "LAST_NAME_ASC";
    BillingCustomersOrderBy["LastNameDesc"] = "LAST_NAME_DESC";
    BillingCustomersOrderBy["EmailAsc"] = "EMAIL_ASC";
    BillingCustomersOrderBy["EmailDesc"] = "EMAIL_DESC";
    BillingCustomersOrderBy["PhoneAsc"] = "PHONE_ASC";
    BillingCustomersOrderBy["PhoneDesc"] = "PHONE_DESC";
    BillingCustomersOrderBy["VatNumberAsc"] = "VAT_NUMBER_ASC";
    BillingCustomersOrderBy["VatNumberDesc"] = "VAT_NUMBER_DESC";
    BillingCustomersOrderBy["VatNumberStatusAsc"] = "VAT_NUMBER_STATUS_ASC";
    BillingCustomersOrderBy["VatNumberStatusDesc"] = "VAT_NUMBER_STATUS_DESC";
    BillingCustomersOrderBy["AutoCollectionAsc"] = "AUTO_COLLECTION_ASC";
    BillingCustomersOrderBy["AutoCollectionDesc"] = "AUTO_COLLECTION_DESC";
    BillingCustomersOrderBy["TaxabilityAsc"] = "TAXABILITY_ASC";
    BillingCustomersOrderBy["TaxabilityDesc"] = "TAXABILITY_DESC";
    BillingCustomersOrderBy["BillingDateAsc"] = "BILLING_DATE_ASC";
    BillingCustomersOrderBy["BillingDateDesc"] = "BILLING_DATE_DESC";
    BillingCustomersOrderBy["BillingDateModeAsc"] = "BILLING_DATE_MODE_ASC";
    BillingCustomersOrderBy["BillingDateModeDesc"] = "BILLING_DATE_MODE_DESC";
    BillingCustomersOrderBy["BillingDayOfWeekAsc"] = "BILLING_DAY_OF_WEEK_ASC";
    BillingCustomersOrderBy["BillingDayOfWeekDesc"] = "BILLING_DAY_OF_WEEK_DESC";
    BillingCustomersOrderBy["BillingDayOfWeekModeAsc"] = "BILLING_DAY_OF_WEEK_MODE_ASC";
    BillingCustomersOrderBy["BillingDayOfWeekModeDesc"] = "BILLING_DAY_OF_WEEK_MODE_DESC";
    BillingCustomersOrderBy["PreferredCurrencyCodeAsc"] = "PREFERRED_CURRENCY_CODE_ASC";
    BillingCustomersOrderBy["PreferredCurrencyCodeDesc"] = "PREFERRED_CURRENCY_CODE_DESC";
    BillingCustomersOrderBy["DeletedAsc"] = "DELETED_ASC";
    BillingCustomersOrderBy["DeletedDesc"] = "DELETED_DESC";
    BillingCustomersOrderBy["CfAllowLicenceAsc"] = "CF_ALLOW_LICENCE_ASC";
    BillingCustomersOrderBy["CfAllowLicenceDesc"] = "CF_ALLOW_LICENCE_DESC";
    BillingCustomersOrderBy["CfPrimaryAsc"] = "CF_PRIMARY_ASC";
    BillingCustomersOrderBy["CfPrimaryDesc"] = "CF_PRIMARY_DESC";
    BillingCustomersOrderBy["CreatedAtAsc"] = "CREATED_AT_ASC";
    BillingCustomersOrderBy["CreatedAtDesc"] = "CREATED_AT_DESC";
    BillingCustomersOrderBy["UpdatedAtAsc"] = "UPDATED_AT_ASC";
    BillingCustomersOrderBy["UpdatedAtDesc"] = "UPDATED_AT_DESC";
    BillingCustomersOrderBy["BillingResellerIdAsc"] = "BILLING_RESELLER_ID_ASC";
    BillingCustomersOrderBy["BillingResellerIdDesc"] = "BILLING_RESELLER_ID_DESC";
    BillingCustomersOrderBy["CfPartnerAsc"] = "CF_PARTNER_ASC";
    BillingCustomersOrderBy["CfPartnerDesc"] = "CF_PARTNER_DESC";
    BillingCustomersOrderBy["PrimaryKeyAsc"] = "PRIMARY_KEY_ASC";
    BillingCustomersOrderBy["PrimaryKeyDesc"] = "PRIMARY_KEY_DESC";
})(BillingCustomersOrderBy || (BillingCustomersOrderBy = {}));
/** Methods to use when ordering `Space`. */
export var SpacesOrderBy;
(function (SpacesOrderBy) {
    SpacesOrderBy["Natural"] = "NATURAL";
    SpacesOrderBy["IdAsc"] = "ID_ASC";
    SpacesOrderBy["IdDesc"] = "ID_DESC";
    SpacesOrderBy["OrgIdAsc"] = "ORG_ID_ASC";
    SpacesOrderBy["OrgIdDesc"] = "ORG_ID_DESC";
    SpacesOrderBy["NameAsc"] = "NAME_ASC";
    SpacesOrderBy["NameDesc"] = "NAME_DESC";
    SpacesOrderBy["SlugAsc"] = "SLUG_ASC";
    SpacesOrderBy["SlugDesc"] = "SLUG_DESC";
    SpacesOrderBy["EnvAsc"] = "ENV_ASC";
    SpacesOrderBy["EnvDesc"] = "ENV_DESC";
    SpacesOrderBy["CreatedAtAsc"] = "CREATED_AT_ASC";
    SpacesOrderBy["CreatedAtDesc"] = "CREATED_AT_DESC";
    SpacesOrderBy["UpdatedAtAsc"] = "UPDATED_AT_ASC";
    SpacesOrderBy["UpdatedAtDesc"] = "UPDATED_AT_DESC";
    SpacesOrderBy["CreatedByAsc"] = "CREATED_BY_ASC";
    SpacesOrderBy["CreatedByDesc"] = "CREATED_BY_DESC";
    SpacesOrderBy["UpdatedByAsc"] = "UPDATED_BY_ASC";
    SpacesOrderBy["UpdatedByDesc"] = "UPDATED_BY_DESC";
    SpacesOrderBy["BillingCustomerIdAsc"] = "BILLING_CUSTOMER_ID_ASC";
    SpacesOrderBy["BillingCustomerIdDesc"] = "BILLING_CUSTOMER_ID_DESC";
    SpacesOrderBy["PreferencesAsc"] = "PREFERENCES_ASC";
    SpacesOrderBy["PreferencesDesc"] = "PREFERENCES_DESC";
    SpacesOrderBy["BillingExpiresAtAsc"] = "BILLING_EXPIRES_AT_ASC";
    SpacesOrderBy["BillingExpiresAtDesc"] = "BILLING_EXPIRES_AT_DESC";
    SpacesOrderBy["PrimaryKeyAsc"] = "PRIMARY_KEY_ASC";
    SpacesOrderBy["PrimaryKeyDesc"] = "PRIMARY_KEY_DESC";
})(SpacesOrderBy || (SpacesOrderBy = {}));
/** Methods to use when ordering `BillingAddress`. */
export var BillingAddressesOrderBy;
(function (BillingAddressesOrderBy) {
    BillingAddressesOrderBy["Natural"] = "NATURAL";
    BillingAddressesOrderBy["BillingCustomerIdAsc"] = "BILLING_CUSTOMER_ID_ASC";
    BillingAddressesOrderBy["BillingCustomerIdDesc"] = "BILLING_CUSTOMER_ID_DESC";
    BillingAddressesOrderBy["OrgIdAsc"] = "ORG_ID_ASC";
    BillingAddressesOrderBy["OrgIdDesc"] = "ORG_ID_DESC";
    BillingAddressesOrderBy["FirstNameAsc"] = "FIRST_NAME_ASC";
    BillingAddressesOrderBy["FirstNameDesc"] = "FIRST_NAME_DESC";
    BillingAddressesOrderBy["LastNameAsc"] = "LAST_NAME_ASC";
    BillingAddressesOrderBy["LastNameDesc"] = "LAST_NAME_DESC";
    BillingAddressesOrderBy["EmailAsc"] = "EMAIL_ASC";
    BillingAddressesOrderBy["EmailDesc"] = "EMAIL_DESC";
    BillingAddressesOrderBy["CompanyAsc"] = "COMPANY_ASC";
    BillingAddressesOrderBy["CompanyDesc"] = "COMPANY_DESC";
    BillingAddressesOrderBy["PhoneAsc"] = "PHONE_ASC";
    BillingAddressesOrderBy["PhoneDesc"] = "PHONE_DESC";
    BillingAddressesOrderBy["Line1Asc"] = "LINE1_ASC";
    BillingAddressesOrderBy["Line1Desc"] = "LINE1_DESC";
    BillingAddressesOrderBy["Line2Asc"] = "LINE2_ASC";
    BillingAddressesOrderBy["Line2Desc"] = "LINE2_DESC";
    BillingAddressesOrderBy["Line3Asc"] = "LINE3_ASC";
    BillingAddressesOrderBy["Line3Desc"] = "LINE3_DESC";
    BillingAddressesOrderBy["CityAsc"] = "CITY_ASC";
    BillingAddressesOrderBy["CityDesc"] = "CITY_DESC";
    BillingAddressesOrderBy["StateCodeAsc"] = "STATE_CODE_ASC";
    BillingAddressesOrderBy["StateCodeDesc"] = "STATE_CODE_DESC";
    BillingAddressesOrderBy["StateAsc"] = "STATE_ASC";
    BillingAddressesOrderBy["StateDesc"] = "STATE_DESC";
    BillingAddressesOrderBy["CountryAsc"] = "COUNTRY_ASC";
    BillingAddressesOrderBy["CountryDesc"] = "COUNTRY_DESC";
    BillingAddressesOrderBy["ZipAsc"] = "ZIP_ASC";
    BillingAddressesOrderBy["ZipDesc"] = "ZIP_DESC";
    BillingAddressesOrderBy["ValidationStatusAsc"] = "VALIDATION_STATUS_ASC";
    BillingAddressesOrderBy["ValidationStatusDesc"] = "VALIDATION_STATUS_DESC";
    BillingAddressesOrderBy["PrimaryKeyAsc"] = "PRIMARY_KEY_ASC";
    BillingAddressesOrderBy["PrimaryKeyDesc"] = "PRIMARY_KEY_DESC";
})(BillingAddressesOrderBy || (BillingAddressesOrderBy = {}));
/** Methods to use when ordering `BillingPaymentSource`. */
export var BillingPaymentSourcesOrderBy;
(function (BillingPaymentSourcesOrderBy) {
    BillingPaymentSourcesOrderBy["Natural"] = "NATURAL";
    BillingPaymentSourcesOrderBy["IdAsc"] = "ID_ASC";
    BillingPaymentSourcesOrderBy["IdDesc"] = "ID_DESC";
    BillingPaymentSourcesOrderBy["BillingCustomerIdAsc"] = "BILLING_CUSTOMER_ID_ASC";
    BillingPaymentSourcesOrderBy["BillingCustomerIdDesc"] = "BILLING_CUSTOMER_ID_DESC";
    BillingPaymentSourcesOrderBy["OrgIdAsc"] = "ORG_ID_ASC";
    BillingPaymentSourcesOrderBy["OrgIdDesc"] = "ORG_ID_DESC";
    BillingPaymentSourcesOrderBy["TypeAsc"] = "TYPE_ASC";
    BillingPaymentSourcesOrderBy["TypeDesc"] = "TYPE_DESC";
    BillingPaymentSourcesOrderBy["StatusAsc"] = "STATUS_ASC";
    BillingPaymentSourcesOrderBy["StatusDesc"] = "STATUS_DESC";
    BillingPaymentSourcesOrderBy["GatewayAsc"] = "GATEWAY_ASC";
    BillingPaymentSourcesOrderBy["GatewayDesc"] = "GATEWAY_DESC";
    BillingPaymentSourcesOrderBy["GatewayAccountIdAsc"] = "GATEWAY_ACCOUNT_ID_ASC";
    BillingPaymentSourcesOrderBy["GatewayAccountIdDesc"] = "GATEWAY_ACCOUNT_ID_DESC";
    BillingPaymentSourcesOrderBy["IssuingCountryAsc"] = "ISSUING_COUNTRY_ASC";
    BillingPaymentSourcesOrderBy["IssuingCountryDesc"] = "ISSUING_COUNTRY_DESC";
    BillingPaymentSourcesOrderBy["CardAsc"] = "CARD_ASC";
    BillingPaymentSourcesOrderBy["CardDesc"] = "CARD_DESC";
    BillingPaymentSourcesOrderBy["BankAccountAsc"] = "BANK_ACCOUNT_ASC";
    BillingPaymentSourcesOrderBy["BankAccountDesc"] = "BANK_ACCOUNT_DESC";
    BillingPaymentSourcesOrderBy["AmazonPaymentAsc"] = "AMAZON_PAYMENT_ASC";
    BillingPaymentSourcesOrderBy["AmazonPaymentDesc"] = "AMAZON_PAYMENT_DESC";
    BillingPaymentSourcesOrderBy["PaypalAsc"] = "PAYPAL_ASC";
    BillingPaymentSourcesOrderBy["PaypalDesc"] = "PAYPAL_DESC";
    BillingPaymentSourcesOrderBy["CreatedAtAsc"] = "CREATED_AT_ASC";
    BillingPaymentSourcesOrderBy["CreatedAtDesc"] = "CREATED_AT_DESC";
    BillingPaymentSourcesOrderBy["UpdatedAtAsc"] = "UPDATED_AT_ASC";
    BillingPaymentSourcesOrderBy["UpdatedAtDesc"] = "UPDATED_AT_DESC";
    BillingPaymentSourcesOrderBy["PrimaryKeyAsc"] = "PRIMARY_KEY_ASC";
    BillingPaymentSourcesOrderBy["PrimaryKeyDesc"] = "PRIMARY_KEY_DESC";
})(BillingPaymentSourcesOrderBy || (BillingPaymentSourcesOrderBy = {}));
/** Methods to use when ordering `BillingSubscription`. */
export var BillingSubscriptionsOrderBy;
(function (BillingSubscriptionsOrderBy) {
    BillingSubscriptionsOrderBy["Natural"] = "NATURAL";
    BillingSubscriptionsOrderBy["IdAsc"] = "ID_ASC";
    BillingSubscriptionsOrderBy["IdDesc"] = "ID_DESC";
    BillingSubscriptionsOrderBy["BillingCustomerIdAsc"] = "BILLING_CUSTOMER_ID_ASC";
    BillingSubscriptionsOrderBy["BillingCustomerIdDesc"] = "BILLING_CUSTOMER_ID_DESC";
    BillingSubscriptionsOrderBy["OrgIdAsc"] = "ORG_ID_ASC";
    BillingSubscriptionsOrderBy["OrgIdDesc"] = "ORG_ID_DESC";
    BillingSubscriptionsOrderBy["CurrencyCodeAsc"] = "CURRENCY_CODE_ASC";
    BillingSubscriptionsOrderBy["CurrencyCodeDesc"] = "CURRENCY_CODE_DESC";
    BillingSubscriptionsOrderBy["PlanIdAsc"] = "PLAN_ID_ASC";
    BillingSubscriptionsOrderBy["PlanIdDesc"] = "PLAN_ID_DESC";
    BillingSubscriptionsOrderBy["PlanQuantityAsc"] = "PLAN_QUANTITY_ASC";
    BillingSubscriptionsOrderBy["PlanQuantityDesc"] = "PLAN_QUANTITY_DESC";
    BillingSubscriptionsOrderBy["PlanUnitPriceAsc"] = "PLAN_UNIT_PRICE_ASC";
    BillingSubscriptionsOrderBy["PlanUnitPriceDesc"] = "PLAN_UNIT_PRICE_DESC";
    BillingSubscriptionsOrderBy["PlanAmountAsc"] = "PLAN_AMOUNT_ASC";
    BillingSubscriptionsOrderBy["PlanAmountDesc"] = "PLAN_AMOUNT_DESC";
    BillingSubscriptionsOrderBy["StatusAsc"] = "STATUS_ASC";
    BillingSubscriptionsOrderBy["StatusDesc"] = "STATUS_DESC";
    BillingSubscriptionsOrderBy["RemainingBillingCyclesAsc"] = "REMAINING_BILLING_CYCLES_ASC";
    BillingSubscriptionsOrderBy["RemainingBillingCyclesDesc"] = "REMAINING_BILLING_CYCLES_DESC";
    BillingSubscriptionsOrderBy["PoNumberAsc"] = "PO_NUMBER_ASC";
    BillingSubscriptionsOrderBy["PoNumberDesc"] = "PO_NUMBER_DESC";
    BillingSubscriptionsOrderBy["PaymentSourceIdAsc"] = "PAYMENT_SOURCE_ID_ASC";
    BillingSubscriptionsOrderBy["PaymentSourceIdDesc"] = "PAYMENT_SOURCE_ID_DESC";
    BillingSubscriptionsOrderBy["AutoCollectionAsc"] = "AUTO_COLLECTION_ASC";
    BillingSubscriptionsOrderBy["AutoCollectionDesc"] = "AUTO_COLLECTION_DESC";
    BillingSubscriptionsOrderBy["DueInvoicesCountAsc"] = "DUE_INVOICES_COUNT_ASC";
    BillingSubscriptionsOrderBy["DueInvoicesCountDesc"] = "DUE_INVOICES_COUNT_DESC";
    BillingSubscriptionsOrderBy["TotalDuesAsc"] = "TOTAL_DUES_ASC";
    BillingSubscriptionsOrderBy["TotalDuesDesc"] = "TOTAL_DUES_DESC";
    BillingSubscriptionsOrderBy["BaseCurrencyCodeAsc"] = "BASE_CURRENCY_CODE_ASC";
    BillingSubscriptionsOrderBy["BaseCurrencyCodeDesc"] = "BASE_CURRENCY_CODE_DESC";
    BillingSubscriptionsOrderBy["HasScheduledChangesAsc"] = "HAS_SCHEDULED_CHANGES_ASC";
    BillingSubscriptionsOrderBy["HasScheduledChangesDesc"] = "HAS_SCHEDULED_CHANGES_DESC";
    BillingSubscriptionsOrderBy["CfPrimaryAsc"] = "CF_PRIMARY_ASC";
    BillingSubscriptionsOrderBy["CfPrimaryDesc"] = "CF_PRIMARY_DESC";
    BillingSubscriptionsOrderBy["DueSinceAsc"] = "DUE_SINCE_ASC";
    BillingSubscriptionsOrderBy["DueSinceDesc"] = "DUE_SINCE_DESC";
    BillingSubscriptionsOrderBy["TrialStartAsc"] = "TRIAL_START_ASC";
    BillingSubscriptionsOrderBy["TrialStartDesc"] = "TRIAL_START_DESC";
    BillingSubscriptionsOrderBy["TrialEndAsc"] = "TRIAL_END_ASC";
    BillingSubscriptionsOrderBy["TrialEndDesc"] = "TRIAL_END_DESC";
    BillingSubscriptionsOrderBy["CurrentTermStartAsc"] = "CURRENT_TERM_START_ASC";
    BillingSubscriptionsOrderBy["CurrentTermStartDesc"] = "CURRENT_TERM_START_DESC";
    BillingSubscriptionsOrderBy["CurrentTermEndAsc"] = "CURRENT_TERM_END_ASC";
    BillingSubscriptionsOrderBy["CurrentTermEndDesc"] = "CURRENT_TERM_END_DESC";
    BillingSubscriptionsOrderBy["PauseDateAsc"] = "PAUSE_DATE_ASC";
    BillingSubscriptionsOrderBy["PauseDateDesc"] = "PAUSE_DATE_DESC";
    BillingSubscriptionsOrderBy["ResumeDateAsc"] = "RESUME_DATE_ASC";
    BillingSubscriptionsOrderBy["ResumeDateDesc"] = "RESUME_DATE_DESC";
    BillingSubscriptionsOrderBy["StartDateAsc"] = "START_DATE_ASC";
    BillingSubscriptionsOrderBy["StartDateDesc"] = "START_DATE_DESC";
    BillingSubscriptionsOrderBy["StartedAtAsc"] = "STARTED_AT_ASC";
    BillingSubscriptionsOrderBy["StartedAtDesc"] = "STARTED_AT_DESC";
    BillingSubscriptionsOrderBy["ActivatedAtAsc"] = "ACTIVATED_AT_ASC";
    BillingSubscriptionsOrderBy["ActivatedAtDesc"] = "ACTIVATED_AT_DESC";
    BillingSubscriptionsOrderBy["CancelledAtAsc"] = "CANCELLED_AT_ASC";
    BillingSubscriptionsOrderBy["CancelledAtDesc"] = "CANCELLED_AT_DESC";
    BillingSubscriptionsOrderBy["NextBillingAtAsc"] = "NEXT_BILLING_AT_ASC";
    BillingSubscriptionsOrderBy["NextBillingAtDesc"] = "NEXT_BILLING_AT_DESC";
    BillingSubscriptionsOrderBy["CreatedAtAsc"] = "CREATED_AT_ASC";
    BillingSubscriptionsOrderBy["CreatedAtDesc"] = "CREATED_AT_DESC";
    BillingSubscriptionsOrderBy["PrimaryKeyAsc"] = "PRIMARY_KEY_ASC";
    BillingSubscriptionsOrderBy["PrimaryKeyDesc"] = "PRIMARY_KEY_DESC";
})(BillingSubscriptionsOrderBy || (BillingSubscriptionsOrderBy = {}));
/** Methods to use when ordering `BillingSubscriptionFuture`. */
export var BillingSubscriptionFuturesOrderBy;
(function (BillingSubscriptionFuturesOrderBy) {
    BillingSubscriptionFuturesOrderBy["Natural"] = "NATURAL";
    BillingSubscriptionFuturesOrderBy["IdAsc"] = "ID_ASC";
    BillingSubscriptionFuturesOrderBy["IdDesc"] = "ID_DESC";
    BillingSubscriptionFuturesOrderBy["BillingCustomerIdAsc"] = "BILLING_CUSTOMER_ID_ASC";
    BillingSubscriptionFuturesOrderBy["BillingCustomerIdDesc"] = "BILLING_CUSTOMER_ID_DESC";
    BillingSubscriptionFuturesOrderBy["OrgIdAsc"] = "ORG_ID_ASC";
    BillingSubscriptionFuturesOrderBy["OrgIdDesc"] = "ORG_ID_DESC";
    BillingSubscriptionFuturesOrderBy["CurrencyCodeAsc"] = "CURRENCY_CODE_ASC";
    BillingSubscriptionFuturesOrderBy["CurrencyCodeDesc"] = "CURRENCY_CODE_DESC";
    BillingSubscriptionFuturesOrderBy["PlanIdAsc"] = "PLAN_ID_ASC";
    BillingSubscriptionFuturesOrderBy["PlanIdDesc"] = "PLAN_ID_DESC";
    BillingSubscriptionFuturesOrderBy["PlanQuantityAsc"] = "PLAN_QUANTITY_ASC";
    BillingSubscriptionFuturesOrderBy["PlanQuantityDesc"] = "PLAN_QUANTITY_DESC";
    BillingSubscriptionFuturesOrderBy["PlanUnitPriceAsc"] = "PLAN_UNIT_PRICE_ASC";
    BillingSubscriptionFuturesOrderBy["PlanUnitPriceDesc"] = "PLAN_UNIT_PRICE_DESC";
    BillingSubscriptionFuturesOrderBy["PlanAmountAsc"] = "PLAN_AMOUNT_ASC";
    BillingSubscriptionFuturesOrderBy["PlanAmountDesc"] = "PLAN_AMOUNT_DESC";
    BillingSubscriptionFuturesOrderBy["StatusAsc"] = "STATUS_ASC";
    BillingSubscriptionFuturesOrderBy["StatusDesc"] = "STATUS_DESC";
    BillingSubscriptionFuturesOrderBy["RemainingBillingCyclesAsc"] = "REMAINING_BILLING_CYCLES_ASC";
    BillingSubscriptionFuturesOrderBy["RemainingBillingCyclesDesc"] = "REMAINING_BILLING_CYCLES_DESC";
    BillingSubscriptionFuturesOrderBy["PoNumberAsc"] = "PO_NUMBER_ASC";
    BillingSubscriptionFuturesOrderBy["PoNumberDesc"] = "PO_NUMBER_DESC";
    BillingSubscriptionFuturesOrderBy["PaymentSourceIdAsc"] = "PAYMENT_SOURCE_ID_ASC";
    BillingSubscriptionFuturesOrderBy["PaymentSourceIdDesc"] = "PAYMENT_SOURCE_ID_DESC";
    BillingSubscriptionFuturesOrderBy["AutoCollectionAsc"] = "AUTO_COLLECTION_ASC";
    BillingSubscriptionFuturesOrderBy["AutoCollectionDesc"] = "AUTO_COLLECTION_DESC";
    BillingSubscriptionFuturesOrderBy["DueInvoicesCountAsc"] = "DUE_INVOICES_COUNT_ASC";
    BillingSubscriptionFuturesOrderBy["DueInvoicesCountDesc"] = "DUE_INVOICES_COUNT_DESC";
    BillingSubscriptionFuturesOrderBy["TotalDuesAsc"] = "TOTAL_DUES_ASC";
    BillingSubscriptionFuturesOrderBy["TotalDuesDesc"] = "TOTAL_DUES_DESC";
    BillingSubscriptionFuturesOrderBy["BaseCurrencyCodeAsc"] = "BASE_CURRENCY_CODE_ASC";
    BillingSubscriptionFuturesOrderBy["BaseCurrencyCodeDesc"] = "BASE_CURRENCY_CODE_DESC";
    BillingSubscriptionFuturesOrderBy["HasScheduledChangesAsc"] = "HAS_SCHEDULED_CHANGES_ASC";
    BillingSubscriptionFuturesOrderBy["HasScheduledChangesDesc"] = "HAS_SCHEDULED_CHANGES_DESC";
    BillingSubscriptionFuturesOrderBy["CfPrimaryAsc"] = "CF_PRIMARY_ASC";
    BillingSubscriptionFuturesOrderBy["CfPrimaryDesc"] = "CF_PRIMARY_DESC";
    BillingSubscriptionFuturesOrderBy["DueSinceAsc"] = "DUE_SINCE_ASC";
    BillingSubscriptionFuturesOrderBy["DueSinceDesc"] = "DUE_SINCE_DESC";
    BillingSubscriptionFuturesOrderBy["TrialStartAsc"] = "TRIAL_START_ASC";
    BillingSubscriptionFuturesOrderBy["TrialStartDesc"] = "TRIAL_START_DESC";
    BillingSubscriptionFuturesOrderBy["TrialEndAsc"] = "TRIAL_END_ASC";
    BillingSubscriptionFuturesOrderBy["TrialEndDesc"] = "TRIAL_END_DESC";
    BillingSubscriptionFuturesOrderBy["CurrentTermStartAsc"] = "CURRENT_TERM_START_ASC";
    BillingSubscriptionFuturesOrderBy["CurrentTermStartDesc"] = "CURRENT_TERM_START_DESC";
    BillingSubscriptionFuturesOrderBy["CurrentTermEndAsc"] = "CURRENT_TERM_END_ASC";
    BillingSubscriptionFuturesOrderBy["CurrentTermEndDesc"] = "CURRENT_TERM_END_DESC";
    BillingSubscriptionFuturesOrderBy["PauseDateAsc"] = "PAUSE_DATE_ASC";
    BillingSubscriptionFuturesOrderBy["PauseDateDesc"] = "PAUSE_DATE_DESC";
    BillingSubscriptionFuturesOrderBy["ResumeDateAsc"] = "RESUME_DATE_ASC";
    BillingSubscriptionFuturesOrderBy["ResumeDateDesc"] = "RESUME_DATE_DESC";
    BillingSubscriptionFuturesOrderBy["StartDateAsc"] = "START_DATE_ASC";
    BillingSubscriptionFuturesOrderBy["StartDateDesc"] = "START_DATE_DESC";
    BillingSubscriptionFuturesOrderBy["StartedAtAsc"] = "STARTED_AT_ASC";
    BillingSubscriptionFuturesOrderBy["StartedAtDesc"] = "STARTED_AT_DESC";
    BillingSubscriptionFuturesOrderBy["ActivatedAtAsc"] = "ACTIVATED_AT_ASC";
    BillingSubscriptionFuturesOrderBy["ActivatedAtDesc"] = "ACTIVATED_AT_DESC";
    BillingSubscriptionFuturesOrderBy["CancelledAtAsc"] = "CANCELLED_AT_ASC";
    BillingSubscriptionFuturesOrderBy["CancelledAtDesc"] = "CANCELLED_AT_DESC";
    BillingSubscriptionFuturesOrderBy["NextBillingAtAsc"] = "NEXT_BILLING_AT_ASC";
    BillingSubscriptionFuturesOrderBy["NextBillingAtDesc"] = "NEXT_BILLING_AT_DESC";
    BillingSubscriptionFuturesOrderBy["CreatedAtAsc"] = "CREATED_AT_ASC";
    BillingSubscriptionFuturesOrderBy["CreatedAtDesc"] = "CREATED_AT_DESC";
    BillingSubscriptionFuturesOrderBy["PrimaryKeyAsc"] = "PRIMARY_KEY_ASC";
    BillingSubscriptionFuturesOrderBy["PrimaryKeyDesc"] = "PRIMARY_KEY_DESC";
})(BillingSubscriptionFuturesOrderBy || (BillingSubscriptionFuturesOrderBy = {}));
/** Methods to use when ordering `BillingRenewalFuture`. */
export var BillingRenewalFuturesOrderBy;
(function (BillingRenewalFuturesOrderBy) {
    BillingRenewalFuturesOrderBy["Natural"] = "NATURAL";
    BillingRenewalFuturesOrderBy["BillingSubscriptionIdAsc"] = "BILLING_SUBSCRIPTION_ID_ASC";
    BillingRenewalFuturesOrderBy["BillingSubscriptionIdDesc"] = "BILLING_SUBSCRIPTION_ID_DESC";
    BillingRenewalFuturesOrderBy["BillingCustomerIdAsc"] = "BILLING_CUSTOMER_ID_ASC";
    BillingRenewalFuturesOrderBy["BillingCustomerIdDesc"] = "BILLING_CUSTOMER_ID_DESC";
    BillingRenewalFuturesOrderBy["OrgIdAsc"] = "ORG_ID_ASC";
    BillingRenewalFuturesOrderBy["OrgIdDesc"] = "ORG_ID_DESC";
    BillingRenewalFuturesOrderBy["SubTotalAsc"] = "SUB_TOTAL_ASC";
    BillingRenewalFuturesOrderBy["SubTotalDesc"] = "SUB_TOTAL_DESC";
    BillingRenewalFuturesOrderBy["TotalAsc"] = "TOTAL_ASC";
    BillingRenewalFuturesOrderBy["TotalDesc"] = "TOTAL_DESC";
    BillingRenewalFuturesOrderBy["CreditsAppliedAsc"] = "CREDITS_APPLIED_ASC";
    BillingRenewalFuturesOrderBy["CreditsAppliedDesc"] = "CREDITS_APPLIED_DESC";
    BillingRenewalFuturesOrderBy["AmountPaidAsc"] = "AMOUNT_PAID_ASC";
    BillingRenewalFuturesOrderBy["AmountPaidDesc"] = "AMOUNT_PAID_DESC";
    BillingRenewalFuturesOrderBy["AmountDueAsc"] = "AMOUNT_DUE_ASC";
    BillingRenewalFuturesOrderBy["AmountDueDesc"] = "AMOUNT_DUE_DESC";
    BillingRenewalFuturesOrderBy["TaxDescriptionAsc"] = "TAX_DESCRIPTION_ASC";
    BillingRenewalFuturesOrderBy["TaxDescriptionDesc"] = "TAX_DESCRIPTION_DESC";
    BillingRenewalFuturesOrderBy["TaxRateAsc"] = "TAX_RATE_ASC";
    BillingRenewalFuturesOrderBy["TaxRateDesc"] = "TAX_RATE_DESC";
    BillingRenewalFuturesOrderBy["TaxAmountAsc"] = "TAX_AMOUNT_ASC";
    BillingRenewalFuturesOrderBy["TaxAmountDesc"] = "TAX_AMOUNT_DESC";
    BillingRenewalFuturesOrderBy["LineItemsAsc"] = "LINE_ITEMS_ASC";
    BillingRenewalFuturesOrderBy["LineItemsDesc"] = "LINE_ITEMS_DESC";
    BillingRenewalFuturesOrderBy["DiscountsAsc"] = "DISCOUNTS_ASC";
    BillingRenewalFuturesOrderBy["DiscountsDesc"] = "DISCOUNTS_DESC";
    BillingRenewalFuturesOrderBy["TaxesAsc"] = "TAXES_ASC";
    BillingRenewalFuturesOrderBy["TaxesDesc"] = "TAXES_DESC";
    BillingRenewalFuturesOrderBy["LineItemTaxesAsc"] = "LINE_ITEM_TAXES_ASC";
    BillingRenewalFuturesOrderBy["LineItemTaxesDesc"] = "LINE_ITEM_TAXES_DESC";
    BillingRenewalFuturesOrderBy["LineItemDiscountsAsc"] = "LINE_ITEM_DISCOUNTS_ASC";
    BillingRenewalFuturesOrderBy["LineItemDiscountsDesc"] = "LINE_ITEM_DISCOUNTS_DESC";
    BillingRenewalFuturesOrderBy["NextBillingAtAsc"] = "NEXT_BILLING_AT_ASC";
    BillingRenewalFuturesOrderBy["NextBillingAtDesc"] = "NEXT_BILLING_AT_DESC";
    BillingRenewalFuturesOrderBy["CreatedAtAsc"] = "CREATED_AT_ASC";
    BillingRenewalFuturesOrderBy["CreatedAtDesc"] = "CREATED_AT_DESC";
    BillingRenewalFuturesOrderBy["PrimaryKeyAsc"] = "PRIMARY_KEY_ASC";
    BillingRenewalFuturesOrderBy["PrimaryKeyDesc"] = "PRIMARY_KEY_DESC";
})(BillingRenewalFuturesOrderBy || (BillingRenewalFuturesOrderBy = {}));
/** Methods to use when ordering `BillingRenewal`. */
export var BillingRenewalsOrderBy;
(function (BillingRenewalsOrderBy) {
    BillingRenewalsOrderBy["Natural"] = "NATURAL";
    BillingRenewalsOrderBy["BillingSubscriptionIdAsc"] = "BILLING_SUBSCRIPTION_ID_ASC";
    BillingRenewalsOrderBy["BillingSubscriptionIdDesc"] = "BILLING_SUBSCRIPTION_ID_DESC";
    BillingRenewalsOrderBy["BillingCustomerIdAsc"] = "BILLING_CUSTOMER_ID_ASC";
    BillingRenewalsOrderBy["BillingCustomerIdDesc"] = "BILLING_CUSTOMER_ID_DESC";
    BillingRenewalsOrderBy["OrgIdAsc"] = "ORG_ID_ASC";
    BillingRenewalsOrderBy["OrgIdDesc"] = "ORG_ID_DESC";
    BillingRenewalsOrderBy["SubTotalAsc"] = "SUB_TOTAL_ASC";
    BillingRenewalsOrderBy["SubTotalDesc"] = "SUB_TOTAL_DESC";
    BillingRenewalsOrderBy["TotalAsc"] = "TOTAL_ASC";
    BillingRenewalsOrderBy["TotalDesc"] = "TOTAL_DESC";
    BillingRenewalsOrderBy["CreditsAppliedAsc"] = "CREDITS_APPLIED_ASC";
    BillingRenewalsOrderBy["CreditsAppliedDesc"] = "CREDITS_APPLIED_DESC";
    BillingRenewalsOrderBy["AmountPaidAsc"] = "AMOUNT_PAID_ASC";
    BillingRenewalsOrderBy["AmountPaidDesc"] = "AMOUNT_PAID_DESC";
    BillingRenewalsOrderBy["AmountDueAsc"] = "AMOUNT_DUE_ASC";
    BillingRenewalsOrderBy["AmountDueDesc"] = "AMOUNT_DUE_DESC";
    BillingRenewalsOrderBy["TaxDescriptionAsc"] = "TAX_DESCRIPTION_ASC";
    BillingRenewalsOrderBy["TaxDescriptionDesc"] = "TAX_DESCRIPTION_DESC";
    BillingRenewalsOrderBy["TaxRateAsc"] = "TAX_RATE_ASC";
    BillingRenewalsOrderBy["TaxRateDesc"] = "TAX_RATE_DESC";
    BillingRenewalsOrderBy["TaxAmountAsc"] = "TAX_AMOUNT_ASC";
    BillingRenewalsOrderBy["TaxAmountDesc"] = "TAX_AMOUNT_DESC";
    BillingRenewalsOrderBy["LineItemsAsc"] = "LINE_ITEMS_ASC";
    BillingRenewalsOrderBy["LineItemsDesc"] = "LINE_ITEMS_DESC";
    BillingRenewalsOrderBy["DiscountsAsc"] = "DISCOUNTS_ASC";
    BillingRenewalsOrderBy["DiscountsDesc"] = "DISCOUNTS_DESC";
    BillingRenewalsOrderBy["TaxesAsc"] = "TAXES_ASC";
    BillingRenewalsOrderBy["TaxesDesc"] = "TAXES_DESC";
    BillingRenewalsOrderBy["LineItemTaxesAsc"] = "LINE_ITEM_TAXES_ASC";
    BillingRenewalsOrderBy["LineItemTaxesDesc"] = "LINE_ITEM_TAXES_DESC";
    BillingRenewalsOrderBy["LineItemDiscountsAsc"] = "LINE_ITEM_DISCOUNTS_ASC";
    BillingRenewalsOrderBy["LineItemDiscountsDesc"] = "LINE_ITEM_DISCOUNTS_DESC";
    BillingRenewalsOrderBy["NextBillingAtAsc"] = "NEXT_BILLING_AT_ASC";
    BillingRenewalsOrderBy["NextBillingAtDesc"] = "NEXT_BILLING_AT_DESC";
    BillingRenewalsOrderBy["CreatedAtAsc"] = "CREATED_AT_ASC";
    BillingRenewalsOrderBy["CreatedAtDesc"] = "CREATED_AT_DESC";
    BillingRenewalsOrderBy["PrimaryKeyAsc"] = "PRIMARY_KEY_ASC";
    BillingRenewalsOrderBy["PrimaryKeyDesc"] = "PRIMARY_KEY_DESC";
})(BillingRenewalsOrderBy || (BillingRenewalsOrderBy = {}));
/** Methods to use when ordering `BillingInvoice`. */
export var BillingInvoicesOrderBy;
(function (BillingInvoicesOrderBy) {
    BillingInvoicesOrderBy["Natural"] = "NATURAL";
    BillingInvoicesOrderBy["IdAsc"] = "ID_ASC";
    BillingInvoicesOrderBy["IdDesc"] = "ID_DESC";
    BillingInvoicesOrderBy["BillingCustomerIdAsc"] = "BILLING_CUSTOMER_ID_ASC";
    BillingInvoicesOrderBy["BillingCustomerIdDesc"] = "BILLING_CUSTOMER_ID_DESC";
    BillingInvoicesOrderBy["StatusAsc"] = "STATUS_ASC";
    BillingInvoicesOrderBy["StatusDesc"] = "STATUS_DESC";
    BillingInvoicesOrderBy["TotalAsc"] = "TOTAL_ASC";
    BillingInvoicesOrderBy["TotalDesc"] = "TOTAL_DESC";
    BillingInvoicesOrderBy["DueDateAsc"] = "DUE_DATE_ASC";
    BillingInvoicesOrderBy["DueDateDesc"] = "DUE_DATE_DESC";
    BillingInvoicesOrderBy["PaidAtAsc"] = "PAID_AT_ASC";
    BillingInvoicesOrderBy["PaidAtDesc"] = "PAID_AT_DESC";
    BillingInvoicesOrderBy["UpdatedAtAsc"] = "UPDATED_AT_ASC";
    BillingInvoicesOrderBy["UpdatedAtDesc"] = "UPDATED_AT_DESC";
    BillingInvoicesOrderBy["AmountDueAsc"] = "AMOUNT_DUE_ASC";
    BillingInvoicesOrderBy["AmountDueDesc"] = "AMOUNT_DUE_DESC";
    BillingInvoicesOrderBy["AmountPaidAsc"] = "AMOUNT_PAID_ASC";
    BillingInvoicesOrderBy["AmountPaidDesc"] = "AMOUNT_PAID_DESC";
    BillingInvoicesOrderBy["AmountToCollectAsc"] = "AMOUNT_TO_COLLECT_ASC";
    BillingInvoicesOrderBy["AmountToCollectDesc"] = "AMOUNT_TO_COLLECT_DESC";
    BillingInvoicesOrderBy["BaseCurrencyCodeAsc"] = "BASE_CURRENCY_CODE_ASC";
    BillingInvoicesOrderBy["BaseCurrencyCodeDesc"] = "BASE_CURRENCY_CODE_DESC";
    BillingInvoicesOrderBy["TaxAsc"] = "TAX_ASC";
    BillingInvoicesOrderBy["TaxDesc"] = "TAX_DESC";
    BillingInvoicesOrderBy["LastSyncAtAsc"] = "LAST_SYNC_AT_ASC";
    BillingInvoicesOrderBy["LastSyncAtDesc"] = "LAST_SYNC_AT_DESC";
    BillingInvoicesOrderBy["BillingSubscriptionIdAsc"] = "BILLING_SUBSCRIPTION_ID_ASC";
    BillingInvoicesOrderBy["BillingSubscriptionIdDesc"] = "BILLING_SUBSCRIPTION_ID_DESC";
    BillingInvoicesOrderBy["DateAsc"] = "DATE_ASC";
    BillingInvoicesOrderBy["DateDesc"] = "DATE_DESC";
    BillingInvoicesOrderBy["PrimaryKeyAsc"] = "PRIMARY_KEY_ASC";
    BillingInvoicesOrderBy["PrimaryKeyDesc"] = "PRIMARY_KEY_DESC";
})(BillingInvoicesOrderBy || (BillingInvoicesOrderBy = {}));
/** Methods to use when ordering `BillingHierarchy`. */
export var BillingHierarchiesOrderBy;
(function (BillingHierarchiesOrderBy) {
    BillingHierarchiesOrderBy["Natural"] = "NATURAL";
    BillingHierarchiesOrderBy["OrgIdAsc"] = "ORG_ID_ASC";
    BillingHierarchiesOrderBy["OrgIdDesc"] = "ORG_ID_DESC";
    BillingHierarchiesOrderBy["ParentIdAsc"] = "PARENT_ID_ASC";
    BillingHierarchiesOrderBy["ParentIdDesc"] = "PARENT_ID_DESC";
    BillingHierarchiesOrderBy["ChildIdAsc"] = "CHILD_ID_ASC";
    BillingHierarchiesOrderBy["ChildIdDesc"] = "CHILD_ID_DESC";
    BillingHierarchiesOrderBy["InvoiceeIdAsc"] = "INVOICEE_ID_ASC";
    BillingHierarchiesOrderBy["InvoiceeIdDesc"] = "INVOICEE_ID_DESC";
    BillingHierarchiesOrderBy["PayeeIdAsc"] = "PAYEE_ID_ASC";
    BillingHierarchiesOrderBy["PayeeIdDesc"] = "PAYEE_ID_DESC";
    BillingHierarchiesOrderBy["PrimaryKeyAsc"] = "PRIMARY_KEY_ASC";
    BillingHierarchiesOrderBy["PrimaryKeyDesc"] = "PRIMARY_KEY_DESC";
})(BillingHierarchiesOrderBy || (BillingHierarchiesOrderBy = {}));
/** Methods to use when ordering `ScreenGroup`. */
export var ScreenGroupsOrderBy;
(function (ScreenGroupsOrderBy) {
    ScreenGroupsOrderBy["Natural"] = "NATURAL";
    ScreenGroupsOrderBy["IdAsc"] = "ID_ASC";
    ScreenGroupsOrderBy["IdDesc"] = "ID_DESC";
    ScreenGroupsOrderBy["OrgIdAsc"] = "ORG_ID_ASC";
    ScreenGroupsOrderBy["OrgIdDesc"] = "ORG_ID_DESC";
    ScreenGroupsOrderBy["SpaceIdAsc"] = "SPACE_ID_ASC";
    ScreenGroupsOrderBy["SpaceIdDesc"] = "SPACE_ID_DESC";
    ScreenGroupsOrderBy["NameAsc"] = "NAME_ASC";
    ScreenGroupsOrderBy["NameDesc"] = "NAME_DESC";
    ScreenGroupsOrderBy["IsPrimaryAsc"] = "IS_PRIMARY_ASC";
    ScreenGroupsOrderBy["IsPrimaryDesc"] = "IS_PRIMARY_DESC";
    ScreenGroupsOrderBy["EnvAsc"] = "ENV_ASC";
    ScreenGroupsOrderBy["EnvDesc"] = "ENV_DESC";
    ScreenGroupsOrderBy["CreatedAtAsc"] = "CREATED_AT_ASC";
    ScreenGroupsOrderBy["CreatedAtDesc"] = "CREATED_AT_DESC";
    ScreenGroupsOrderBy["UpdatedAtAsc"] = "UPDATED_AT_ASC";
    ScreenGroupsOrderBy["UpdatedAtDesc"] = "UPDATED_AT_DESC";
    ScreenGroupsOrderBy["CreatedByAsc"] = "CREATED_BY_ASC";
    ScreenGroupsOrderBy["CreatedByDesc"] = "CREATED_BY_DESC";
    ScreenGroupsOrderBy["UpdatedByAsc"] = "UPDATED_BY_ASC";
    ScreenGroupsOrderBy["UpdatedByDesc"] = "UPDATED_BY_DESC";
    ScreenGroupsOrderBy["PrimaryKeyAsc"] = "PRIMARY_KEY_ASC";
    ScreenGroupsOrderBy["PrimaryKeyDesc"] = "PRIMARY_KEY_DESC";
})(ScreenGroupsOrderBy || (ScreenGroupsOrderBy = {}));
/** Methods to use when ordering `Screen`. */
export var ScreensOrderBy;
(function (ScreensOrderBy) {
    ScreensOrderBy["Natural"] = "NATURAL";
    ScreensOrderBy["IdAsc"] = "ID_ASC";
    ScreensOrderBy["IdDesc"] = "ID_DESC";
    ScreensOrderBy["OrgIdAsc"] = "ORG_ID_ASC";
    ScreensOrderBy["OrgIdDesc"] = "ORG_ID_DESC";
    ScreensOrderBy["SpaceIdAsc"] = "SPACE_ID_ASC";
    ScreensOrderBy["SpaceIdDesc"] = "SPACE_ID_DESC";
    ScreensOrderBy["ScreenGroupIdAsc"] = "SCREEN_GROUP_ID_ASC";
    ScreensOrderBy["ScreenGroupIdDesc"] = "SCREEN_GROUP_ID_DESC";
    ScreensOrderBy["CastIdAsc"] = "CAST_ID_ASC";
    ScreensOrderBy["CastIdDesc"] = "CAST_ID_DESC";
    ScreensOrderBy["NameAsc"] = "NAME_ASC";
    ScreensOrderBy["NameDesc"] = "NAME_DESC";
    ScreensOrderBy["ContentAsc"] = "CONTENT_ASC";
    ScreensOrderBy["ContentDesc"] = "CONTENT_DESC";
    ScreensOrderBy["StatusAsc"] = "STATUS_ASC";
    ScreensOrderBy["StatusDesc"] = "STATUS_DESC";
    ScreensOrderBy["PreviewAsc"] = "PREVIEW_ASC";
    ScreensOrderBy["PreviewDesc"] = "PREVIEW_DESC";
    ScreensOrderBy["DeviceAsc"] = "DEVICE_ASC";
    ScreensOrderBy["DeviceDesc"] = "DEVICE_DESC";
    ScreensOrderBy["RotationAsc"] = "ROTATION_ASC";
    ScreensOrderBy["RotationDesc"] = "ROTATION_DESC";
    ScreensOrderBy["TagsAsc"] = "TAGS_ASC";
    ScreensOrderBy["TagsDesc"] = "TAGS_DESC";
    ScreensOrderBy["EnvAsc"] = "ENV_ASC";
    ScreensOrderBy["EnvDesc"] = "ENV_DESC";
    ScreensOrderBy["NotesAsc"] = "NOTES_ASC";
    ScreensOrderBy["NotesDesc"] = "NOTES_DESC";
    ScreensOrderBy["CreatedAtAsc"] = "CREATED_AT_ASC";
    ScreensOrderBy["CreatedAtDesc"] = "CREATED_AT_DESC";
    ScreensOrderBy["UpdatedAtAsc"] = "UPDATED_AT_ASC";
    ScreensOrderBy["UpdatedAtDesc"] = "UPDATED_AT_DESC";
    ScreensOrderBy["CreatedByAsc"] = "CREATED_BY_ASC";
    ScreensOrderBy["CreatedByDesc"] = "CREATED_BY_DESC";
    ScreensOrderBy["UpdatedByAsc"] = "UPDATED_BY_ASC";
    ScreensOrderBy["UpdatedByDesc"] = "UPDATED_BY_DESC";
    ScreensOrderBy["PreferencesAsc"] = "PREFERENCES_ASC";
    ScreensOrderBy["PreferencesDesc"] = "PREFERENCES_DESC";
    ScreensOrderBy["PrimaryKeyAsc"] = "PRIMARY_KEY_ASC";
    ScreensOrderBy["PrimaryKeyDesc"] = "PRIMARY_KEY_DESC";
})(ScreensOrderBy || (ScreensOrderBy = {}));
export var ScreenStatus;
(function (ScreenStatus) {
    ScreenStatus["Live"] = "LIVE";
    ScreenStatus["Pending"] = "PENDING";
    ScreenStatus["Paused"] = "PAUSED";
    ScreenStatus["Suspended"] = "SUSPENDED";
})(ScreenStatus || (ScreenStatus = {}));
export var ScreenRotation;
(function (ScreenRotation) {
    ScreenRotation["Rotation_0"] = "ROTATION_0";
    ScreenRotation["Rotation_90"] = "ROTATION_90";
    ScreenRotation["Rotation_180"] = "ROTATION_180";
    ScreenRotation["Rotation_270"] = "ROTATION_270";
})(ScreenRotation || (ScreenRotation = {}));
/** Methods to use when ordering `Association`. */
export var AssociationsOrderBy;
(function (AssociationsOrderBy) {
    AssociationsOrderBy["Natural"] = "NATURAL";
    AssociationsOrderBy["OrgIdAsc"] = "ORG_ID_ASC";
    AssociationsOrderBy["OrgIdDesc"] = "ORG_ID_DESC";
    AssociationsOrderBy["FromCastAsc"] = "FROM_CAST_ASC";
    AssociationsOrderBy["FromCastDesc"] = "FROM_CAST_DESC";
    AssociationsOrderBy["FromScreenAsc"] = "FROM_SCREEN_ASC";
    AssociationsOrderBy["FromScreenDesc"] = "FROM_SCREEN_DESC";
    AssociationsOrderBy["FromChannelAsc"] = "FROM_CHANNEL_ASC";
    AssociationsOrderBy["FromChannelDesc"] = "FROM_CHANNEL_DESC";
    AssociationsOrderBy["FromPlaylistAsc"] = "FROM_PLAYLIST_ASC";
    AssociationsOrderBy["FromPlaylistDesc"] = "FROM_PLAYLIST_DESC";
    AssociationsOrderBy["FromAppInstanceAsc"] = "FROM_APP_INSTANCE_ASC";
    AssociationsOrderBy["FromAppInstanceDesc"] = "FROM_APP_INSTANCE_DESC";
    AssociationsOrderBy["ToCastAsc"] = "TO_CAST_ASC";
    AssociationsOrderBy["ToCastDesc"] = "TO_CAST_DESC";
    AssociationsOrderBy["ToFileAsc"] = "TO_FILE_ASC";
    AssociationsOrderBy["ToFileDesc"] = "TO_FILE_DESC";
    AssociationsOrderBy["ToAppInstanceAsc"] = "TO_APP_INSTANCE_ASC";
    AssociationsOrderBy["ToAppInstanceDesc"] = "TO_APP_INSTANCE_DESC";
    AssociationsOrderBy["ToPlaylistAsc"] = "TO_PLAYLIST_ASC";
    AssociationsOrderBy["ToPlaylistDesc"] = "TO_PLAYLIST_DESC";
    AssociationsOrderBy["ToChannelAsc"] = "TO_CHANNEL_ASC";
    AssociationsOrderBy["ToChannelDesc"] = "TO_CHANNEL_DESC";
    AssociationsOrderBy["ToLinkAsc"] = "TO_LINK_ASC";
    AssociationsOrderBy["ToLinkDesc"] = "TO_LINK_DESC";
    AssociationsOrderBy["ToCredentialAsc"] = "TO_CREDENTIAL_ASC";
    AssociationsOrderBy["ToCredentialDesc"] = "TO_CREDENTIAL_DESC";
    AssociationsOrderBy["ToSiteAsc"] = "TO_SITE_ASC";
    AssociationsOrderBy["ToSiteDesc"] = "TO_SITE_DESC";
})(AssociationsOrderBy || (AssociationsOrderBy = {}));
export var ChannelType;
(function (ChannelType) {
    ChannelType["Blank"] = "BLANK";
    ChannelType["Default"] = "DEFAULT";
    ChannelType["Splash"] = "SPLASH";
})(ChannelType || (ChannelType = {}));
export var JobStatus;
(function (JobStatus) {
    JobStatus["NotStarted"] = "NOT_STARTED";
    JobStatus["Completed"] = "COMPLETED";
    JobStatus["Pending"] = "PENDING";
    JobStatus["Failed"] = "FAILED";
})(JobStatus || (JobStatus = {}));
/** Methods to use when ordering `Folder`. */
export var FoldersOrderBy;
(function (FoldersOrderBy) {
    FoldersOrderBy["Natural"] = "NATURAL";
    FoldersOrderBy["IdAsc"] = "ID_ASC";
    FoldersOrderBy["IdDesc"] = "ID_DESC";
    FoldersOrderBy["OrgIdAsc"] = "ORG_ID_ASC";
    FoldersOrderBy["OrgIdDesc"] = "ORG_ID_DESC";
    FoldersOrderBy["SpaceIdAsc"] = "SPACE_ID_ASC";
    FoldersOrderBy["SpaceIdDesc"] = "SPACE_ID_DESC";
    FoldersOrderBy["LibraryIdAsc"] = "LIBRARY_ID_ASC";
    FoldersOrderBy["LibraryIdDesc"] = "LIBRARY_ID_DESC";
    FoldersOrderBy["ParentIdAsc"] = "PARENT_ID_ASC";
    FoldersOrderBy["ParentIdDesc"] = "PARENT_ID_DESC";
    FoldersOrderBy["NameAsc"] = "NAME_ASC";
    FoldersOrderBy["NameDesc"] = "NAME_DESC";
    FoldersOrderBy["TagsAsc"] = "TAGS_ASC";
    FoldersOrderBy["TagsDesc"] = "TAGS_DESC";
    FoldersOrderBy["CreatedAtAsc"] = "CREATED_AT_ASC";
    FoldersOrderBy["CreatedAtDesc"] = "CREATED_AT_DESC";
    FoldersOrderBy["UpdatedAtAsc"] = "UPDATED_AT_ASC";
    FoldersOrderBy["UpdatedAtDesc"] = "UPDATED_AT_DESC";
    FoldersOrderBy["CreatedByAsc"] = "CREATED_BY_ASC";
    FoldersOrderBy["CreatedByDesc"] = "CREATED_BY_DESC";
    FoldersOrderBy["UpdatedByAsc"] = "UPDATED_BY_ASC";
    FoldersOrderBy["UpdatedByDesc"] = "UPDATED_BY_DESC";
    FoldersOrderBy["PrimaryKeyAsc"] = "PRIMARY_KEY_ASC";
    FoldersOrderBy["PrimaryKeyDesc"] = "PRIMARY_KEY_DESC";
})(FoldersOrderBy || (FoldersOrderBy = {}));
/** Methods to use when ordering `Library`. */
export var LibrariesOrderBy;
(function (LibrariesOrderBy) {
    LibrariesOrderBy["Natural"] = "NATURAL";
    LibrariesOrderBy["IdAsc"] = "ID_ASC";
    LibrariesOrderBy["IdDesc"] = "ID_DESC";
    LibrariesOrderBy["OrgIdAsc"] = "ORG_ID_ASC";
    LibrariesOrderBy["OrgIdDesc"] = "ORG_ID_DESC";
    LibrariesOrderBy["SpaceIdAsc"] = "SPACE_ID_ASC";
    LibrariesOrderBy["SpaceIdDesc"] = "SPACE_ID_DESC";
    LibrariesOrderBy["NameAsc"] = "NAME_ASC";
    LibrariesOrderBy["NameDesc"] = "NAME_DESC";
    LibrariesOrderBy["IsPrimaryAsc"] = "IS_PRIMARY_ASC";
    LibrariesOrderBy["IsPrimaryDesc"] = "IS_PRIMARY_DESC";
    LibrariesOrderBy["IsReadonlyAsc"] = "IS_READONLY_ASC";
    LibrariesOrderBy["IsReadonlyDesc"] = "IS_READONLY_DESC";
    LibrariesOrderBy["CreatedAtAsc"] = "CREATED_AT_ASC";
    LibrariesOrderBy["CreatedAtDesc"] = "CREATED_AT_DESC";
    LibrariesOrderBy["UpdatedAtAsc"] = "UPDATED_AT_ASC";
    LibrariesOrderBy["UpdatedAtDesc"] = "UPDATED_AT_DESC";
    LibrariesOrderBy["CreatedByAsc"] = "CREATED_BY_ASC";
    LibrariesOrderBy["CreatedByDesc"] = "CREATED_BY_DESC";
    LibrariesOrderBy["UpdatedByAsc"] = "UPDATED_BY_ASC";
    LibrariesOrderBy["UpdatedByDesc"] = "UPDATED_BY_DESC";
    LibrariesOrderBy["RootFolderIdAsc"] = "ROOT_FOLDER_ID_ASC";
    LibrariesOrderBy["RootFolderIdDesc"] = "ROOT_FOLDER_ID_DESC";
    LibrariesOrderBy["PrimaryKeyAsc"] = "PRIMARY_KEY_ASC";
    LibrariesOrderBy["PrimaryKeyDesc"] = "PRIMARY_KEY_DESC";
})(LibrariesOrderBy || (LibrariesOrderBy = {}));
/** Methods to use when ordering `File`. */
export var FilesOrderBy;
(function (FilesOrderBy) {
    FilesOrderBy["Natural"] = "NATURAL";
    FilesOrderBy["IdAsc"] = "ID_ASC";
    FilesOrderBy["IdDesc"] = "ID_DESC";
    FilesOrderBy["OrgIdAsc"] = "ORG_ID_ASC";
    FilesOrderBy["OrgIdDesc"] = "ORG_ID_DESC";
    FilesOrderBy["SpaceIdAsc"] = "SPACE_ID_ASC";
    FilesOrderBy["SpaceIdDesc"] = "SPACE_ID_DESC";
    FilesOrderBy["FolderIdAsc"] = "FOLDER_ID_ASC";
    FilesOrderBy["FolderIdDesc"] = "FOLDER_ID_DESC";
    FilesOrderBy["NameAsc"] = "NAME_ASC";
    FilesOrderBy["NameDesc"] = "NAME_DESC";
    FilesOrderBy["MimetypeAsc"] = "MIMETYPE_ASC";
    FilesOrderBy["MimetypeDesc"] = "MIMETYPE_DESC";
    FilesOrderBy["SizeAsc"] = "SIZE_ASC";
    FilesOrderBy["SizeDesc"] = "SIZE_DESC";
    FilesOrderBy["SourceAsc"] = "SOURCE_ASC";
    FilesOrderBy["SourceDesc"] = "SOURCE_DESC";
    FilesOrderBy["FileProcessingStatusAsc"] = "FILE_PROCESSING_STATUS_ASC";
    FilesOrderBy["FileProcessingStatusDesc"] = "FILE_PROCESSING_STATUS_DESC";
    FilesOrderBy["MetadataAsc"] = "METADATA_ASC";
    FilesOrderBy["MetadataDesc"] = "METADATA_DESC";
    FilesOrderBy["TagsAsc"] = "TAGS_ASC";
    FilesOrderBy["TagsDesc"] = "TAGS_DESC";
    FilesOrderBy["AvailableAtAsc"] = "AVAILABLE_AT_ASC";
    FilesOrderBy["AvailableAtDesc"] = "AVAILABLE_AT_DESC";
    FilesOrderBy["ExpireAtAsc"] = "EXPIRE_AT_ASC";
    FilesOrderBy["ExpireAtDesc"] = "EXPIRE_AT_DESC";
    FilesOrderBy["CreatedAtAsc"] = "CREATED_AT_ASC";
    FilesOrderBy["CreatedAtDesc"] = "CREATED_AT_DESC";
    FilesOrderBy["UpdatedAtAsc"] = "UPDATED_AT_ASC";
    FilesOrderBy["UpdatedAtDesc"] = "UPDATED_AT_DESC";
    FilesOrderBy["CreatedByAsc"] = "CREATED_BY_ASC";
    FilesOrderBy["CreatedByDesc"] = "CREATED_BY_DESC";
    FilesOrderBy["UpdatedByAsc"] = "UPDATED_BY_ASC";
    FilesOrderBy["UpdatedByDesc"] = "UPDATED_BY_DESC";
    FilesOrderBy["CommonFileIdAsc"] = "COMMON_FILE_ID_ASC";
    FilesOrderBy["CommonFileIdDesc"] = "COMMON_FILE_ID_DESC";
    FilesOrderBy["PrimaryKeyAsc"] = "PRIMARY_KEY_ASC";
    FilesOrderBy["PrimaryKeyDesc"] = "PRIMARY_KEY_DESC";
})(FilesOrderBy || (FilesOrderBy = {}));
/** Methods to use when ordering `Favorite`. */
export var FavoritesOrderBy;
(function (FavoritesOrderBy) {
    FavoritesOrderBy["Natural"] = "NATURAL";
    FavoritesOrderBy["OrgIdAsc"] = "ORG_ID_ASC";
    FavoritesOrderBy["OrgIdDesc"] = "ORG_ID_DESC";
    FavoritesOrderBy["SpaceIdAsc"] = "SPACE_ID_ASC";
    FavoritesOrderBy["SpaceIdDesc"] = "SPACE_ID_DESC";
    FavoritesOrderBy["FileIdAsc"] = "FILE_ID_ASC";
    FavoritesOrderBy["FileIdDesc"] = "FILE_ID_DESC";
    FavoritesOrderBy["FolderIdAsc"] = "FOLDER_ID_ASC";
    FavoritesOrderBy["FolderIdDesc"] = "FOLDER_ID_DESC";
    FavoritesOrderBy["PlaylistIdAsc"] = "PLAYLIST_ID_ASC";
    FavoritesOrderBy["PlaylistIdDesc"] = "PLAYLIST_ID_DESC";
    FavoritesOrderBy["LinkIdAsc"] = "LINK_ID_ASC";
    FavoritesOrderBy["LinkIdDesc"] = "LINK_ID_DESC";
    FavoritesOrderBy["UserIdAsc"] = "USER_ID_ASC";
    FavoritesOrderBy["UserIdDesc"] = "USER_ID_DESC";
    FavoritesOrderBy["SiteIdAsc"] = "SITE_ID_ASC";
    FavoritesOrderBy["SiteIdDesc"] = "SITE_ID_DESC";
})(FavoritesOrderBy || (FavoritesOrderBy = {}));
/** Methods to use when ordering `Playlist`. */
export var PlaylistsOrderBy;
(function (PlaylistsOrderBy) {
    PlaylistsOrderBy["Natural"] = "NATURAL";
    PlaylistsOrderBy["IdAsc"] = "ID_ASC";
    PlaylistsOrderBy["IdDesc"] = "ID_DESC";
    PlaylistsOrderBy["OrgIdAsc"] = "ORG_ID_ASC";
    PlaylistsOrderBy["OrgIdDesc"] = "ORG_ID_DESC";
    PlaylistsOrderBy["SpaceIdAsc"] = "SPACE_ID_ASC";
    PlaylistsOrderBy["SpaceIdDesc"] = "SPACE_ID_DESC";
    PlaylistsOrderBy["NameAsc"] = "NAME_ASC";
    PlaylistsOrderBy["NameDesc"] = "NAME_DESC";
    PlaylistsOrderBy["ContentAsc"] = "CONTENT_ASC";
    PlaylistsOrderBy["ContentDesc"] = "CONTENT_DESC";
    PlaylistsOrderBy["ColorAsc"] = "COLOR_ASC";
    PlaylistsOrderBy["ColorDesc"] = "COLOR_DESC";
    PlaylistsOrderBy["TagsAsc"] = "TAGS_ASC";
    PlaylistsOrderBy["TagsDesc"] = "TAGS_DESC";
    PlaylistsOrderBy["IsPublishedAsc"] = "IS_PUBLISHED_ASC";
    PlaylistsOrderBy["IsPublishedDesc"] = "IS_PUBLISHED_DESC";
    PlaylistsOrderBy["DraftOfAsc"] = "DRAFT_OF_ASC";
    PlaylistsOrderBy["DraftOfDesc"] = "DRAFT_OF_DESC";
    PlaylistsOrderBy["CreatedAtAsc"] = "CREATED_AT_ASC";
    PlaylistsOrderBy["CreatedAtDesc"] = "CREATED_AT_DESC";
    PlaylistsOrderBy["UpdatedAtAsc"] = "UPDATED_AT_ASC";
    PlaylistsOrderBy["UpdatedAtDesc"] = "UPDATED_AT_DESC";
    PlaylistsOrderBy["PublishedAtAsc"] = "PUBLISHED_AT_ASC";
    PlaylistsOrderBy["PublishedAtDesc"] = "PUBLISHED_AT_DESC";
    PlaylistsOrderBy["CreatedByAsc"] = "CREATED_BY_ASC";
    PlaylistsOrderBy["CreatedByDesc"] = "CREATED_BY_DESC";
    PlaylistsOrderBy["UpdatedByAsc"] = "UPDATED_BY_ASC";
    PlaylistsOrderBy["UpdatedByDesc"] = "UPDATED_BY_DESC";
    PlaylistsOrderBy["PublishedByAsc"] = "PUBLISHED_BY_ASC";
    PlaylistsOrderBy["PublishedByDesc"] = "PUBLISHED_BY_DESC";
    PlaylistsOrderBy["IsEmbedEnabledAsc"] = "IS_EMBED_ENABLED_ASC";
    PlaylistsOrderBy["IsEmbedEnabledDesc"] = "IS_EMBED_ENABLED_DESC";
    PlaylistsOrderBy["IsEmbedPublicAsc"] = "IS_EMBED_PUBLIC_ASC";
    PlaylistsOrderBy["IsEmbedPublicDesc"] = "IS_EMBED_PUBLIC_DESC";
    PlaylistsOrderBy["PrimaryKeyAsc"] = "PRIMARY_KEY_ASC";
    PlaylistsOrderBy["PrimaryKeyDesc"] = "PRIMARY_KEY_DESC";
})(PlaylistsOrderBy || (PlaylistsOrderBy = {}));
/** Methods to use when ordering `TagAssociation`. */
export var TagAssociationsOrderBy;
(function (TagAssociationsOrderBy) {
    TagAssociationsOrderBy["Natural"] = "NATURAL";
    TagAssociationsOrderBy["OrgIdAsc"] = "ORG_ID_ASC";
    TagAssociationsOrderBy["OrgIdDesc"] = "ORG_ID_DESC";
    TagAssociationsOrderBy["SpaceIdAsc"] = "SPACE_ID_ASC";
    TagAssociationsOrderBy["SpaceIdDesc"] = "SPACE_ID_DESC";
    TagAssociationsOrderBy["TagNameAsc"] = "TAG_NAME_ASC";
    TagAssociationsOrderBy["TagNameDesc"] = "TAG_NAME_DESC";
    TagAssociationsOrderBy["ToFileAsc"] = "TO_FILE_ASC";
    TagAssociationsOrderBy["ToFileDesc"] = "TO_FILE_DESC";
    TagAssociationsOrderBy["ToScreenAsc"] = "TO_SCREEN_ASC";
    TagAssociationsOrderBy["ToScreenDesc"] = "TO_SCREEN_DESC";
    TagAssociationsOrderBy["ToLinkAsc"] = "TO_LINK_ASC";
    TagAssociationsOrderBy["ToLinkDesc"] = "TO_LINK_DESC";
    TagAssociationsOrderBy["ToChannelAsc"] = "TO_CHANNEL_ASC";
    TagAssociationsOrderBy["ToChannelDesc"] = "TO_CHANNEL_DESC";
    TagAssociationsOrderBy["ToPlaylistAsc"] = "TO_PLAYLIST_ASC";
    TagAssociationsOrderBy["ToPlaylistDesc"] = "TO_PLAYLIST_DESC";
    TagAssociationsOrderBy["ToFolderAsc"] = "TO_FOLDER_ASC";
    TagAssociationsOrderBy["ToFolderDesc"] = "TO_FOLDER_DESC";
    TagAssociationsOrderBy["ToAppInstanceAsc"] = "TO_APP_INSTANCE_ASC";
    TagAssociationsOrderBy["ToAppInstanceDesc"] = "TO_APP_INSTANCE_DESC";
    TagAssociationsOrderBy["ToSiteAsc"] = "TO_SITE_ASC";
    TagAssociationsOrderBy["ToSiteDesc"] = "TO_SITE_DESC";
})(TagAssociationsOrderBy || (TagAssociationsOrderBy = {}));
export var LinkType;
(function (LinkType) {
    LinkType["Standard"] = "STANDARD";
    LinkType["Internal"] = "INTERNAL";
    LinkType["Cloud"] = "CLOUD";
})(LinkType || (LinkType = {}));
/** Methods to use when ordering `ShareAssociation`. */
export var ShareAssociationsOrderBy;
(function (ShareAssociationsOrderBy) {
    ShareAssociationsOrderBy["Natural"] = "NATURAL";
    ShareAssociationsOrderBy["OrgIdAsc"] = "ORG_ID_ASC";
    ShareAssociationsOrderBy["OrgIdDesc"] = "ORG_ID_DESC";
    ShareAssociationsOrderBy["FromSpaceAsc"] = "FROM_SPACE_ASC";
    ShareAssociationsOrderBy["FromSpaceDesc"] = "FROM_SPACE_DESC";
    ShareAssociationsOrderBy["ShareChannelAsc"] = "SHARE_CHANNEL_ASC";
    ShareAssociationsOrderBy["ShareChannelDesc"] = "SHARE_CHANNEL_DESC";
    ShareAssociationsOrderBy["SharePlaylistAsc"] = "SHARE_PLAYLIST_ASC";
    ShareAssociationsOrderBy["SharePlaylistDesc"] = "SHARE_PLAYLIST_DESC";
    ShareAssociationsOrderBy["ShareFolderAsc"] = "SHARE_FOLDER_ASC";
    ShareAssociationsOrderBy["ShareFolderDesc"] = "SHARE_FOLDER_DESC";
    ShareAssociationsOrderBy["ShareFileAsc"] = "SHARE_FILE_ASC";
    ShareAssociationsOrderBy["ShareFileDesc"] = "SHARE_FILE_DESC";
    ShareAssociationsOrderBy["ShareLinkAsc"] = "SHARE_LINK_ASC";
    ShareAssociationsOrderBy["ShareLinkDesc"] = "SHARE_LINK_DESC";
    ShareAssociationsOrderBy["ShareAppInstanceAsc"] = "SHARE_APP_INSTANCE_ASC";
    ShareAssociationsOrderBy["ShareAppInstanceDesc"] = "SHARE_APP_INSTANCE_DESC";
    ShareAssociationsOrderBy["ShareCredentialAsc"] = "SHARE_CREDENTIAL_ASC";
    ShareAssociationsOrderBy["ShareCredentialDesc"] = "SHARE_CREDENTIAL_DESC";
    ShareAssociationsOrderBy["ToSpaceAsc"] = "TO_SPACE_ASC";
    ShareAssociationsOrderBy["ToSpaceDesc"] = "TO_SPACE_DESC";
    ShareAssociationsOrderBy["SharedAtAsc"] = "SHARED_AT_ASC";
    ShareAssociationsOrderBy["SharedAtDesc"] = "SHARED_AT_DESC";
    ShareAssociationsOrderBy["SharedByAsc"] = "SHARED_BY_ASC";
    ShareAssociationsOrderBy["SharedByDesc"] = "SHARED_BY_DESC";
    ShareAssociationsOrderBy["ShareSiteAsc"] = "SHARE_SITE_ASC";
    ShareAssociationsOrderBy["ShareSiteDesc"] = "SHARE_SITE_DESC";
})(ShareAssociationsOrderBy || (ShareAssociationsOrderBy = {}));
export var AppInstanceStatus;
(function (AppInstanceStatus) {
    AppInstanceStatus["Active"] = "ACTIVE";
    AppInstanceStatus["Disabled"] = "DISABLED";
})(AppInstanceStatus || (AppInstanceStatus = {}));
/** Methods to use when ordering `CommonAppInstanceTemplate`. */
export var CommonAppInstanceTemplatesOrderBy;
(function (CommonAppInstanceTemplatesOrderBy) {
    CommonAppInstanceTemplatesOrderBy["Natural"] = "NATURAL";
    CommonAppInstanceTemplatesOrderBy["IdAsc"] = "ID_ASC";
    CommonAppInstanceTemplatesOrderBy["IdDesc"] = "ID_DESC";
    CommonAppInstanceTemplatesOrderBy["AppInstallIdAsc"] = "APP_INSTALL_ID_ASC";
    CommonAppInstanceTemplatesOrderBy["AppInstallIdDesc"] = "APP_INSTALL_ID_DESC";
    CommonAppInstanceTemplatesOrderBy["NameAsc"] = "NAME_ASC";
    CommonAppInstanceTemplatesOrderBy["NameDesc"] = "NAME_DESC";
    CommonAppInstanceTemplatesOrderBy["VersionAsc"] = "VERSION_ASC";
    CommonAppInstanceTemplatesOrderBy["VersionDesc"] = "VERSION_DESC";
    CommonAppInstanceTemplatesOrderBy["ConfigAsc"] = "CONFIG_ASC";
    CommonAppInstanceTemplatesOrderBy["ConfigDesc"] = "CONFIG_DESC";
    CommonAppInstanceTemplatesOrderBy["StateAsc"] = "STATE_ASC";
    CommonAppInstanceTemplatesOrderBy["StateDesc"] = "STATE_DESC";
    CommonAppInstanceTemplatesOrderBy["TagsAsc"] = "TAGS_ASC";
    CommonAppInstanceTemplatesOrderBy["TagsDesc"] = "TAGS_DESC";
    CommonAppInstanceTemplatesOrderBy["CreatedAtAsc"] = "CREATED_AT_ASC";
    CommonAppInstanceTemplatesOrderBy["CreatedAtDesc"] = "CREATED_AT_DESC";
    CommonAppInstanceTemplatesOrderBy["UpdatedAtAsc"] = "UPDATED_AT_ASC";
    CommonAppInstanceTemplatesOrderBy["UpdatedAtDesc"] = "UPDATED_AT_DESC";
    CommonAppInstanceTemplatesOrderBy["CreatedByAsc"] = "CREATED_BY_ASC";
    CommonAppInstanceTemplatesOrderBy["CreatedByDesc"] = "CREATED_BY_DESC";
    CommonAppInstanceTemplatesOrderBy["UpdatedByAsc"] = "UPDATED_BY_ASC";
    CommonAppInstanceTemplatesOrderBy["UpdatedByDesc"] = "UPDATED_BY_DESC";
    CommonAppInstanceTemplatesOrderBy["IsTemplateAsc"] = "IS_TEMPLATE_ASC";
    CommonAppInstanceTemplatesOrderBy["IsTemplateDesc"] = "IS_TEMPLATE_DESC";
    CommonAppInstanceTemplatesOrderBy["StatusAsc"] = "STATUS_ASC";
    CommonAppInstanceTemplatesOrderBy["StatusDesc"] = "STATUS_DESC";
    CommonAppInstanceTemplatesOrderBy["ThumbnailFileIdAsc"] = "THUMBNAIL_FILE_ID_ASC";
    CommonAppInstanceTemplatesOrderBy["ThumbnailFileIdDesc"] = "THUMBNAIL_FILE_ID_DESC";
    CommonAppInstanceTemplatesOrderBy["IsPublishedAsc"] = "IS_PUBLISHED_ASC";
    CommonAppInstanceTemplatesOrderBy["IsPublishedDesc"] = "IS_PUBLISHED_DESC";
    CommonAppInstanceTemplatesOrderBy["IsDeprecatedAsc"] = "IS_DEPRECATED_ASC";
    CommonAppInstanceTemplatesOrderBy["IsDeprecatedDesc"] = "IS_DEPRECATED_DESC";
    CommonAppInstanceTemplatesOrderBy["SourceOrgIdAsc"] = "SOURCE_ORG_ID_ASC";
    CommonAppInstanceTemplatesOrderBy["SourceOrgIdDesc"] = "SOURCE_ORG_ID_DESC";
    CommonAppInstanceTemplatesOrderBy["SourceAppInstanceIdAsc"] = "SOURCE_APP_INSTANCE_ID_ASC";
    CommonAppInstanceTemplatesOrderBy["SourceAppInstanceIdDesc"] = "SOURCE_APP_INSTANCE_ID_DESC";
    CommonAppInstanceTemplatesOrderBy["AppIdAsc"] = "APP_ID_ASC";
    CommonAppInstanceTemplatesOrderBy["AppIdDesc"] = "APP_ID_DESC";
    CommonAppInstanceTemplatesOrderBy["PrimaryKeyAsc"] = "PRIMARY_KEY_ASC";
    CommonAppInstanceTemplatesOrderBy["PrimaryKeyDesc"] = "PRIMARY_KEY_DESC";
})(CommonAppInstanceTemplatesOrderBy || (CommonAppInstanceTemplatesOrderBy = {}));
/** Methods to use when ordering `AppInstance`. */
export var AppInstancesOrderBy;
(function (AppInstancesOrderBy) {
    AppInstancesOrderBy["Natural"] = "NATURAL";
    AppInstancesOrderBy["IdAsc"] = "ID_ASC";
    AppInstancesOrderBy["IdDesc"] = "ID_DESC";
    AppInstancesOrderBy["OrgIdAsc"] = "ORG_ID_ASC";
    AppInstancesOrderBy["OrgIdDesc"] = "ORG_ID_DESC";
    AppInstancesOrderBy["AppInstallIdAsc"] = "APP_INSTALL_ID_ASC";
    AppInstancesOrderBy["AppInstallIdDesc"] = "APP_INSTALL_ID_DESC";
    AppInstancesOrderBy["SpaceIdAsc"] = "SPACE_ID_ASC";
    AppInstancesOrderBy["SpaceIdDesc"] = "SPACE_ID_DESC";
    AppInstancesOrderBy["NameAsc"] = "NAME_ASC";
    AppInstancesOrderBy["NameDesc"] = "NAME_DESC";
    AppInstancesOrderBy["VersionAsc"] = "VERSION_ASC";
    AppInstancesOrderBy["VersionDesc"] = "VERSION_DESC";
    AppInstancesOrderBy["ConfigAsc"] = "CONFIG_ASC";
    AppInstancesOrderBy["ConfigDesc"] = "CONFIG_DESC";
    AppInstancesOrderBy["StateAsc"] = "STATE_ASC";
    AppInstancesOrderBy["StateDesc"] = "STATE_DESC";
    AppInstancesOrderBy["TagsAsc"] = "TAGS_ASC";
    AppInstancesOrderBy["TagsDesc"] = "TAGS_DESC";
    AppInstancesOrderBy["CreatedAtAsc"] = "CREATED_AT_ASC";
    AppInstancesOrderBy["CreatedAtDesc"] = "CREATED_AT_DESC";
    AppInstancesOrderBy["UpdatedAtAsc"] = "UPDATED_AT_ASC";
    AppInstancesOrderBy["UpdatedAtDesc"] = "UPDATED_AT_DESC";
    AppInstancesOrderBy["CreatedByAsc"] = "CREATED_BY_ASC";
    AppInstancesOrderBy["CreatedByDesc"] = "CREATED_BY_DESC";
    AppInstancesOrderBy["UpdatedByAsc"] = "UPDATED_BY_ASC";
    AppInstancesOrderBy["UpdatedByDesc"] = "UPDATED_BY_DESC";
    AppInstancesOrderBy["IsTemplateAsc"] = "IS_TEMPLATE_ASC";
    AppInstancesOrderBy["IsTemplateDesc"] = "IS_TEMPLATE_DESC";
    AppInstancesOrderBy["StatusAsc"] = "STATUS_ASC";
    AppInstancesOrderBy["StatusDesc"] = "STATUS_DESC";
    AppInstancesOrderBy["ThumbnailFileIdAsc"] = "THUMBNAIL_FILE_ID_ASC";
    AppInstancesOrderBy["ThumbnailFileIdDesc"] = "THUMBNAIL_FILE_ID_DESC";
    AppInstancesOrderBy["CommonAppInstanceTemplateIdAsc"] = "COMMON_APP_INSTANCE_TEMPLATE_ID_ASC";
    AppInstancesOrderBy["CommonAppInstanceTemplateIdDesc"] = "COMMON_APP_INSTANCE_TEMPLATE_ID_DESC";
    AppInstancesOrderBy["TargetCommonAppInstanceTemplateIdAsc"] = "TARGET_COMMON_APP_INSTANCE_TEMPLATE_ID_ASC";
    AppInstancesOrderBy["TargetCommonAppInstanceTemplateIdDesc"] = "TARGET_COMMON_APP_INSTANCE_TEMPLATE_ID_DESC";
    AppInstancesOrderBy["AppIdAsc"] = "APP_ID_ASC";
    AppInstancesOrderBy["AppIdDesc"] = "APP_ID_DESC";
    AppInstancesOrderBy["PrimaryKeyAsc"] = "PRIMARY_KEY_ASC";
    AppInstancesOrderBy["PrimaryKeyDesc"] = "PRIMARY_KEY_DESC";
})(AppInstancesOrderBy || (AppInstancesOrderBy = {}));
/** Methods to use when ordering `ScreenContentHistory`. */
export var ScreenContentHistoriesOrderBy;
(function (ScreenContentHistoriesOrderBy) {
    ScreenContentHistoriesOrderBy["Natural"] = "NATURAL";
    ScreenContentHistoriesOrderBy["IdAsc"] = "ID_ASC";
    ScreenContentHistoriesOrderBy["IdDesc"] = "ID_DESC";
    ScreenContentHistoriesOrderBy["OrgIdAsc"] = "ORG_ID_ASC";
    ScreenContentHistoriesOrderBy["OrgIdDesc"] = "ORG_ID_DESC";
    ScreenContentHistoriesOrderBy["ScreenIdAsc"] = "SCREEN_ID_ASC";
    ScreenContentHistoriesOrderBy["ScreenIdDesc"] = "SCREEN_ID_DESC";
    ScreenContentHistoriesOrderBy["SpaceIdAsc"] = "SPACE_ID_ASC";
    ScreenContentHistoriesOrderBy["SpaceIdDesc"] = "SPACE_ID_DESC";
    ScreenContentHistoriesOrderBy["ContentAsc"] = "CONTENT_ASC";
    ScreenContentHistoriesOrderBy["ContentDesc"] = "CONTENT_DESC";
    ScreenContentHistoriesOrderBy["FileIdAsc"] = "FILE_ID_ASC";
    ScreenContentHistoriesOrderBy["FileIdDesc"] = "FILE_ID_DESC";
    ScreenContentHistoriesOrderBy["ChannelIdAsc"] = "CHANNEL_ID_ASC";
    ScreenContentHistoriesOrderBy["ChannelIdDesc"] = "CHANNEL_ID_DESC";
    ScreenContentHistoriesOrderBy["PlaylistIdAsc"] = "PLAYLIST_ID_ASC";
    ScreenContentHistoriesOrderBy["PlaylistIdDesc"] = "PLAYLIST_ID_DESC";
    ScreenContentHistoriesOrderBy["LinkIdAsc"] = "LINK_ID_ASC";
    ScreenContentHistoriesOrderBy["LinkIdDesc"] = "LINK_ID_DESC";
    ScreenContentHistoriesOrderBy["AppInstanceIdAsc"] = "APP_INSTANCE_ID_ASC";
    ScreenContentHistoriesOrderBy["AppInstanceIdDesc"] = "APP_INSTANCE_ID_DESC";
    ScreenContentHistoriesOrderBy["CreatedAtAsc"] = "CREATED_AT_ASC";
    ScreenContentHistoriesOrderBy["CreatedAtDesc"] = "CREATED_AT_DESC";
    ScreenContentHistoriesOrderBy["CreatedByAsc"] = "CREATED_BY_ASC";
    ScreenContentHistoriesOrderBy["CreatedByDesc"] = "CREATED_BY_DESC";
    ScreenContentHistoriesOrderBy["SiteIdAsc"] = "SITE_ID_ASC";
    ScreenContentHistoriesOrderBy["SiteIdDesc"] = "SITE_ID_DESC";
    ScreenContentHistoriesOrderBy["PrimaryKeyAsc"] = "PRIMARY_KEY_ASC";
    ScreenContentHistoriesOrderBy["PrimaryKeyDesc"] = "PRIMARY_KEY_DESC";
})(ScreenContentHistoriesOrderBy || (ScreenContentHistoriesOrderBy = {}));
export var SiteType;
(function (SiteType) {
    SiteType["Basic"] = "BASIC";
    SiteType["BasicInternal"] = "BASIC_INTERNAL";
    SiteType["Cloud"] = "CLOUD";
    SiteType["CloudInternal"] = "CLOUD_INTERNAL";
    SiteType["Secure"] = "SECURE";
    SiteType["SecureInternal"] = "SECURE_INTERNAL";
})(SiteType || (SiteType = {}));
/** Methods to use when ordering `Channel`. */
export var ChannelsOrderBy;
(function (ChannelsOrderBy) {
    ChannelsOrderBy["Natural"] = "NATURAL";
    ChannelsOrderBy["IdAsc"] = "ID_ASC";
    ChannelsOrderBy["IdDesc"] = "ID_DESC";
    ChannelsOrderBy["OrgIdAsc"] = "ORG_ID_ASC";
    ChannelsOrderBy["OrgIdDesc"] = "ORG_ID_DESC";
    ChannelsOrderBy["SpaceIdAsc"] = "SPACE_ID_ASC";
    ChannelsOrderBy["SpaceIdDesc"] = "SPACE_ID_DESC";
    ChannelsOrderBy["NameAsc"] = "NAME_ASC";
    ChannelsOrderBy["NameDesc"] = "NAME_DESC";
    ChannelsOrderBy["WidthAsc"] = "WIDTH_ASC";
    ChannelsOrderBy["WidthDesc"] = "WIDTH_DESC";
    ChannelsOrderBy["HeightAsc"] = "HEIGHT_ASC";
    ChannelsOrderBy["HeightDesc"] = "HEIGHT_DESC";
    ChannelsOrderBy["LayoutIdAsc"] = "LAYOUT_ID_ASC";
    ChannelsOrderBy["LayoutIdDesc"] = "LAYOUT_ID_DESC";
    ChannelsOrderBy["CoverDataAsc"] = "COVER_DATA_ASC";
    ChannelsOrderBy["CoverDataDesc"] = "COVER_DATA_DESC";
    ChannelsOrderBy["CoverImageIdAsc"] = "COVER_IMAGE_ID_ASC";
    ChannelsOrderBy["CoverImageIdDesc"] = "COVER_IMAGE_ID_DESC";
    ChannelsOrderBy["ContentAsc"] = "CONTENT_ASC";
    ChannelsOrderBy["ContentDesc"] = "CONTENT_DESC";
    ChannelsOrderBy["TagsAsc"] = "TAGS_ASC";
    ChannelsOrderBy["TagsDesc"] = "TAGS_DESC";
    ChannelsOrderBy["HiddenZoneAsc"] = "HIDDEN_ZONE_ASC";
    ChannelsOrderBy["HiddenZoneDesc"] = "HIDDEN_ZONE_DESC";
    ChannelsOrderBy["IsHiddenAsc"] = "IS_HIDDEN_ASC";
    ChannelsOrderBy["IsHiddenDesc"] = "IS_HIDDEN_DESC";
    ChannelsOrderBy["IsPublishedAsc"] = "IS_PUBLISHED_ASC";
    ChannelsOrderBy["IsPublishedDesc"] = "IS_PUBLISHED_DESC";
    ChannelsOrderBy["DraftOfAsc"] = "DRAFT_OF_ASC";
    ChannelsOrderBy["DraftOfDesc"] = "DRAFT_OF_DESC";
    ChannelsOrderBy["CreatedAtAsc"] = "CREATED_AT_ASC";
    ChannelsOrderBy["CreatedAtDesc"] = "CREATED_AT_DESC";
    ChannelsOrderBy["UpdatedAtAsc"] = "UPDATED_AT_ASC";
    ChannelsOrderBy["UpdatedAtDesc"] = "UPDATED_AT_DESC";
    ChannelsOrderBy["PublishedAtAsc"] = "PUBLISHED_AT_ASC";
    ChannelsOrderBy["PublishedAtDesc"] = "PUBLISHED_AT_DESC";
    ChannelsOrderBy["CreatedByAsc"] = "CREATED_BY_ASC";
    ChannelsOrderBy["CreatedByDesc"] = "CREATED_BY_DESC";
    ChannelsOrderBy["UpdatedByAsc"] = "UPDATED_BY_ASC";
    ChannelsOrderBy["UpdatedByDesc"] = "UPDATED_BY_DESC";
    ChannelsOrderBy["PublishedByAsc"] = "PUBLISHED_BY_ASC";
    ChannelsOrderBy["PublishedByDesc"] = "PUBLISHED_BY_DESC";
    ChannelsOrderBy["ThemeIdAsc"] = "THEME_ID_ASC";
    ChannelsOrderBy["ThemeIdDesc"] = "THEME_ID_DESC";
    ChannelsOrderBy["UseOrgDefaultThemeAsc"] = "USE_ORG_DEFAULT_THEME_ASC";
    ChannelsOrderBy["UseOrgDefaultThemeDesc"] = "USE_ORG_DEFAULT_THEME_DESC";
    ChannelsOrderBy["TypeAsc"] = "TYPE_ASC";
    ChannelsOrderBy["TypeDesc"] = "TYPE_DESC";
    ChannelsOrderBy["IsEmbedEnabledAsc"] = "IS_EMBED_ENABLED_ASC";
    ChannelsOrderBy["IsEmbedEnabledDesc"] = "IS_EMBED_ENABLED_DESC";
    ChannelsOrderBy["IsEmbedPublicAsc"] = "IS_EMBED_PUBLIC_ASC";
    ChannelsOrderBy["IsEmbedPublicDesc"] = "IS_EMBED_PUBLIC_DESC";
    ChannelsOrderBy["PrimaryKeyAsc"] = "PRIMARY_KEY_ASC";
    ChannelsOrderBy["PrimaryKeyDesc"] = "PRIMARY_KEY_DESC";
})(ChannelsOrderBy || (ChannelsOrderBy = {}));
/** Methods to use when ordering `Theme`. */
export var ThemesOrderBy;
(function (ThemesOrderBy) {
    ThemesOrderBy["Natural"] = "NATURAL";
    ThemesOrderBy["IdAsc"] = "ID_ASC";
    ThemesOrderBy["IdDesc"] = "ID_DESC";
    ThemesOrderBy["OrgIdAsc"] = "ORG_ID_ASC";
    ThemesOrderBy["OrgIdDesc"] = "ORG_ID_DESC";
    ThemesOrderBy["NameAsc"] = "NAME_ASC";
    ThemesOrderBy["NameDesc"] = "NAME_DESC";
    ThemesOrderBy["LogoFileIdAsc"] = "LOGO_FILE_ID_ASC";
    ThemesOrderBy["LogoFileIdDesc"] = "LOGO_FILE_ID_DESC";
    ThemesOrderBy["LogoFileIdDraftAsc"] = "LOGO_FILE_ID_DRAFT_ASC";
    ThemesOrderBy["LogoFileIdDraftDesc"] = "LOGO_FILE_ID_DRAFT_DESC";
    ThemesOrderBy["ConfigAsc"] = "CONFIG_ASC";
    ThemesOrderBy["ConfigDesc"] = "CONFIG_DESC";
    ThemesOrderBy["ConfigDraftAsc"] = "CONFIG_DRAFT_ASC";
    ThemesOrderBy["ConfigDraftDesc"] = "CONFIG_DRAFT_DESC";
    ThemesOrderBy["CreatedAtAsc"] = "CREATED_AT_ASC";
    ThemesOrderBy["CreatedAtDesc"] = "CREATED_AT_DESC";
    ThemesOrderBy["UpdatedAtAsc"] = "UPDATED_AT_ASC";
    ThemesOrderBy["UpdatedAtDesc"] = "UPDATED_AT_DESC";
    ThemesOrderBy["CreatedByAsc"] = "CREATED_BY_ASC";
    ThemesOrderBy["CreatedByDesc"] = "CREATED_BY_DESC";
    ThemesOrderBy["UpdatedByAsc"] = "UPDATED_BY_ASC";
    ThemesOrderBy["UpdatedByDesc"] = "UPDATED_BY_DESC";
    ThemesOrderBy["PrimaryKeyAsc"] = "PRIMARY_KEY_ASC";
    ThemesOrderBy["PrimaryKeyDesc"] = "PRIMARY_KEY_DESC";
})(ThemesOrderBy || (ThemesOrderBy = {}));
/** Methods to use when ordering `Link`. */
export var LinksOrderBy;
(function (LinksOrderBy) {
    LinksOrderBy["Natural"] = "NATURAL";
    LinksOrderBy["IdAsc"] = "ID_ASC";
    LinksOrderBy["IdDesc"] = "ID_DESC";
    LinksOrderBy["OrgIdAsc"] = "ORG_ID_ASC";
    LinksOrderBy["OrgIdDesc"] = "ORG_ID_DESC";
    LinksOrderBy["SpaceIdAsc"] = "SPACE_ID_ASC";
    LinksOrderBy["SpaceIdDesc"] = "SPACE_ID_DESC";
    LinksOrderBy["NameAsc"] = "NAME_ASC";
    LinksOrderBy["NameDesc"] = "NAME_DESC";
    LinksOrderBy["UrlAsc"] = "URL_ASC";
    LinksOrderBy["UrlDesc"] = "URL_DESC";
    LinksOrderBy["FileIdAsc"] = "FILE_ID_ASC";
    LinksOrderBy["FileIdDesc"] = "FILE_ID_DESC";
    LinksOrderBy["TagsAsc"] = "TAGS_ASC";
    LinksOrderBy["TagsDesc"] = "TAGS_DESC";
    LinksOrderBy["LinkTypeAsc"] = "LINK_TYPE_ASC";
    LinksOrderBy["LinkTypeDesc"] = "LINK_TYPE_DESC";
    LinksOrderBy["CloudConfigAsc"] = "CLOUD_CONFIG_ASC";
    LinksOrderBy["CloudConfigDesc"] = "CLOUD_CONFIG_DESC";
    LinksOrderBy["CreatedAtAsc"] = "CREATED_AT_ASC";
    LinksOrderBy["CreatedAtDesc"] = "CREATED_AT_DESC";
    LinksOrderBy["UpdatedAtAsc"] = "UPDATED_AT_ASC";
    LinksOrderBy["UpdatedAtDesc"] = "UPDATED_AT_DESC";
    LinksOrderBy["CreatedByAsc"] = "CREATED_BY_ASC";
    LinksOrderBy["CreatedByDesc"] = "CREATED_BY_DESC";
    LinksOrderBy["UpdatedByAsc"] = "UPDATED_BY_ASC";
    LinksOrderBy["UpdatedByDesc"] = "UPDATED_BY_DESC";
    LinksOrderBy["PrimaryKeyAsc"] = "PRIMARY_KEY_ASC";
    LinksOrderBy["PrimaryKeyDesc"] = "PRIMARY_KEY_DESC";
})(LinksOrderBy || (LinksOrderBy = {}));
/** Methods to use when ordering `FileProcessingJob`. */
export var FileProcessingJobsOrderBy;
(function (FileProcessingJobsOrderBy) {
    FileProcessingJobsOrderBy["Natural"] = "NATURAL";
    FileProcessingJobsOrderBy["IdAsc"] = "ID_ASC";
    FileProcessingJobsOrderBy["IdDesc"] = "ID_DESC";
    FileProcessingJobsOrderBy["OrgIdAsc"] = "ORG_ID_ASC";
    FileProcessingJobsOrderBy["OrgIdDesc"] = "ORG_ID_DESC";
    FileProcessingJobsOrderBy["SpaceIdAsc"] = "SPACE_ID_ASC";
    FileProcessingJobsOrderBy["SpaceIdDesc"] = "SPACE_ID_DESC";
    FileProcessingJobsOrderBy["FileIdAsc"] = "FILE_ID_ASC";
    FileProcessingJobsOrderBy["FileIdDesc"] = "FILE_ID_DESC";
    FileProcessingJobsOrderBy["JobTypeAsc"] = "JOB_TYPE_ASC";
    FileProcessingJobsOrderBy["JobTypeDesc"] = "JOB_TYPE_DESC";
    FileProcessingJobsOrderBy["JobTargetOutputAsc"] = "JOB_TARGET_OUTPUT_ASC";
    FileProcessingJobsOrderBy["JobTargetOutputDesc"] = "JOB_TARGET_OUTPUT_DESC";
    FileProcessingJobsOrderBy["InfoAsc"] = "INFO_ASC";
    FileProcessingJobsOrderBy["InfoDesc"] = "INFO_DESC";
    FileProcessingJobsOrderBy["StatusAsc"] = "STATUS_ASC";
    FileProcessingJobsOrderBy["StatusDesc"] = "STATUS_DESC";
    FileProcessingJobsOrderBy["RetriesAsc"] = "RETRIES_ASC";
    FileProcessingJobsOrderBy["RetriesDesc"] = "RETRIES_DESC";
    FileProcessingJobsOrderBy["LastRetryAtAsc"] = "LAST_RETRY_AT_ASC";
    FileProcessingJobsOrderBy["LastRetryAtDesc"] = "LAST_RETRY_AT_DESC";
    FileProcessingJobsOrderBy["ErrorAsc"] = "ERROR_ASC";
    FileProcessingJobsOrderBy["ErrorDesc"] = "ERROR_DESC";
    FileProcessingJobsOrderBy["CreatedAtAsc"] = "CREATED_AT_ASC";
    FileProcessingJobsOrderBy["CreatedAtDesc"] = "CREATED_AT_DESC";
    FileProcessingJobsOrderBy["FinishedAtAsc"] = "FINISHED_AT_ASC";
    FileProcessingJobsOrderBy["FinishedAtDesc"] = "FINISHED_AT_DESC";
    FileProcessingJobsOrderBy["StartedAtAsc"] = "STARTED_AT_ASC";
    FileProcessingJobsOrderBy["StartedAtDesc"] = "STARTED_AT_DESC";
    FileProcessingJobsOrderBy["PrimaryKeyAsc"] = "PRIMARY_KEY_ASC";
    FileProcessingJobsOrderBy["PrimaryKeyDesc"] = "PRIMARY_KEY_DESC";
})(FileProcessingJobsOrderBy || (FileProcessingJobsOrderBy = {}));
export var JobType;
(function (JobType) {
    JobType["Image"] = "IMAGE";
    JobType["Document"] = "DOCUMENT";
    JobType["Inspection"] = "INSPECTION";
    JobType["Snapshot"] = "SNAPSHOT";
    JobType["Avc"] = "AVC";
    JobType["Hevc"] = "HEVC";
    JobType["FileCopy"] = "FILE_COPY";
    JobType["Audio"] = "AUDIO";
})(JobType || (JobType = {}));
export var JobTargetOutput;
(function (JobTargetOutput) {
    JobTargetOutput["Mp4"] = "MP4";
    JobTargetOutput["Dash"] = "DASH";
    JobTargetOutput["Hls"] = "HLS";
    JobTargetOutput["Jpeg"] = "JPEG";
    JobTargetOutput["Png"] = "PNG";
})(JobTargetOutput || (JobTargetOutput = {}));
/** Methods to use when ordering `FileOutput`. */
export var FileOutputsOrderBy;
(function (FileOutputsOrderBy) {
    FileOutputsOrderBy["Natural"] = "NATURAL";
    FileOutputsOrderBy["IdAsc"] = "ID_ASC";
    FileOutputsOrderBy["IdDesc"] = "ID_DESC";
    FileOutputsOrderBy["OrgIdAsc"] = "ORG_ID_ASC";
    FileOutputsOrderBy["OrgIdDesc"] = "ORG_ID_DESC";
    FileOutputsOrderBy["SpaceIdAsc"] = "SPACE_ID_ASC";
    FileOutputsOrderBy["SpaceIdDesc"] = "SPACE_ID_DESC";
    FileOutputsOrderBy["FileIdAsc"] = "FILE_ID_ASC";
    FileOutputsOrderBy["FileIdDesc"] = "FILE_ID_DESC";
    FileOutputsOrderBy["FileProcessingJobIdAsc"] = "FILE_PROCESSING_JOB_ID_ASC";
    FileOutputsOrderBy["FileProcessingJobIdDesc"] = "FILE_PROCESSING_JOB_ID_DESC";
    FileOutputsOrderBy["UrlAsc"] = "URL_ASC";
    FileOutputsOrderBy["UrlDesc"] = "URL_DESC";
    FileOutputsOrderBy["ContentAsc"] = "CONTENT_ASC";
    FileOutputsOrderBy["ContentDesc"] = "CONTENT_DESC";
    FileOutputsOrderBy["MetadataAsc"] = "METADATA_ASC";
    FileOutputsOrderBy["MetadataDesc"] = "METADATA_DESC";
    FileOutputsOrderBy["MimetypeAsc"] = "MIMETYPE_ASC";
    FileOutputsOrderBy["MimetypeDesc"] = "MIMETYPE_DESC";
    FileOutputsOrderBy["SizeAsc"] = "SIZE_ASC";
    FileOutputsOrderBy["SizeDesc"] = "SIZE_DESC";
    FileOutputsOrderBy["CreatedAtAsc"] = "CREATED_AT_ASC";
    FileOutputsOrderBy["CreatedAtDesc"] = "CREATED_AT_DESC";
    FileOutputsOrderBy["CommonFileIdAsc"] = "COMMON_FILE_ID_ASC";
    FileOutputsOrderBy["CommonFileIdDesc"] = "COMMON_FILE_ID_DESC";
    FileOutputsOrderBy["PrimaryKeyAsc"] = "PRIMARY_KEY_ASC";
    FileOutputsOrderBy["PrimaryKeyDesc"] = "PRIMARY_KEY_DESC";
})(FileOutputsOrderBy || (FileOutputsOrderBy = {}));
/** Methods to use when ordering `Site`. */
export var SitesOrderBy;
(function (SitesOrderBy) {
    SitesOrderBy["Natural"] = "NATURAL";
    SitesOrderBy["IdAsc"] = "ID_ASC";
    SitesOrderBy["IdDesc"] = "ID_DESC";
    SitesOrderBy["OrgIdAsc"] = "ORG_ID_ASC";
    SitesOrderBy["OrgIdDesc"] = "ORG_ID_DESC";
    SitesOrderBy["SpaceIdAsc"] = "SPACE_ID_ASC";
    SitesOrderBy["SpaceIdDesc"] = "SPACE_ID_DESC";
    SitesOrderBy["NameAsc"] = "NAME_ASC";
    SitesOrderBy["NameDesc"] = "NAME_DESC";
    SitesOrderBy["TypeAsc"] = "TYPE_ASC";
    SitesOrderBy["TypeDesc"] = "TYPE_DESC";
    SitesOrderBy["UrlAsc"] = "URL_ASC";
    SitesOrderBy["UrlDesc"] = "URL_DESC";
    SitesOrderBy["ActionsAsc"] = "ACTIONS_ASC";
    SitesOrderBy["ActionsDesc"] = "ACTIONS_DESC";
    SitesOrderBy["ComputeAsc"] = "COMPUTE_ASC";
    SitesOrderBy["ComputeDesc"] = "COMPUTE_DESC";
    SitesOrderBy["ConfigAsc"] = "CONFIG_ASC";
    SitesOrderBy["ConfigDesc"] = "CONFIG_DESC";
    SitesOrderBy["ThumbnailIdAsc"] = "THUMBNAIL_ID_ASC";
    SitesOrderBy["ThumbnailIdDesc"] = "THUMBNAIL_ID_DESC";
    SitesOrderBy["TagsAsc"] = "TAGS_ASC";
    SitesOrderBy["TagsDesc"] = "TAGS_DESC";
    SitesOrderBy["CredentialIdsAsc"] = "CREDENTIAL_IDS_ASC";
    SitesOrderBy["CredentialIdsDesc"] = "CREDENTIAL_IDS_DESC";
    SitesOrderBy["CreatedAtAsc"] = "CREATED_AT_ASC";
    SitesOrderBy["CreatedAtDesc"] = "CREATED_AT_DESC";
    SitesOrderBy["UpdatedAtAsc"] = "UPDATED_AT_ASC";
    SitesOrderBy["UpdatedAtDesc"] = "UPDATED_AT_DESC";
    SitesOrderBy["CreatedByAsc"] = "CREATED_BY_ASC";
    SitesOrderBy["CreatedByDesc"] = "CREATED_BY_DESC";
    SitesOrderBy["UpdatedByAsc"] = "UPDATED_BY_ASC";
    SitesOrderBy["UpdatedByDesc"] = "UPDATED_BY_DESC";
    SitesOrderBy["PrimaryKeyAsc"] = "PRIMARY_KEY_ASC";
    SitesOrderBy["PrimaryKeyDesc"] = "PRIMARY_KEY_DESC";
})(SitesOrderBy || (SitesOrderBy = {}));
/** Methods to use when ordering `Cast`. */
export var CastsOrderBy;
(function (CastsOrderBy) {
    CastsOrderBy["Natural"] = "NATURAL";
    CastsOrderBy["IdAsc"] = "ID_ASC";
    CastsOrderBy["IdDesc"] = "ID_DESC";
    CastsOrderBy["OrgIdAsc"] = "ORG_ID_ASC";
    CastsOrderBy["OrgIdDesc"] = "ORG_ID_DESC";
    CastsOrderBy["SpaceIdAsc"] = "SPACE_ID_ASC";
    CastsOrderBy["SpaceIdDesc"] = "SPACE_ID_DESC";
    CastsOrderBy["PriorityAsc"] = "PRIORITY_ASC";
    CastsOrderBy["PriorityDesc"] = "PRIORITY_DESC";
    CastsOrderBy["ExpiresAtAsc"] = "EXPIRES_AT_ASC";
    CastsOrderBy["ExpiresAtDesc"] = "EXPIRES_AT_DESC";
    CastsOrderBy["ContentAsc"] = "CONTENT_ASC";
    CastsOrderBy["ContentDesc"] = "CONTENT_DESC";
    CastsOrderBy["CreatedAtAsc"] = "CREATED_AT_ASC";
    CastsOrderBy["CreatedAtDesc"] = "CREATED_AT_DESC";
    CastsOrderBy["UpdatedAtAsc"] = "UPDATED_AT_ASC";
    CastsOrderBy["UpdatedAtDesc"] = "UPDATED_AT_DESC";
    CastsOrderBy["CreatedByAsc"] = "CREATED_BY_ASC";
    CastsOrderBy["CreatedByDesc"] = "CREATED_BY_DESC";
    CastsOrderBy["UpdatedByAsc"] = "UPDATED_BY_ASC";
    CastsOrderBy["UpdatedByDesc"] = "UPDATED_BY_DESC";
    CastsOrderBy["PrimaryKeyAsc"] = "PRIMARY_KEY_ASC";
    CastsOrderBy["PrimaryKeyDesc"] = "PRIMARY_KEY_DESC";
})(CastsOrderBy || (CastsOrderBy = {}));
/** Methods to use when ordering `Layout`. */
export var LayoutsOrderBy;
(function (LayoutsOrderBy) {
    LayoutsOrderBy["Natural"] = "NATURAL";
    LayoutsOrderBy["IdAsc"] = "ID_ASC";
    LayoutsOrderBy["IdDesc"] = "ID_DESC";
    LayoutsOrderBy["OrgIdAsc"] = "ORG_ID_ASC";
    LayoutsOrderBy["OrgIdDesc"] = "ORG_ID_DESC";
    LayoutsOrderBy["SpaceIdAsc"] = "SPACE_ID_ASC";
    LayoutsOrderBy["SpaceIdDesc"] = "SPACE_ID_DESC";
    LayoutsOrderBy["NameAsc"] = "NAME_ASC";
    LayoutsOrderBy["NameDesc"] = "NAME_DESC";
    LayoutsOrderBy["WidthAsc"] = "WIDTH_ASC";
    LayoutsOrderBy["WidthDesc"] = "WIDTH_DESC";
    LayoutsOrderBy["HeightAsc"] = "HEIGHT_ASC";
    LayoutsOrderBy["HeightDesc"] = "HEIGHT_DESC";
    LayoutsOrderBy["IsFlexibleAsc"] = "IS_FLEXIBLE_ASC";
    LayoutsOrderBy["IsFlexibleDesc"] = "IS_FLEXIBLE_DESC";
    LayoutsOrderBy["IsScalableAsc"] = "IS_SCALABLE_ASC";
    LayoutsOrderBy["IsScalableDesc"] = "IS_SCALABLE_DESC";
    LayoutsOrderBy["ZonesAsc"] = "ZONES_ASC";
    LayoutsOrderBy["ZonesDesc"] = "ZONES_DESC";
    LayoutsOrderBy["ConfigAsc"] = "CONFIG_ASC";
    LayoutsOrderBy["ConfigDesc"] = "CONFIG_DESC";
    LayoutsOrderBy["CreatedAtAsc"] = "CREATED_AT_ASC";
    LayoutsOrderBy["CreatedAtDesc"] = "CREATED_AT_DESC";
    LayoutsOrderBy["UpdatedAtAsc"] = "UPDATED_AT_ASC";
    LayoutsOrderBy["UpdatedAtDesc"] = "UPDATED_AT_DESC";
    LayoutsOrderBy["CreatedByAsc"] = "CREATED_BY_ASC";
    LayoutsOrderBy["CreatedByDesc"] = "CREATED_BY_DESC";
    LayoutsOrderBy["UpdatedByAsc"] = "UPDATED_BY_ASC";
    LayoutsOrderBy["UpdatedByDesc"] = "UPDATED_BY_DESC";
    LayoutsOrderBy["PrimaryKeyAsc"] = "PRIMARY_KEY_ASC";
    LayoutsOrderBy["PrimaryKeyDesc"] = "PRIMARY_KEY_DESC";
})(LayoutsOrderBy || (LayoutsOrderBy = {}));
/** Methods to use when ordering `Tag`. */
export var TagsOrderBy;
(function (TagsOrderBy) {
    TagsOrderBy["Natural"] = "NATURAL";
    TagsOrderBy["OrgIdAsc"] = "ORG_ID_ASC";
    TagsOrderBy["OrgIdDesc"] = "ORG_ID_DESC";
    TagsOrderBy["SpaceIdAsc"] = "SPACE_ID_ASC";
    TagsOrderBy["SpaceIdDesc"] = "SPACE_ID_DESC";
    TagsOrderBy["NameAsc"] = "NAME_ASC";
    TagsOrderBy["NameDesc"] = "NAME_DESC";
    TagsOrderBy["PrimaryKeyAsc"] = "PRIMARY_KEY_ASC";
    TagsOrderBy["PrimaryKeyDesc"] = "PRIMARY_KEY_DESC";
})(TagsOrderBy || (TagsOrderBy = {}));
/** Methods to use when ordering `Credential`. */
export var CredentialsOrderBy;
(function (CredentialsOrderBy) {
    CredentialsOrderBy["Natural"] = "NATURAL";
    CredentialsOrderBy["IdAsc"] = "ID_ASC";
    CredentialsOrderBy["IdDesc"] = "ID_DESC";
    CredentialsOrderBy["OrgIdAsc"] = "ORG_ID_ASC";
    CredentialsOrderBy["OrgIdDesc"] = "ORG_ID_DESC";
    CredentialsOrderBy["SpaceIdAsc"] = "SPACE_ID_ASC";
    CredentialsOrderBy["SpaceIdDesc"] = "SPACE_ID_DESC";
    CredentialsOrderBy["DescriptionAsc"] = "DESCRIPTION_ASC";
    CredentialsOrderBy["DescriptionDesc"] = "DESCRIPTION_DESC";
    CredentialsOrderBy["UsernameAsc"] = "USERNAME_ASC";
    CredentialsOrderBy["UsernameDesc"] = "USERNAME_DESC";
    CredentialsOrderBy["ProviderAsc"] = "PROVIDER_ASC";
    CredentialsOrderBy["ProviderDesc"] = "PROVIDER_DESC";
    CredentialsOrderBy["CredentialKeyAsc"] = "CREDENTIAL_KEY_ASC";
    CredentialsOrderBy["CredentialKeyDesc"] = "CREDENTIAL_KEY_DESC";
    CredentialsOrderBy["CreatedByAsc"] = "CREATED_BY_ASC";
    CredentialsOrderBy["CreatedByDesc"] = "CREATED_BY_DESC";
    CredentialsOrderBy["CreatedAtAsc"] = "CREATED_AT_ASC";
    CredentialsOrderBy["CreatedAtDesc"] = "CREATED_AT_DESC";
    CredentialsOrderBy["PrimaryKeyAsc"] = "PRIMARY_KEY_ASC";
    CredentialsOrderBy["PrimaryKeyDesc"] = "PRIMARY_KEY_DESC";
})(CredentialsOrderBy || (CredentialsOrderBy = {}));
/** Methods to use when ordering `Token`. */
export var TokensOrderBy;
(function (TokensOrderBy) {
    TokensOrderBy["Natural"] = "NATURAL";
    TokensOrderBy["IdAsc"] = "ID_ASC";
    TokensOrderBy["IdDesc"] = "ID_DESC";
    TokensOrderBy["OrgIdAsc"] = "ORG_ID_ASC";
    TokensOrderBy["OrgIdDesc"] = "ORG_ID_DESC";
    TokensOrderBy["UserIdAsc"] = "USER_ID_ASC";
    TokensOrderBy["UserIdDesc"] = "USER_ID_DESC";
    TokensOrderBy["NameAsc"] = "NAME_ASC";
    TokensOrderBy["NameDesc"] = "NAME_DESC";
    TokensOrderBy["PermissionsAsc"] = "PERMISSIONS_ASC";
    TokensOrderBy["PermissionsDesc"] = "PERMISSIONS_DESC";
    TokensOrderBy["PermissionSetsAsc"] = "PERMISSION_SETS_ASC";
    TokensOrderBy["PermissionSetsDesc"] = "PERMISSION_SETS_DESC";
    TokensOrderBy["LastUsedAsc"] = "LAST_USED_ASC";
    TokensOrderBy["LastUsedDesc"] = "LAST_USED_DESC";
    TokensOrderBy["DeactivatedAtAsc"] = "DEACTIVATED_AT_ASC";
    TokensOrderBy["DeactivatedAtDesc"] = "DEACTIVATED_AT_DESC";
    TokensOrderBy["CreatedAtAsc"] = "CREATED_AT_ASC";
    TokensOrderBy["CreatedAtDesc"] = "CREATED_AT_DESC";
    TokensOrderBy["UpdatedAtAsc"] = "UPDATED_AT_ASC";
    TokensOrderBy["UpdatedAtDesc"] = "UPDATED_AT_DESC";
    TokensOrderBy["DeletedAtAsc"] = "DELETED_AT_ASC";
    TokensOrderBy["DeletedAtDesc"] = "DELETED_AT_DESC";
    TokensOrderBy["CreatedByAsc"] = "CREATED_BY_ASC";
    TokensOrderBy["CreatedByDesc"] = "CREATED_BY_DESC";
    TokensOrderBy["UpdatedByAsc"] = "UPDATED_BY_ASC";
    TokensOrderBy["UpdatedByDesc"] = "UPDATED_BY_DESC";
    TokensOrderBy["PrimaryKeyAsc"] = "PRIMARY_KEY_ASC";
    TokensOrderBy["PrimaryKeyDesc"] = "PRIMARY_KEY_DESC";
})(TokensOrderBy || (TokensOrderBy = {}));
/** Methods to use when ordering `OrgConnection`. */
export var OrgConnectionsOrderBy;
(function (OrgConnectionsOrderBy) {
    OrgConnectionsOrderBy["Natural"] = "NATURAL";
    OrgConnectionsOrderBy["IdAsc"] = "ID_ASC";
    OrgConnectionsOrderBy["IdDesc"] = "ID_DESC";
    OrgConnectionsOrderBy["OrgIdAsc"] = "ORG_ID_ASC";
    OrgConnectionsOrderBy["OrgIdDesc"] = "ORG_ID_DESC";
    OrgConnectionsOrderBy["ProviderAsc"] = "PROVIDER_ASC";
    OrgConnectionsOrderBy["ProviderDesc"] = "PROVIDER_DESC";
    OrgConnectionsOrderBy["ConnectionAsc"] = "CONNECTION_ASC";
    OrgConnectionsOrderBy["ConnectionDesc"] = "CONNECTION_DESC";
    OrgConnectionsOrderBy["RequiredAsc"] = "REQUIRED_ASC";
    OrgConnectionsOrderBy["RequiredDesc"] = "REQUIRED_DESC";
    OrgConnectionsOrderBy["AutomaticEnrollAsc"] = "AUTOMATIC_ENROLL_ASC";
    OrgConnectionsOrderBy["AutomaticEnrollDesc"] = "AUTOMATIC_ENROLL_DESC";
    OrgConnectionsOrderBy["CreatedAtAsc"] = "CREATED_AT_ASC";
    OrgConnectionsOrderBy["CreatedAtDesc"] = "CREATED_AT_DESC";
    OrgConnectionsOrderBy["CreatedByAsc"] = "CREATED_BY_ASC";
    OrgConnectionsOrderBy["CreatedByDesc"] = "CREATED_BY_DESC";
    OrgConnectionsOrderBy["UpdatedAtAsc"] = "UPDATED_AT_ASC";
    OrgConnectionsOrderBy["UpdatedAtDesc"] = "UPDATED_AT_DESC";
    OrgConnectionsOrderBy["UpdatedByAsc"] = "UPDATED_BY_ASC";
    OrgConnectionsOrderBy["UpdatedByDesc"] = "UPDATED_BY_DESC";
    OrgConnectionsOrderBy["PrimaryKeyAsc"] = "PRIMARY_KEY_ASC";
    OrgConnectionsOrderBy["PrimaryKeyDesc"] = "PRIMARY_KEY_DESC";
})(OrgConnectionsOrderBy || (OrgConnectionsOrderBy = {}));
/** Methods to use when ordering `SystemAccess`. */
export var SystemAccessesOrderBy;
(function (SystemAccessesOrderBy) {
    SystemAccessesOrderBy["Natural"] = "NATURAL";
    SystemAccessesOrderBy["IdAsc"] = "ID_ASC";
    SystemAccessesOrderBy["IdDesc"] = "ID_DESC";
    SystemAccessesOrderBy["OrgIdAsc"] = "ORG_ID_ASC";
    SystemAccessesOrderBy["OrgIdDesc"] = "ORG_ID_DESC";
    SystemAccessesOrderBy["StatusAsc"] = "STATUS_ASC";
    SystemAccessesOrderBy["StatusDesc"] = "STATUS_DESC";
    SystemAccessesOrderBy["TargetsAsc"] = "TARGETS_ASC";
    SystemAccessesOrderBy["TargetsDesc"] = "TARGETS_DESC";
    SystemAccessesOrderBy["ReasonAsc"] = "REASON_ASC";
    SystemAccessesOrderBy["ReasonDesc"] = "REASON_DESC";
    SystemAccessesOrderBy["ZendeskTicketIdAsc"] = "ZENDESK_TICKET_ID_ASC";
    SystemAccessesOrderBy["ZendeskTicketIdDesc"] = "ZENDESK_TICKET_ID_DESC";
    SystemAccessesOrderBy["CreatedAtAsc"] = "CREATED_AT_ASC";
    SystemAccessesOrderBy["CreatedAtDesc"] = "CREATED_AT_DESC";
    SystemAccessesOrderBy["CreatedByAsc"] = "CREATED_BY_ASC";
    SystemAccessesOrderBy["CreatedByDesc"] = "CREATED_BY_DESC";
    SystemAccessesOrderBy["UpdatedAtAsc"] = "UPDATED_AT_ASC";
    SystemAccessesOrderBy["UpdatedAtDesc"] = "UPDATED_AT_DESC";
    SystemAccessesOrderBy["UpdatedByAsc"] = "UPDATED_BY_ASC";
    SystemAccessesOrderBy["UpdatedByDesc"] = "UPDATED_BY_DESC";
    SystemAccessesOrderBy["PrimaryKeyAsc"] = "PRIMARY_KEY_ASC";
    SystemAccessesOrderBy["PrimaryKeyDesc"] = "PRIMARY_KEY_DESC";
})(SystemAccessesOrderBy || (SystemAccessesOrderBy = {}));
export var SystemAccessStatus;
(function (SystemAccessStatus) {
    SystemAccessStatus["Pending"] = "PENDING";
    SystemAccessStatus["Active"] = "ACTIVE";
    SystemAccessStatus["Disabled"] = "DISABLED";
    SystemAccessStatus["Archived"] = "ARCHIVED";
})(SystemAccessStatus || (SystemAccessStatus = {}));
export var SystemAccessTarget;
(function (SystemAccessTarget) {
    SystemAccessTarget["Support"] = "SUPPORT";
    SystemAccessTarget["Sales"] = "SALES";
    SystemAccessTarget["ProfessionalServices"] = "PROFESSIONAL_SERVICES";
    SystemAccessTarget["Marketing"] = "MARKETING";
    SystemAccessTarget["Development"] = "DEVELOPMENT";
    SystemAccessTarget["Finance"] = "FINANCE";
    SystemAccessTarget["Product"] = "PRODUCT";
})(SystemAccessTarget || (SystemAccessTarget = {}));
/** Methods to use when ordering `AppAccess`. */
export var AppAccessesOrderBy;
(function (AppAccessesOrderBy) {
    AppAccessesOrderBy["Natural"] = "NATURAL";
    AppAccessesOrderBy["IdAsc"] = "ID_ASC";
    AppAccessesOrderBy["IdDesc"] = "ID_DESC";
    AppAccessesOrderBy["AppInstallIdAsc"] = "APP_INSTALL_ID_ASC";
    AppAccessesOrderBy["AppInstallIdDesc"] = "APP_INSTALL_ID_DESC";
    AppAccessesOrderBy["OrgIdAsc"] = "ORG_ID_ASC";
    AppAccessesOrderBy["OrgIdDesc"] = "ORG_ID_DESC";
    AppAccessesOrderBy["SpaceIdsAsc"] = "SPACE_IDS_ASC";
    AppAccessesOrderBy["SpaceIdsDesc"] = "SPACE_IDS_DESC";
    AppAccessesOrderBy["ServicesAsc"] = "SERVICES_ASC";
    AppAccessesOrderBy["ServicesDesc"] = "SERVICES_DESC";
    AppAccessesOrderBy["DataAsc"] = "DATA_ASC";
    AppAccessesOrderBy["DataDesc"] = "DATA_DESC";
    AppAccessesOrderBy["CreatedAtAsc"] = "CREATED_AT_ASC";
    AppAccessesOrderBy["CreatedAtDesc"] = "CREATED_AT_DESC";
    AppAccessesOrderBy["UpdatedAtAsc"] = "UPDATED_AT_ASC";
    AppAccessesOrderBy["UpdatedAtDesc"] = "UPDATED_AT_DESC";
    AppAccessesOrderBy["CreatedByAsc"] = "CREATED_BY_ASC";
    AppAccessesOrderBy["CreatedByDesc"] = "CREATED_BY_DESC";
    AppAccessesOrderBy["UpdatedByAsc"] = "UPDATED_BY_ASC";
    AppAccessesOrderBy["UpdatedByDesc"] = "UPDATED_BY_DESC";
    AppAccessesOrderBy["PrimaryKeyAsc"] = "PRIMARY_KEY_ASC";
    AppAccessesOrderBy["PrimaryKeyDesc"] = "PRIMARY_KEY_DESC";
})(AppAccessesOrderBy || (AppAccessesOrderBy = {}));
/** Methods to use when ordering `PermissionSet`. */
export var PermissionSetsOrderBy;
(function (PermissionSetsOrderBy) {
    PermissionSetsOrderBy["Natural"] = "NATURAL";
    PermissionSetsOrderBy["IdAsc"] = "ID_ASC";
    PermissionSetsOrderBy["IdDesc"] = "ID_DESC";
    PermissionSetsOrderBy["OrgIdAsc"] = "ORG_ID_ASC";
    PermissionSetsOrderBy["OrgIdDesc"] = "ORG_ID_DESC";
    PermissionSetsOrderBy["CommonPermissionSetIdAsc"] = "COMMON_PERMISSION_SET_ID_ASC";
    PermissionSetsOrderBy["CommonPermissionSetIdDesc"] = "COMMON_PERMISSION_SET_ID_DESC";
    PermissionSetsOrderBy["NameAsc"] = "NAME_ASC";
    PermissionSetsOrderBy["NameDesc"] = "NAME_DESC";
    PermissionSetsOrderBy["ColorAsc"] = "COLOR_ASC";
    PermissionSetsOrderBy["ColorDesc"] = "COLOR_DESC";
    PermissionSetsOrderBy["DescriptionAsc"] = "DESCRIPTION_ASC";
    PermissionSetsOrderBy["DescriptionDesc"] = "DESCRIPTION_DESC";
    PermissionSetsOrderBy["PermissionsAsc"] = "PERMISSIONS_ASC";
    PermissionSetsOrderBy["PermissionsDesc"] = "PERMISSIONS_DESC";
})(PermissionSetsOrderBy || (PermissionSetsOrderBy = {}));
/** Methods to use when ordering `GroupCombination`. */
export var GroupCombinationsOrderBy;
(function (GroupCombinationsOrderBy) {
    GroupCombinationsOrderBy["Natural"] = "NATURAL";
    GroupCombinationsOrderBy["IdAsc"] = "ID_ASC";
    GroupCombinationsOrderBy["IdDesc"] = "ID_DESC";
    GroupCombinationsOrderBy["OrgIdAsc"] = "ORG_ID_ASC";
    GroupCombinationsOrderBy["OrgIdDesc"] = "ORG_ID_DESC";
    GroupCombinationsOrderBy["GroupIdsAsc"] = "GROUP_IDS_ASC";
    GroupCombinationsOrderBy["GroupIdsDesc"] = "GROUP_IDS_DESC";
    GroupCombinationsOrderBy["PrimaryKeyAsc"] = "PRIMARY_KEY_ASC";
    GroupCombinationsOrderBy["PrimaryKeyDesc"] = "PRIMARY_KEY_DESC";
})(GroupCombinationsOrderBy || (GroupCombinationsOrderBy = {}));
/** Methods to use when ordering `FeatureFlagOrg`. */
export var FeatureFlagOrgsOrderBy;
(function (FeatureFlagOrgsOrderBy) {
    FeatureFlagOrgsOrderBy["Natural"] = "NATURAL";
    FeatureFlagOrgsOrderBy["KeyAsc"] = "KEY_ASC";
    FeatureFlagOrgsOrderBy["KeyDesc"] = "KEY_DESC";
    FeatureFlagOrgsOrderBy["OrgIdAsc"] = "ORG_ID_ASC";
    FeatureFlagOrgsOrderBy["OrgIdDesc"] = "ORG_ID_DESC";
    FeatureFlagOrgsOrderBy["ValueAsc"] = "VALUE_ASC";
    FeatureFlagOrgsOrderBy["ValueDesc"] = "VALUE_DESC";
    FeatureFlagOrgsOrderBy["CustomValueAsc"] = "CUSTOM_VALUE_ASC";
    FeatureFlagOrgsOrderBy["CustomValueDesc"] = "CUSTOM_VALUE_DESC";
    FeatureFlagOrgsOrderBy["CustomizableAsc"] = "CUSTOMIZABLE_ASC";
    FeatureFlagOrgsOrderBy["CustomizableDesc"] = "CUSTOMIZABLE_DESC";
    FeatureFlagOrgsOrderBy["PrimaryKeyAsc"] = "PRIMARY_KEY_ASC";
    FeatureFlagOrgsOrderBy["PrimaryKeyDesc"] = "PRIMARY_KEY_DESC";
})(FeatureFlagOrgsOrderBy || (FeatureFlagOrgsOrderBy = {}));
export var FeatureFlagVisibility;
(function (FeatureFlagVisibility) {
    FeatureFlagVisibility["Public"] = "PUBLIC";
    FeatureFlagVisibility["Private"] = "PRIVATE";
    FeatureFlagVisibility["Internal"] = "INTERNAL";
})(FeatureFlagVisibility || (FeatureFlagVisibility = {}));
export var FeatureFlagTags;
(function (FeatureFlagTags) {
    FeatureFlagTags["Beta"] = "BETA";
    FeatureFlagTags["Deprecated"] = "DEPRECATED";
    FeatureFlagTags["Experimental"] = "EXPERIMENTAL";
    FeatureFlagTags["Internal"] = "INTERNAL";
    FeatureFlagTags["Maintenance"] = "MAINTENANCE";
})(FeatureFlagTags || (FeatureFlagTags = {}));
/** Methods to use when ordering `BillingDistributor`. */
export var BillingDistributorsOrderBy;
(function (BillingDistributorsOrderBy) {
    BillingDistributorsOrderBy["Natural"] = "NATURAL";
    BillingDistributorsOrderBy["IdAsc"] = "ID_ASC";
    BillingDistributorsOrderBy["IdDesc"] = "ID_DESC";
    BillingDistributorsOrderBy["ParentIdAsc"] = "PARENT_ID_ASC";
    BillingDistributorsOrderBy["ParentIdDesc"] = "PARENT_ID_DESC";
    BillingDistributorsOrderBy["PaymentOwnerIdAsc"] = "PAYMENT_OWNER_ID_ASC";
    BillingDistributorsOrderBy["PaymentOwnerIdDesc"] = "PAYMENT_OWNER_ID_DESC";
    BillingDistributorsOrderBy["InvoiceOwnerIdAsc"] = "INVOICE_OWNER_ID_ASC";
    BillingDistributorsOrderBy["InvoiceOwnerIdDesc"] = "INVOICE_OWNER_ID_DESC";
    BillingDistributorsOrderBy["CompanyAsc"] = "COMPANY_ASC";
    BillingDistributorsOrderBy["CompanyDesc"] = "COMPANY_DESC";
    BillingDistributorsOrderBy["FirstNameAsc"] = "FIRST_NAME_ASC";
    BillingDistributorsOrderBy["FirstNameDesc"] = "FIRST_NAME_DESC";
    BillingDistributorsOrderBy["LastNameAsc"] = "LAST_NAME_ASC";
    BillingDistributorsOrderBy["LastNameDesc"] = "LAST_NAME_DESC";
    BillingDistributorsOrderBy["EmailAsc"] = "EMAIL_ASC";
    BillingDistributorsOrderBy["EmailDesc"] = "EMAIL_DESC";
    BillingDistributorsOrderBy["PhoneAsc"] = "PHONE_ASC";
    BillingDistributorsOrderBy["PhoneDesc"] = "PHONE_DESC";
    BillingDistributorsOrderBy["Line1Asc"] = "LINE1_ASC";
    BillingDistributorsOrderBy["Line1Desc"] = "LINE1_DESC";
    BillingDistributorsOrderBy["Line2Asc"] = "LINE2_ASC";
    BillingDistributorsOrderBy["Line2Desc"] = "LINE2_DESC";
    BillingDistributorsOrderBy["Line3Asc"] = "LINE3_ASC";
    BillingDistributorsOrderBy["Line3Desc"] = "LINE3_DESC";
    BillingDistributorsOrderBy["CityAsc"] = "CITY_ASC";
    BillingDistributorsOrderBy["CityDesc"] = "CITY_DESC";
    BillingDistributorsOrderBy["StateCodeAsc"] = "STATE_CODE_ASC";
    BillingDistributorsOrderBy["StateCodeDesc"] = "STATE_CODE_DESC";
    BillingDistributorsOrderBy["StateAsc"] = "STATE_ASC";
    BillingDistributorsOrderBy["StateDesc"] = "STATE_DESC";
    BillingDistributorsOrderBy["CountryAsc"] = "COUNTRY_ASC";
    BillingDistributorsOrderBy["CountryDesc"] = "COUNTRY_DESC";
    BillingDistributorsOrderBy["ZipAsc"] = "ZIP_ASC";
    BillingDistributorsOrderBy["ZipDesc"] = "ZIP_DESC";
    BillingDistributorsOrderBy["DeletedAsc"] = "DELETED_ASC";
    BillingDistributorsOrderBy["DeletedDesc"] = "DELETED_DESC";
    BillingDistributorsOrderBy["CfCloseIoIdAsc"] = "CF_CLOSE_IO_ID_ASC";
    BillingDistributorsOrderBy["CfCloseIoIdDesc"] = "CF_CLOSE_IO_ID_DESC";
    BillingDistributorsOrderBy["CfCustomerTypeAsc"] = "CF_CUSTOMER_TYPE_ASC";
    BillingDistributorsOrderBy["CfCustomerTypeDesc"] = "CF_CUSTOMER_TYPE_DESC";
    BillingDistributorsOrderBy["CfSalesAgentAsc"] = "CF_SALES_AGENT_ASC";
    BillingDistributorsOrderBy["CfSalesAgentDesc"] = "CF_SALES_AGENT_DESC";
    BillingDistributorsOrderBy["CfAllowLicenceAsc"] = "CF_ALLOW_LICENCE_ASC";
    BillingDistributorsOrderBy["CfAllowLicenceDesc"] = "CF_ALLOW_LICENCE_DESC";
    BillingDistributorsOrderBy["CfPrimaryAsc"] = "CF_PRIMARY_ASC";
    BillingDistributorsOrderBy["CfPrimaryDesc"] = "CF_PRIMARY_DESC";
    BillingDistributorsOrderBy["CfPartnerAsc"] = "CF_PARTNER_ASC";
    BillingDistributorsOrderBy["CfPartnerDesc"] = "CF_PARTNER_DESC";
    BillingDistributorsOrderBy["CreatedAtAsc"] = "CREATED_AT_ASC";
    BillingDistributorsOrderBy["CreatedAtDesc"] = "CREATED_AT_DESC";
    BillingDistributorsOrderBy["UpdatedAtAsc"] = "UPDATED_AT_ASC";
    BillingDistributorsOrderBy["UpdatedAtDesc"] = "UPDATED_AT_DESC";
    BillingDistributorsOrderBy["PrimaryKeyAsc"] = "PRIMARY_KEY_ASC";
    BillingDistributorsOrderBy["PrimaryKeyDesc"] = "PRIMARY_KEY_DESC";
})(BillingDistributorsOrderBy || (BillingDistributorsOrderBy = {}));
/** Methods to use when ordering `BillingPlan`. */
export var BillingPlansOrderBy;
(function (BillingPlansOrderBy) {
    BillingPlansOrderBy["Natural"] = "NATURAL";
    BillingPlansOrderBy["IdAsc"] = "ID_ASC";
    BillingPlansOrderBy["IdDesc"] = "ID_DESC";
    BillingPlansOrderBy["NameAsc"] = "NAME_ASC";
    BillingPlansOrderBy["NameDesc"] = "NAME_DESC";
    BillingPlansOrderBy["DescriptionAsc"] = "DESCRIPTION_ASC";
    BillingPlansOrderBy["DescriptionDesc"] = "DESCRIPTION_DESC";
    BillingPlansOrderBy["PriceAsc"] = "PRICE_ASC";
    BillingPlansOrderBy["PriceDesc"] = "PRICE_DESC";
    BillingPlansOrderBy["CurrencyCodeAsc"] = "CURRENCY_CODE_ASC";
    BillingPlansOrderBy["CurrencyCodeDesc"] = "CURRENCY_CODE_DESC";
    BillingPlansOrderBy["PeriodAsc"] = "PERIOD_ASC";
    BillingPlansOrderBy["PeriodDesc"] = "PERIOD_DESC";
    BillingPlansOrderBy["PeriodUnitAsc"] = "PERIOD_UNIT_ASC";
    BillingPlansOrderBy["PeriodUnitDesc"] = "PERIOD_UNIT_DESC";
    BillingPlansOrderBy["TrialPeriodAsc"] = "TRIAL_PERIOD_ASC";
    BillingPlansOrderBy["TrialPeriodDesc"] = "TRIAL_PERIOD_DESC";
    BillingPlansOrderBy["TrialPeriodUnitAsc"] = "TRIAL_PERIOD_UNIT_ASC";
    BillingPlansOrderBy["TrialPeriodUnitDesc"] = "TRIAL_PERIOD_UNIT_DESC";
    BillingPlansOrderBy["PricingModelAsc"] = "PRICING_MODEL_ASC";
    BillingPlansOrderBy["PricingModelDesc"] = "PRICING_MODEL_DESC";
    BillingPlansOrderBy["FreeQuantityAsc"] = "FREE_QUANTITY_ASC";
    BillingPlansOrderBy["FreeQuantityDesc"] = "FREE_QUANTITY_DESC";
    BillingPlansOrderBy["StatusAsc"] = "STATUS_ASC";
    BillingPlansOrderBy["StatusDesc"] = "STATUS_DESC";
    BillingPlansOrderBy["BillingCyclesAsc"] = "BILLING_CYCLES_ASC";
    BillingPlansOrderBy["BillingCyclesDesc"] = "BILLING_CYCLES_DESC";
    BillingPlansOrderBy["CfSubscriptionTypeAsc"] = "CF_SUBSCRIPTION_TYPE_ASC";
    BillingPlansOrderBy["CfSubscriptionTypeDesc"] = "CF_SUBSCRIPTION_TYPE_DESC";
    BillingPlansOrderBy["CfIsLegacyAsc"] = "CF_IS_LEGACY_ASC";
    BillingPlansOrderBy["CfIsLegacyDesc"] = "CF_IS_LEGACY_DESC";
    BillingPlansOrderBy["CfEnterpriseAsc"] = "CF_ENTERPRISE_ASC";
    BillingPlansOrderBy["CfEnterpriseDesc"] = "CF_ENTERPRISE_DESC";
    BillingPlansOrderBy["CfSpecialAsc"] = "CF_SPECIAL_ASC";
    BillingPlansOrderBy["CfSpecialDesc"] = "CF_SPECIAL_DESC";
    BillingPlansOrderBy["PrimaryKeyAsc"] = "PRIMARY_KEY_ASC";
    BillingPlansOrderBy["PrimaryKeyDesc"] = "PRIMARY_KEY_DESC";
})(BillingPlansOrderBy || (BillingPlansOrderBy = {}));
/** Methods to use when ordering `CommonFileToDelete`. */
export var CommonFileToDeletesOrderBy;
(function (CommonFileToDeletesOrderBy) {
    CommonFileToDeletesOrderBy["Natural"] = "NATURAL";
    CommonFileToDeletesOrderBy["IdAsc"] = "ID_ASC";
    CommonFileToDeletesOrderBy["IdDesc"] = "ID_DESC";
    CommonFileToDeletesOrderBy["FileIdAsc"] = "FILE_ID_ASC";
    CommonFileToDeletesOrderBy["FileIdDesc"] = "FILE_ID_DESC";
    CommonFileToDeletesOrderBy["NameAsc"] = "NAME_ASC";
    CommonFileToDeletesOrderBy["NameDesc"] = "NAME_DESC";
    CommonFileToDeletesOrderBy["MimetypeAsc"] = "MIMETYPE_ASC";
    CommonFileToDeletesOrderBy["MimetypeDesc"] = "MIMETYPE_DESC";
    CommonFileToDeletesOrderBy["SizeAsc"] = "SIZE_ASC";
    CommonFileToDeletesOrderBy["SizeDesc"] = "SIZE_DESC";
    CommonFileToDeletesOrderBy["SourceAsc"] = "SOURCE_ASC";
    CommonFileToDeletesOrderBy["SourceDesc"] = "SOURCE_DESC";
    CommonFileToDeletesOrderBy["ErrorAsc"] = "ERROR_ASC";
    CommonFileToDeletesOrderBy["ErrorDesc"] = "ERROR_DESC";
    CommonFileToDeletesOrderBy["MetadataAsc"] = "METADATA_ASC";
    CommonFileToDeletesOrderBy["MetadataDesc"] = "METADATA_DESC";
    CommonFileToDeletesOrderBy["CreatedAtAsc"] = "CREATED_AT_ASC";
    CommonFileToDeletesOrderBy["CreatedAtDesc"] = "CREATED_AT_DESC";
    CommonFileToDeletesOrderBy["DeletedAtAsc"] = "DELETED_AT_ASC";
    CommonFileToDeletesOrderBy["DeletedAtDesc"] = "DELETED_AT_DESC";
})(CommonFileToDeletesOrderBy || (CommonFileToDeletesOrderBy = {}));
/** Methods to use when ordering `CommonLayout`. */
export var CommonLayoutsOrderBy;
(function (CommonLayoutsOrderBy) {
    CommonLayoutsOrderBy["Natural"] = "NATURAL";
    CommonLayoutsOrderBy["IdAsc"] = "ID_ASC";
    CommonLayoutsOrderBy["IdDesc"] = "ID_DESC";
    CommonLayoutsOrderBy["NameAsc"] = "NAME_ASC";
    CommonLayoutsOrderBy["NameDesc"] = "NAME_DESC";
    CommonLayoutsOrderBy["WidthAsc"] = "WIDTH_ASC";
    CommonLayoutsOrderBy["WidthDesc"] = "WIDTH_DESC";
    CommonLayoutsOrderBy["HeightAsc"] = "HEIGHT_ASC";
    CommonLayoutsOrderBy["HeightDesc"] = "HEIGHT_DESC";
    CommonLayoutsOrderBy["IsFlexibleAsc"] = "IS_FLEXIBLE_ASC";
    CommonLayoutsOrderBy["IsFlexibleDesc"] = "IS_FLEXIBLE_DESC";
    CommonLayoutsOrderBy["IsScalableAsc"] = "IS_SCALABLE_ASC";
    CommonLayoutsOrderBy["IsScalableDesc"] = "IS_SCALABLE_DESC";
    CommonLayoutsOrderBy["ZonesAsc"] = "ZONES_ASC";
    CommonLayoutsOrderBy["ZonesDesc"] = "ZONES_DESC";
    CommonLayoutsOrderBy["ConfigAsc"] = "CONFIG_ASC";
    CommonLayoutsOrderBy["ConfigDesc"] = "CONFIG_DESC";
    CommonLayoutsOrderBy["CreatedAtAsc"] = "CREATED_AT_ASC";
    CommonLayoutsOrderBy["CreatedAtDesc"] = "CREATED_AT_DESC";
    CommonLayoutsOrderBy["UpdatedAtAsc"] = "UPDATED_AT_ASC";
    CommonLayoutsOrderBy["UpdatedAtDesc"] = "UPDATED_AT_DESC";
    CommonLayoutsOrderBy["PrimaryKeyAsc"] = "PRIMARY_KEY_ASC";
    CommonLayoutsOrderBy["PrimaryKeyDesc"] = "PRIMARY_KEY_DESC";
})(CommonLayoutsOrderBy || (CommonLayoutsOrderBy = {}));
/** Methods to use when ordering `CommonPermissionSet`. */
export var CommonPermissionSetsOrderBy;
(function (CommonPermissionSetsOrderBy) {
    CommonPermissionSetsOrderBy["Natural"] = "NATURAL";
    CommonPermissionSetsOrderBy["IdAsc"] = "ID_ASC";
    CommonPermissionSetsOrderBy["IdDesc"] = "ID_DESC";
    CommonPermissionSetsOrderBy["NameAsc"] = "NAME_ASC";
    CommonPermissionSetsOrderBy["NameDesc"] = "NAME_DESC";
    CommonPermissionSetsOrderBy["ColorAsc"] = "COLOR_ASC";
    CommonPermissionSetsOrderBy["ColorDesc"] = "COLOR_DESC";
    CommonPermissionSetsOrderBy["DescriptionAsc"] = "DESCRIPTION_ASC";
    CommonPermissionSetsOrderBy["DescriptionDesc"] = "DESCRIPTION_DESC";
    CommonPermissionSetsOrderBy["PermissionsAsc"] = "PERMISSIONS_ASC";
    CommonPermissionSetsOrderBy["PermissionsDesc"] = "PERMISSIONS_DESC";
    CommonPermissionSetsOrderBy["IsDefaultProvidedAsc"] = "IS_DEFAULT_PROVIDED_ASC";
    CommonPermissionSetsOrderBy["IsDefaultProvidedDesc"] = "IS_DEFAULT_PROVIDED_DESC";
    CommonPermissionSetsOrderBy["IsDefaultProvidedAsGroupAsc"] = "IS_DEFAULT_PROVIDED_AS_GROUP_ASC";
    CommonPermissionSetsOrderBy["IsDefaultProvidedAsGroupDesc"] = "IS_DEFAULT_PROVIDED_AS_GROUP_DESC";
    CommonPermissionSetsOrderBy["PrimaryKeyAsc"] = "PRIMARY_KEY_ASC";
    CommonPermissionSetsOrderBy["PrimaryKeyDesc"] = "PRIMARY_KEY_DESC";
})(CommonPermissionSetsOrderBy || (CommonPermissionSetsOrderBy = {}));
/** Methods to use when ordering `FeatureFlag`. */
export var FeatureFlagsOrderBy;
(function (FeatureFlagsOrderBy) {
    FeatureFlagsOrderBy["Natural"] = "NATURAL";
    FeatureFlagsOrderBy["KeyAsc"] = "KEY_ASC";
    FeatureFlagsOrderBy["KeyDesc"] = "KEY_DESC";
    FeatureFlagsOrderBy["ValueAsc"] = "VALUE_ASC";
    FeatureFlagsOrderBy["ValueDesc"] = "VALUE_DESC";
    FeatureFlagsOrderBy["OverwriteValueAsc"] = "OVERWRITE_VALUE_ASC";
    FeatureFlagsOrderBy["OverwriteValueDesc"] = "OVERWRITE_VALUE_DESC";
    FeatureFlagsOrderBy["DefaultCustomizableAsc"] = "DEFAULT_CUSTOMIZABLE_ASC";
    FeatureFlagsOrderBy["DefaultCustomizableDesc"] = "DEFAULT_CUSTOMIZABLE_DESC";
    FeatureFlagsOrderBy["VisibilityAsc"] = "VISIBILITY_ASC";
    FeatureFlagsOrderBy["VisibilityDesc"] = "VISIBILITY_DESC";
    FeatureFlagsOrderBy["TagsAsc"] = "TAGS_ASC";
    FeatureFlagsOrderBy["TagsDesc"] = "TAGS_DESC";
    FeatureFlagsOrderBy["CreatedAtAsc"] = "CREATED_AT_ASC";
    FeatureFlagsOrderBy["CreatedAtDesc"] = "CREATED_AT_DESC";
    FeatureFlagsOrderBy["UpdatedAtAsc"] = "UPDATED_AT_ASC";
    FeatureFlagsOrderBy["UpdatedAtDesc"] = "UPDATED_AT_DESC";
    FeatureFlagsOrderBy["PrimaryKeyAsc"] = "PRIMARY_KEY_ASC";
    FeatureFlagsOrderBy["PrimaryKeyDesc"] = "PRIMARY_KEY_DESC";
})(FeatureFlagsOrderBy || (FeatureFlagsOrderBy = {}));
/** Methods to use when ordering `LegacyLog`. */
export var LegacyLogsOrderBy;
(function (LegacyLogsOrderBy) {
    LegacyLogsOrderBy["Natural"] = "NATURAL";
    LegacyLogsOrderBy["IdAsc"] = "ID_ASC";
    LegacyLogsOrderBy["IdDesc"] = "ID_DESC";
    LegacyLogsOrderBy["OrgIdAsc"] = "ORG_ID_ASC";
    LegacyLogsOrderBy["OrgIdDesc"] = "ORG_ID_DESC";
    LegacyLogsOrderBy["TeamIdAsc"] = "TEAM_ID_ASC";
    LegacyLogsOrderBy["TeamIdDesc"] = "TEAM_ID_DESC";
    LegacyLogsOrderBy["ActionAsc"] = "ACTION_ASC";
    LegacyLogsOrderBy["ActionDesc"] = "ACTION_DESC";
    LegacyLogsOrderBy["ObjectTypeAsc"] = "OBJECT_TYPE_ASC";
    LegacyLogsOrderBy["ObjectTypeDesc"] = "OBJECT_TYPE_DESC";
    LegacyLogsOrderBy["ObjectIdAsc"] = "OBJECT_ID_ASC";
    LegacyLogsOrderBy["ObjectIdDesc"] = "OBJECT_ID_DESC";
    LegacyLogsOrderBy["ExtraAsc"] = "EXTRA_ASC";
    LegacyLogsOrderBy["ExtraDesc"] = "EXTRA_DESC";
    LegacyLogsOrderBy["ActionedByAsc"] = "ACTIONED_BY_ASC";
    LegacyLogsOrderBy["ActionedByDesc"] = "ACTIONED_BY_DESC";
    LegacyLogsOrderBy["ActionedAtAsc"] = "ACTIONED_AT_ASC";
    LegacyLogsOrderBy["ActionedAtDesc"] = "ACTIONED_AT_DESC";
    LegacyLogsOrderBy["ClientAddrAsc"] = "CLIENT_ADDR_ASC";
    LegacyLogsOrderBy["ClientAddrDesc"] = "CLIENT_ADDR_DESC";
    LegacyLogsOrderBy["ClientPortAsc"] = "CLIENT_PORT_ASC";
    LegacyLogsOrderBy["ClientPortDesc"] = "CLIENT_PORT_DESC";
    LegacyLogsOrderBy["MsgAsc"] = "MSG_ASC";
    LegacyLogsOrderBy["MsgDesc"] = "MSG_DESC";
    LegacyLogsOrderBy["PrimaryKeyAsc"] = "PRIMARY_KEY_ASC";
    LegacyLogsOrderBy["PrimaryKeyDesc"] = "PRIMARY_KEY_DESC";
})(LegacyLogsOrderBy || (LegacyLogsOrderBy = {}));
/** Methods to use when ordering `Log`. */
export var LogsOrderBy;
(function (LogsOrderBy) {
    LogsOrderBy["Natural"] = "NATURAL";
    LogsOrderBy["IdAsc"] = "ID_ASC";
    LogsOrderBy["IdDesc"] = "ID_DESC";
    LogsOrderBy["OrgIdAsc"] = "ORG_ID_ASC";
    LogsOrderBy["OrgIdDesc"] = "ORG_ID_DESC";
    LogsOrderBy["ObjectTypeAsc"] = "OBJECT_TYPE_ASC";
    LogsOrderBy["ObjectTypeDesc"] = "OBJECT_TYPE_DESC";
    LogsOrderBy["ObjectIdAsc"] = "OBJECT_ID_ASC";
    LogsOrderBy["ObjectIdDesc"] = "OBJECT_ID_DESC";
    LogsOrderBy["ActionAsc"] = "ACTION_ASC";
    LogsOrderBy["ActionDesc"] = "ACTION_DESC";
    LogsOrderBy["ClientAddrAsc"] = "CLIENT_ADDR_ASC";
    LogsOrderBy["ClientAddrDesc"] = "CLIENT_ADDR_DESC";
    LogsOrderBy["ClientPortAsc"] = "CLIENT_PORT_ASC";
    LogsOrderBy["ClientPortDesc"] = "CLIENT_PORT_DESC";
    LogsOrderBy["ChangedFieldsAsc"] = "CHANGED_FIELDS_ASC";
    LogsOrderBy["ChangedFieldsDesc"] = "CHANGED_FIELDS_DESC";
    LogsOrderBy["PreviousFieldsAsc"] = "PREVIOUS_FIELDS_ASC";
    LogsOrderBy["PreviousFieldsDesc"] = "PREVIOUS_FIELDS_DESC";
    LogsOrderBy["SystemAccessIdAsc"] = "SYSTEM_ACCESS_ID_ASC";
    LogsOrderBy["SystemAccessIdDesc"] = "SYSTEM_ACCESS_ID_DESC";
    LogsOrderBy["UserIdAsc"] = "USER_ID_ASC";
    LogsOrderBy["UserIdDesc"] = "USER_ID_DESC";
    LogsOrderBy["ApplicationNameAsc"] = "APPLICATION_NAME_ASC";
    LogsOrderBy["ApplicationNameDesc"] = "APPLICATION_NAME_DESC";
    LogsOrderBy["TransactionIdAsc"] = "TRANSACTION_ID_ASC";
    LogsOrderBy["TransactionIdDesc"] = "TRANSACTION_ID_DESC";
    LogsOrderBy["SessionUserNameAsc"] = "SESSION_USER_NAME_ASC";
    LogsOrderBy["SessionUserNameDesc"] = "SESSION_USER_NAME_DESC";
    LogsOrderBy["RemoteAddressAsc"] = "REMOTE_ADDRESS_ASC";
    LogsOrderBy["RemoteAddressDesc"] = "REMOTE_ADDRESS_DESC";
    LogsOrderBy["TokenIdAsc"] = "TOKEN_ID_ASC";
    LogsOrderBy["TokenIdDesc"] = "TOKEN_ID_DESC";
    LogsOrderBy["RelidAsc"] = "RELID_ASC";
    LogsOrderBy["RelidDesc"] = "RELID_DESC";
    LogsOrderBy["TimestampAsc"] = "TIMESTAMP_ASC";
    LogsOrderBy["TimestampDesc"] = "TIMESTAMP_DESC";
    LogsOrderBy["SystemTokenIdAsc"] = "SYSTEM_TOKEN_ID_ASC";
    LogsOrderBy["SystemTokenIdDesc"] = "SYSTEM_TOKEN_ID_DESC";
    LogsOrderBy["SystemUserIdAsc"] = "SYSTEM_USER_ID_ASC";
    LogsOrderBy["SystemUserIdDesc"] = "SYSTEM_USER_ID_DESC";
    LogsOrderBy["PrimaryKeyAsc"] = "PRIMARY_KEY_ASC";
    LogsOrderBy["PrimaryKeyDesc"] = "PRIMARY_KEY_DESC";
})(LogsOrderBy || (LogsOrderBy = {}));
export var CanPairScreenResponseCode;
(function (CanPairScreenResponseCode) {
    CanPairScreenResponseCode["StudioCannotPairScreen"] = "STUDIO_CANNOT_PAIR_SCREEN";
    CanPairScreenResponseCode["BillingSubscriptionNotFound"] = "BILLING_SUBSCRIPTION_NOT_FOUND";
    CanPairScreenResponseCode["BillingSubscriptionExpired"] = "BILLING_SUBSCRIPTION_EXPIRED";
    CanPairScreenResponseCode["BillingPartnerLimitReached"] = "BILLING_PARTNER_LIMIT_REACHED";
    CanPairScreenResponseCode["BillingInTrialLimitReached"] = "BILLING_IN_TRIAL_LIMIT_REACHED";
    CanPairScreenResponseCode["BillingPaymentMissing"] = "BILLING_PAYMENT_MISSING";
    CanPairScreenResponseCode["Success"] = "SUCCESS";
})(CanPairScreenResponseCode || (CanPairScreenResponseCode = {}));
export var EmailStatus;
(function (EmailStatus) {
    EmailStatus["Pending"] = "PENDING";
    EmailStatus["Sent"] = "SENT";
    EmailStatus["Failed"] = "FAILED";
})(EmailStatus || (EmailStatus = {}));
export var ScreenContentType;
(function (ScreenContentType) {
    ScreenContentType["Channel"] = "CHANNEL";
    ScreenContentType["File"] = "FILE";
    ScreenContentType["App"] = "APP";
    ScreenContentType["Playlist"] = "PLAYLIST";
    ScreenContentType["Link"] = "LINK";
    ScreenContentType["Site"] = "SITE";
})(ScreenContentType || (ScreenContentType = {}));
export var AllChannelsDocument = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    query AllChannels($spaceId: UUID) {\n  allChannels(condition: {isEmbedEnabled: true, spaceId: $spaceId}) {\n    nodes {\n      id\n      orgId\n      spaceId\n      name\n      isEmbedPublic\n      height\n      width\n    }\n  }\n}\n    "], ["\n    query AllChannels($spaceId: UUID) {\n  allChannels(condition: {isEmbedEnabled: true, spaceId: $spaceId}) {\n    nodes {\n      id\n      orgId\n      spaceId\n      name\n      isEmbedPublic\n      height\n      width\n    }\n  }\n}\n    "])));
export var AllChannelsComponent = function (props) { return (React.createElement(ApolloReactComponents.Query, __assign({ query: AllChannelsDocument }, props))); };
export function withAllChannels(operationOptions) {
    return ApolloReactHoc.withQuery(AllChannelsDocument, __assign({ alias: 'allChannels' }, operationOptions));
}
;
/**
 * __useAllChannelsQuery__
 *
 * To run a query within a React component, call `useAllChannelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllChannelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllChannelsQuery({
 *   variables: {
 *      spaceId: // value for 'spaceId'
 *   },
 * });
 */
export function useAllChannelsQuery(baseOptions) {
    return ApolloReactHooks.useQuery(AllChannelsDocument, baseOptions);
}
export function useAllChannelsLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(AllChannelsDocument, baseOptions);
}
export var AllSpacesDocument = gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    query AllSpaces {\n  allSpaces {\n    nodes {\n      id\n      name\n    }\n  }\n}\n    "], ["\n    query AllSpaces {\n  allSpaces {\n    nodes {\n      id\n      name\n    }\n  }\n}\n    "])));
export var AllSpacesComponent = function (props) { return (React.createElement(ApolloReactComponents.Query, __assign({ query: AllSpacesDocument }, props))); };
export function withAllSpaces(operationOptions) {
    return ApolloReactHoc.withQuery(AllSpacesDocument, __assign({ alias: 'allSpaces' }, operationOptions));
}
;
/**
 * __useAllSpacesQuery__
 *
 * To run a query within a React component, call `useAllSpacesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllSpacesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllSpacesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllSpacesQuery(baseOptions) {
    return ApolloReactHooks.useQuery(AllSpacesDocument, baseOptions);
}
export function useAllSpacesLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(AllSpacesDocument, baseOptions);
}
var templateObject_1, templateObject_2;
