import React, { useEffect } from 'react';
import * as microsoftTeams from '@microsoft/teams-js';
import { TokenService } from '../../lib/TokenService';
import AuthLoading from './AuthLoading';
var AuthLoginEnd = function () {
    useEffect(function () {
        microsoftTeams.initialize();
        TokenService()
            .then(function (response) {
            if (response.success) {
                localStorage.removeItem('isLoggedOut');
                microsoftTeams.authentication.notifySuccess(JSON.stringify(response.accessTokens));
            }
            else {
                microsoftTeams.authentication.notifyFailure(response.reason);
            }
        }).catch(function (reason) {
            console.warn(reason);
        });
    }, []);
    return (React.createElement(AuthLoading, { theme: undefined, message: "Almost done..." }));
};
export default AuthLoginEnd;
