import React, { useState, useEffect } from 'react';
import './App.css';
import * as microsoftTeams from "@microsoft/teams-js";
import BasicLogin from './Authentication/BasicLogin';
import ChannelConfig from './ChannelConfig';
import Home from './Home';
import axios from 'axios';
import { appConfig } from '../appConfig';
import ScreenState from '../types/ScreenState';
import Region from '../types/Region';
var GroupTabConfig = function (props) {
    var _a = useState({ Id: '', IsPrivate: false, Name: '', OrgId: '', Width: 0, Height: 0, Region: Region.EU }), channelState = _a[0], setChannelState = _a[1];
    var _b = useState(''), spaceState = _b[0], setSpaceState = _b[1];
    var _c = useState(), contextState = _c[0], setContextState = _c[1];
    var _d = useState(), usernameState = _d[0], setUsernameState = _d[1];
    var _e = useState(), accessTokensState = _e[0], setAccessTokensState = _e[1];
    var instanceSettings = {
        entityId: "ScreenCloudTVEmbedded",
        contentUrl: appConfig.contentUrl + "/embedded?org=" + channelState.OrgId + "&channel=" + channelState.Id + "&isPrivate=" + channelState.IsPrivate + "&width=" + channelState.Width + "&height=" + channelState.Height + "&region=" + channelState.Region,
        suggestedDisplayName: channelState.Name
    };
    useEffect(function () {
        microsoftTeams.getContext(function (context) {
            setContextState(context);
        });
    }, []);
    useEffect(function () {
        var isValid = true;
        isValid = channelState.Id === '' ? false : true;
        microsoftTeams.settings.setValidityState(isValid);
    });
    microsoftTeams.settings.setSettings(instanceSettings);
    var _f = useState(ScreenState.HOME), screenState = _f[0], setScreenState = _f[1];
    function logoutHandler() {
        axios.post(appConfig.authUrl + "/api/v1/user/logout")
            .then(function (response) {
            if (response.status === 200) {
                setScreenState(ScreenState.HOME);
                setUsernameState(undefined);
            }
        })
            .catch(function (reason) {
            console.error("failed to log out");
        });
    }
    var content;
    if (screenState === ScreenState.HOME) {
        content = (React.createElement(Home, { logInWithMicrosoft: false, theme: props.theme, setScreenState: setScreenState, setAccessTokensState: setAccessTokensState }));
    }
    else if (screenState === ScreenState.LOGIN) {
        content = (React.createElement(BasicLogin, { theme: props.theme, setAccessTokenState: setAccessTokensState, setScreenState: setScreenState, setUsernameState: setUsernameState }));
    }
    else if (screenState === ScreenState.LOGGEDIN && contextState && accessTokensState) {
        content = (React.createElement(ChannelConfig, { accessTokens: accessTokensState, channel: channelState, space: spaceState, username: usernameState || contextState.loginHint || '', logoutHandler: logoutHandler, setChannelHandler: setChannelState, setSpaceHandler: setSpaceState }));
    }
    return (content);
};
export default GroupTabConfig;
