import React, { useEffect, useState } from 'react';
import { hasConnection, createConnection } from '../lib/GraphQL';
import * as microsoftTeams from '@microsoft/teams-js';
import { Alert } from '@fluentui/react-northstar';
var Connection = function (props) {
    var _a = useState(false), hasConnectionState = _a[0], setHasConnection = _a[1];
    useEffect(function () {
        hasConnection(props.accessToken)
            .then(function (result) {
            if (result.success) {
                setHasConnection(result.hasConnection);
            }
        });
    }, [props.accessToken, hasConnectionState]);
    var content = (React.createElement("div", null));
    var createConnectionHandler = function () {
        var _a;
        if ((_a = props.msContext) === null || _a === void 0 ? void 0 : _a.tid) {
            createConnection(props.accessToken, props.msContext.tid)
                .then(function (result) {
                if (result.success) {
                    setHasConnection(true);
                }
                else {
                    console.error(JSON.stringify(result.errors));
                }
            });
        }
    };
    var createUserAssociation = function () {
        microsoftTeams.authentication.authenticate({
            url: window.location.origin + "/azurelogin",
            height: 800,
            width: 600,
            successCallback: function (result) {
                props.setAccessTokensState(JSON.parse(result.accessTokens));
            },
            failureCallback: function (reason) {
                // TODO handle failure scenario
            }
        });
    };
    if (hasConnectionState) {
        if (props.accessToken.tokenPayload.provider !== 'ms-teams') {
            content = (React.createElement(Alert, { content: "You can associate your Microsoft account with ScreenCloud to enable autologin", dismissible: true, info: true, actions: [
                    {
                        content: 'Link account',
                        onClick: createUserAssociation
                    }
                ] }));
        }
    }
    else {
        if (props.accessToken.tokenPayload.is_owner) {
            content = (React.createElement(Alert, { content: "You can enable hassle free login by creating a connection between Teams and your ScreenCloud organisation", dismissible: true, info: true, actions: [
                    {
                        content: 'Connect',
                        onClick: createConnectionHandler
                    }
                ] }));
        }
    }
    return content;
};
export default Connection;
