import React from 'react';
import { Stack } from '@fluentui/react';
import ScreenCloudLogo from '../Branding/ScreenCloudIcon';
import { Loader } from '@fluentui/react-northstar';
var AuthLoading = function (props) {
    var stackTokens = {
        childrenGap: 'l2'
    };
    return (React.createElement(Stack, { verticalAlign: "center", horizontalAlign: "center", tokens: stackTokens, style: { height: window.innerHeight } },
        React.createElement(ScreenCloudLogo, { theme: props.theme, height: 40 }),
        React.createElement(Loader, { label: props.message, labelPosition: "end", size: "medium" })));
};
export default AuthLoading;
