var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import axios from 'axios';
import { appConfig } from '../appConfig';
import Region from '../types/Region';
import TokenServiceError from '../types/TokenServiceError';
var regionConfig = [
    {
        region: Region.EU,
        uri: appConfig.graphqlEuUrl
    },
    {
        region: Region.US,
        uri: appConfig.graphqlUsUrl
    }
];
export var TokenService = function () { return __awaiter(void 0, void 0, Promise, function () {
    var result;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, new Promise(function (resolve) { return axios.get(appConfig.authUrl + "/token/refresh", { withCredentials: true })
                    .then(function (tokenResponse) {
                    if (tokenResponse.status === 200) {
                        var accessTokens_1 = [];
                        axios.all(generateRequests(tokenResponse.data.token))
                            .then(axios.spread(function (euTokenResponse, usTokenResponse) {
                            if (euTokenResponse && euTokenResponse.status === 200) {
                                var processedTokens = processAccessTokens(euTokenResponse.data.accessTokens, tokenResponse.data.claims.email, Region.EU);
                                accessTokens_1 = accessTokens_1.concat(processedTokens);
                            }
                            if (usTokenResponse && usTokenResponse.status === 200) {
                                var processedTokens = processAccessTokens(usTokenResponse.data.accessTokens, tokenResponse.data.claims.email, Region.US);
                                accessTokens_1 = accessTokens_1.concat(processedTokens);
                            }
                            if (accessTokens_1.length === 0) {
                                resolve({ success: false, reason: TokenServiceError.NOTOKEN });
                            }
                            resolve({ success: true, accessTokens: accessTokens_1 });
                        }));
                    }
                }).catch(function (reason) {
                    resolve({ success: false, reason: reason });
                }); })];
            case 1:
                result = _a.sent();
                return [2 /*return*/, result];
        }
    });
}); };
var generateRequests = function (token) {
    var requests = [];
    regionConfig.forEach(function (region) {
        if (region.uri) {
            requests.push(axios.post(region.uri + "/auth", null, { headers: { 'Authorization': "Bearer " + token } }));
        }
    });
    return requests;
};
var processAccessTokens = function (accessTokens, email, region) {
    var validAccessTokens = accessTokens.filter(function (token) {
        if (token.tokenPayload.role !== 'STUDIO_USER'
            || token.tokenPayload.org_id === undefined) {
            return false;
        }
        return true;
    });
    var updatedAccessTokens = validAccessTokens.map(function (accessToken) {
        accessToken.email = email;
        accessToken.region = region;
        accessToken.tokenPayload.org_name = accessToken.tokenPayload.org_name + " (" + region + ")";
        return accessToken;
    });
    return updatedAccessTokens;
};
export var getActiveAccessToken = function (accessTokens, orgId) {
    if (orgId) {
        var accessToken = accessTokens.filter(function (element) { return (element.tokenPayload.org_id === orgId); });
        if (accessToken.length === 1) {
            return accessToken[0];
        }
        // TODO: Should probably throw an error here
    }
    return null;
};
