import React, { useState } from 'react';
import { Stack } from '@fluentui/react';
import SpaceListContainer from './SpacesList';
import ChannelListContainer from './ChannelsList';
import Organisation from './Organisation';
import { ApolloProvider } from 'react-apollo';
import { ApolloProvider as ApolloHooksProvider } from 'react-apollo-hooks';
import User from './User';
var ChannelConfig = function (props) {
    var _a = useState(), activeAccessTokenState = _a[0], setActiveAccessTokenState = _a[1];
    var _b = useState(), gqlClientState = _b[0], setGqlState = _b[1];
    var stackTokens = {
        childrenGap: 'm'
    };
    var stackItemStyles = {
        root: {
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
        },
    };
    var content = gqlClientState ? (React.createElement(ApolloProvider, { client: gqlClientState },
        React.createElement(ApolloHooksProvider, { client: gqlClientState },
            React.createElement(Stack.Item, { styles: stackItemStyles },
                React.createElement(SpaceListContainer, { space: props.space, setChannelHandler: props.setChannelHandler, setSpaceHandler: props.setSpaceHandler })),
            React.createElement(Stack.Item, { styles: stackItemStyles },
                React.createElement(ChannelListContainer, { activeAccessToken: activeAccessTokenState, space: props.space, channel: props.channel, setChannelHandler: props.setChannelHandler }))))) : null;
    return (React.createElement(Stack, { tokens: stackTokens },
        React.createElement(Stack.Item, null,
            React.createElement(User, { username: props.username, logoutHandler: props.logoutHandler })),
        React.createElement(Stack.Item, { styles: stackItemStyles },
            React.createElement(Organisation, { accessTokens: props.accessTokens, activeAccessToken: activeAccessTokenState, gqlClient: gqlClientState, setActiveAccessToken: setActiveAccessTokenState, setChannel: props.setChannelHandler, setSpace: props.setSpaceHandler, setGqlClient: setGqlState })),
        content));
};
export default ChannelConfig;
