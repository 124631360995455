import React, { useState } from 'react';
import axios from 'axios';
import { Stack, Link } from '@fluentui/react';
import { TokenService } from '../../lib/TokenService';
import { appConfig } from '../../appConfig';
import ScreenState from '../../types/ScreenState';
import { Input, Button, Alert } from '@fluentui/react-northstar';
import ScreenCloudLogo from '../Branding/ScreenCloudIcon';
import TokenServiceError from '../../types/TokenServiceError';
var BasicLogin = function (props) {
    var _a = useState(''), emailState = _a[0], setEmailState = _a[1];
    var _b = useState(''), passwordState = _b[0], setPasswordState = _b[1];
    var _c = useState(''), errorState = _c[0], setErrorState = _c[1];
    function emailOnChangeHandler(event) {
        setEmailState(event.target.value);
    }
    function passwordOnChangeHandler(event) {
        setPasswordState(event.target.value);
    }
    function loginHandler() {
        setErrorState('');
        axios.post(appConfig.authUrl + "/api/v1/user/login", { email: emailState, password: passwordState }, { withCredentials: true })
            .then(function (loginResponse) {
            if (loginResponse.status === 200) {
                TokenService()
                    .then(function (tokenSvcResponse) {
                    if (tokenSvcResponse.success) {
                        localStorage.removeItem('isLoggedOut');
                        if (props.setUsernameState) {
                            props.setUsernameState(emailState);
                        }
                        props.setAccessTokenState(tokenSvcResponse.accessTokens);
                        props.setScreenState(ScreenState.LOGGEDIN);
                    }
                    else {
                        if (tokenSvcResponse.reason === TokenServiceError.NOTOKEN) {
                            setErrorState((React.createElement("p", null,
                                "We are unable to find an organisation associated with your account. Please visit ",
                                React.createElement(Link, { href: appConfig.studioUrl, target: "_blank" }, "ScreenCloud Studio"),
                                " and make sure your organisation has been set up correctly. If you are still experiencing problems, contact our support team ",
                                React.createElement(Link, { href: "mailto:support@screen.cloud" }, "support@screen.cloud"),
                                ".")));
                        }
                        else {
                            setErrorState(React.createElement("p", null, tokenSvcResponse.reason));
                        }
                    }
                });
            }
        }).catch(function (error) {
            if (error.response.status === 401) {
                setErrorState('Invalid username or password');
            }
            else {
                setErrorState('Something has gone wrong please try again later');
            }
        });
    }
    function backHandler() {
        props.setScreenState(ScreenState.HOME);
    }
    var stackTokens = {
        childrenGap: 'm'
    };
    var stackItemStyles = {
        root: {
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            width: 500
        },
    };
    var errorMessage = errorState !== '' ? (React.createElement(Alert, { danger: true, content: errorState })) : null;
    return (React.createElement(Stack, { horizontalAlign: "center", tokens: stackTokens, style: { paddingTop: 30 } },
        React.createElement(Stack, { horizontal: true, disableShrink: true, styles: stackItemStyles, tokens: stackTokens },
            React.createElement(ScreenCloudLogo, { theme: props.theme, height: 40 })),
        React.createElement(Stack.Item, { styles: stackItemStyles },
            React.createElement(Input, { fluid: true, placeholder: "e-mail", value: emailState, onChange: emailOnChangeHandler })),
        React.createElement(Stack.Item, { styles: stackItemStyles },
            React.createElement(Input, { fluid: true, placeholder: "password", type: "password", value: passwordState, onChange: passwordOnChangeHandler })),
        errorMessage,
        React.createElement(Stack.Item, { styles: stackItemStyles },
            React.createElement(Stack, { horizontal: true, tokens: stackTokens },
                React.createElement(Button, { fluid: true, secondary: true, onClick: backHandler, content: "back" }),
                React.createElement(Button, { fluid: true, primary: true, onClick: loginHandler, content: "log in" })))));
};
export default BasicLogin;
