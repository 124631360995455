import React, { useState } from 'react';
import * as microsoftTeams from "@microsoft/teams-js";
import { appConfig } from '../appConfig';
import ScreenState from '../types/ScreenState';
import { Stack, Link } from '@fluentui/react';
import { Alert, Button } from '@fluentui/react-northstar';
import ScreenCloudLogo from './Branding/ScreenCloudIcon';
import TokenServiceError from '../types/TokenServiceError';
var Home = function (props) {
    var _a = useState(), errorMessage = _a[0], setErrorMessage = _a[1];
    function resolveErrorMessage(reason) {
        if (reason === TokenServiceError.NOTOKEN) {
            setErrorMessage((React.createElement("p", null,
                "We are unable to find an organisation associated with your account. Please visit ",
                React.createElement(Link, { href: appConfig.studioUrl, target: "_blank" }, "ScreenCloud Studio"),
                " and make sure your organisation has been set up correctly. If you are still experiencing problems, contact our support team ",
                React.createElement(Link, { href: "mailto:support@screen.cloud" }, "support@screen.cloud"),
                ".")));
        }
        else {
            setErrorMessage((React.createElement("p", null, reason)));
        }
    }
    function googleLoginHandler() {
        setErrorMessage(undefined);
        microsoftTeams.authentication.authenticate({
            url: window.location.origin + "/googlelogin",
            height: 800,
            width: 600,
            successCallback: function (result) {
                props.setAccessTokensState(JSON.parse(result));
                props.setScreenState(ScreenState.LOGGEDIN);
            },
            failureCallback: function (reason) {
                resolveErrorMessage(reason);
            }
        });
    }
    function azureLoginHandler() {
        setErrorMessage(undefined);
        microsoftTeams.authentication.authenticate({
            url: window.location.origin + "/azurelogin",
            height: 800,
            width: 600,
            successCallback: function (result) {
                props.setAccessTokensState(JSON.parse(result));
                props.setScreenState(ScreenState.LOGGEDIN);
            },
            failureCallback: function (reason) {
                resolveErrorMessage(reason);
            }
        });
    }
    function loginHandler() {
        setErrorMessage(undefined);
        props.setScreenState(ScreenState.LOGIN);
    }
    var stackTokens = {
        childrenGap: 'm',
    };
    var stackItemStyles = {
        root: {
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
        },
    };
    var buttonStyles = {
        width: 500
    };
    var errorMessageAlert = errorMessage ? (React.createElement(Alert, { content: errorMessage, danger: true, style: { width: 500 } })) : null;
    var logInWithMicrosoft = props.logInWithMicrosoft ? (React.createElement(Stack.Item, { styles: stackItemStyles },
        React.createElement(Button, { style: buttonStyles, onClick: azureLoginHandler, content: "Log in with Microsoft", secondary: true }))) : null;
    return (React.createElement(Stack, { horizontalAlign: 'center', tokens: stackTokens, style: { paddingTop: 30 } },
        React.createElement(Stack.Item, null,
            React.createElement(Stack, { horizontal: true, disableShrink: true, styles: stackItemStyles, tokens: stackTokens },
                React.createElement(ScreenCloudLogo, { theme: props.theme, height: 40 }))),
        React.createElement(Stack.Item, { styles: stackItemStyles },
            React.createElement(Button, { onClick: loginHandler, content: "Log in with email", primary: true, style: buttonStyles })),
        React.createElement(Stack.Item, null,
            React.createElement("span", null,
                "Don't have an account? ",
                React.createElement(Link, { href: appConfig.signupUrl, target: '_blank', style: { color: "#6264a7", textDecorationLine: "underline", fontWeight: "bold" } }, "Get Started"),
                " ")),
        React.createElement(Stack.Item, { styles: stackItemStyles },
            React.createElement(Button, { style: buttonStyles, onClick: googleLoginHandler, content: "Log in with Google", secondary: true })),
        logInWithMicrosoft,
        errorMessageAlert));
};
export default Home;
