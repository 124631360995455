import React from 'react';
import { Image } from '@fluentui/react-northstar';
var ScreenCloudLogo = function (props) {
    var image = "img/logo_screencloud_2019_icon_1.svg";
    if (props.theme !== "default" && props.theme !== undefined) {
        image = "img/logo_screencloud_2019_icon_2.svg";
    }
    return (React.createElement(Image, { src: image, style: { height: props.height } }));
};
export default ScreenCloudLogo;
