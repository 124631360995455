import React from 'react';
import { Stack } from '@fluentui/react';
import { Button } from '@fluentui/react-northstar';
import { LeaveIcon } from '@fluentui/react-icons-northstar';
var User = function (props) {
    return (React.createElement(Stack, { horizontal: true, horizontalAlign: 'end' },
        React.createElement("p", null,
            React.createElement("b", null, props.username),
            React.createElement(Button, { style: { marginLeft: 15 }, primary: true, icon: React.createElement(LeaveIcon, null), content: "Log out", iconPosition: "after", onClick: props.logoutHandler }))));
};
export default User;
