import React, { useEffect, useState } from 'react';
import './App.css';
import * as queryString from 'query-string';
import axios from 'axios';
import { TokenService, getActiveAccessToken } from '../lib/TokenService';
import { Stack } from '@fluentui/react';
import { UserBlurIcon } from '@fluentui/react-icons-northstar';
import Home from './Home';
import BasicLogin from './Authentication/BasicLogin';
import Connection from './Connection';
import { appConfig } from '../appConfig';
import * as microsoftTeams from '@microsoft/teams-js';
import short from 'short-uuid';
import ScreenState from '../types/ScreenState';
import User from './User';
import Region from '../types/Region';
import { Alert, Button } from '@fluentui/react-northstar';
import ScreenCloudLogoAndText from './Branding/ScreenCloudIconAndText';
import AuthLoading from './Authentication/AuthLoading';
;
var EmbeddedChannel = function (props) {
    var _a = useState(ScreenState.SILENT), screenState = _a[0], setScreenState = _a[1];
    var _b = useState(false), displayConnMsgState = _b[0], setDisplayConnMsg = _b[1];
    var _c = useState(), accessTokensState = _c[0], setAccessTokensState = _c[1];
    var _d = useState(window.innerWidth), windowWidthState = _d[0], setWindowWidthState = _d[1];
    var _e = useState(window.innerHeight), windowHeightState = _e[0], setWindowHeightState = _e[1];
    var _f = useState(), contextState = _f[0], setContextState = _f[1];
    var qs = queryString.parse(window.location.search);
    var orgId = qs.org;
    var channelId = qs.channel;
    var isPrivate = qs.isPrivate;
    var channelRegion = qs.region;
    var isPublic = !Boolean(JSON.parse(isPrivate));
    useEffect(function () {
        microsoftTeams.getContext(function (context) {
            setContextState(context);
        });
    }, []);
    useEffect(function () {
        if (isPublic) {
            // The user has explicitly logged out, we should not attempt another login
            if (!localStorage.getItem('isLoggedOut')) {
                attemptLogin();
            }
            // The user is not attempting to login and they are currently not logged in
            if (screenState !== ScreenState.HOME && screenState !== ScreenState.LOGIN && screenState !== ScreenState.LOGGEDIN) {
                setScreenState(ScreenState.PUBLIC);
            }
        }
        else {
            // The user has logged out, we should redirect to the home screen
            if (localStorage.getItem('isLoggedOut')) {
                if (screenState !== ScreenState.LOGIN && screenState !== ScreenState.HOME) {
                    setScreenState(ScreenState.HOME);
                }
            }
            // Attempt to get a token from exisiting session
            if (screenState !== ScreenState.LOGIN && screenState !== ScreenState.LOGGEDIN && screenState !== ScreenState.HOME) {
                // TODO: Display Conn Msg needs to be made more generic and be moved to home or top level
                setDisplayConnMsg(false);
                attemptLogin();
            }
        }
    }, [screenState, orgId]);
    useEffect(function () {
        handleResize();
        window.addEventListener("resize", handleResize);
        return function () { return window.removeEventListener("resize", handleResize); };
    }, []);
    function attemptLogin() {
        TokenService()
            .then(function (response) {
            if (response.success) {
                var activeAccessToken = getActiveAccessToken(response.accessTokens, orgId);
                if (activeAccessToken === null || activeAccessToken === void 0 ? void 0 : activeAccessToken.tokenPayload.org_id) {
                    setScreenState(ScreenState.LOGGEDIN);
                }
                else {
                    // We are logged in as a studio user who has not been associated with an organisation (indicates org has not enabled ms-teams connection)
                    if (!isPublic) {
                        setDisplayConnMsg(true);
                        setScreenState(ScreenState.HOME);
                    }
                }
                setAccessTokensState(response.accessTokens);
            }
            else {
                // attempt an azure login
                if (!isPublic && screenState === ScreenState.SILENT) {
                    microsoftTeams.authentication.authenticate({
                        url: window.location.origin + "/azurelogin",
                        height: 800,
                        width: 600,
                        successCallback: function (result) {
                            setAccessTokensState(JSON.parse(result));
                            setScreenState(ScreenState.LOGGEDIN);
                        },
                        failureCallback: function (reason) {
                            setScreenState(ScreenState.HOME);
                        }
                    });
                }
            }
        }).catch(function (reason) {
            if (!isPublic) {
                setScreenState(ScreenState.HOME);
            }
        });
    }
    function handleResize() {
        setWindowWidthState(window.innerWidth);
        setWindowHeightState(window.innerHeight);
    }
    function logoutHandler() {
        localStorage.setItem('isLoggedOut', 'true');
        axios.post(appConfig.authUrl + "/api/v1/user/logout")
            .then(function (response) {
            if (response.status === 200) {
                var state = isPublic ? ScreenState.PUBLIC : ScreenState.HOME;
                setScreenState(state);
                setAccessTokensState(undefined);
            }
        })
            .catch(function (reason) {
            console.error("failed to log out");
        });
    }
    if (screenState === ScreenState.SILENT) {
        return (React.createElement(AuthLoading, { theme: props.theme, message: "Attempting to log you in..." }));
    }
    else if (screenState === ScreenState.HOME) {
        var msg = displayConnMsgState ? (React.createElement(Alert, { content: "Microsoft Teams auto-enrollment has not been enabled for your organisation. Either login using one of the options below or speak with an organisation owner about enabling auto-enrollment for Microsoft Teams.", info: true })) : null;
        return (React.createElement("div", { style: { height: window.innerHeight } },
            msg,
            React.createElement(Home, { logInWithMicrosoft: true, theme: props.theme, setScreenState: setScreenState, setAccessTokensState: setAccessTokensState })));
    }
    else if (screenState === ScreenState.LOGIN) {
        return (React.createElement("div", { style: { height: window.innerHeight } },
            React.createElement(BasicLogin, { theme: props.theme, setAccessTokenState: setAccessTokensState, setScreenState: setScreenState })));
    }
    else if (screenState === ScreenState.LOGGEDIN || isPublic) {
        var shortUUID = short();
        var connection = void 0;
        var content = void 0;
        var user = void 0;
        var region = channelRegion;
        var overrideToken = '';
        if (isPublic || accessTokensState) {
            var activeAccessToken = null;
            if (accessTokensState) {
                activeAccessToken = getActiveAccessToken(accessTokensState, orgId);
            }
            if (activeAccessToken && activeAccessToken.tokenPayload.org_id === orgId) {
                overrideToken = "&override_studio_user_token=" + activeAccessToken.token;
                region = activeAccessToken.region;
                connection = (React.createElement(Connection, { accessToken: activeAccessToken, msContext: contextState, setAccessTokensState: setAccessTokensState }));
                var username = contextState && contextState.loginHint && activeAccessToken.tokenPayload.provider === 'ms-teams' ? contextState.loginHint : activeAccessToken.email;
                user = (React.createElement(User, { username: username, logoutHandler: logoutHandler }));
            }
            else {
                connection = (React.createElement(React.Fragment, null));
                user = (React.createElement(Button, { style: { marginLeft: 15 }, primary: true, content: "Log in", icon: React.createElement(UserBlurIcon, null), iconPosition: "after", onClick: function () { setScreenState(ScreenState.HOME); } }));
            }
            var adjustment = 57;
            var playerUrl = region === Region.EU ? appConfig.playerEuUrl : appConfig.playerUsUrl;
            content = (React.createElement("iframe", { key: orgId + ":" + channelId, title: orgId + ":" + channelId, src: playerUrl + "/?embed=" + shortUUID.fromUUID(orgId) + ":" + shortUUID.fromUUID(channelId) + "&type=channel&private=" + isPrivate + "&autoplay=true" + overrideToken, width: windowWidthState, height: windowHeightState - adjustment, sandbox: "allow-scripts allow-same-origin" }));
        }
        else {
            content = (React.createElement(Alert, { content: "Your user account does not have access to view this content. An owner of your organisation may need to enable an MS Teams Connection in order for you to view this channel.", info: true }));
        }
        var footer = {
            root: {
                height: 50,
                padding: 10
            }
        };
        var stackTokens = {
            childrenGap: 'l2'
        };
        return (React.createElement(Stack, null,
            React.createElement(Stack.Item, null, content),
            React.createElement(Stack, { styles: footer, horizontal: true, horizontalAlign: "space-between", verticalAlign: "center", tokens: stackTokens },
                React.createElement(Stack.Item, null,
                    React.createElement(ScreenCloudLogoAndText, { theme: props.theme, height: 20 })),
                React.createElement(Stack.Item, null, connection),
                React.createElement(Stack.Item, { disableShrink: true }, user))));
    }
    else {
        return (React.createElement("div", null));
    }
};
export default EmbeddedChannel;
